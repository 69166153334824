import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import ClientEditForm from './ClientEditForm';
import Header from './Header';
import clientRequest from '../../../requests/api/client';
import useStyles from './styles';

function ClientEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [client, setClient] = useState();
  const [actecos, setActecos] = useState([]);
  useEffect(() => {
    const getClient = async () => {
      const response = await clientRequest.getClientById(props.match.params.id);
      if (isMountedRef.current) {
        setClient(response.client);
      }
    };

    const getActecos = async () => {
      const response = await clientRequest.getActecos();
      if (isMountedRef.current) {
        setActecos(response.actecos);
      }
    };

    getActecos();
    getClient();
  }, [props]);

  if (!client) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Editar Cliente"
    >
      <Container maxWidth={false}>
        <Header clientId={props.match.params.id} />
        <Box mt={3}>
        <ClientEditForm client={client} actecos={actecos}/>
        </Box>
      </Container>
    </Page>
  );
}

ClientEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ClientEditView);
