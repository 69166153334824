import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as FormData from 'form-data';

import {
  Grid,
  Dialog,
  DialogTitle,
  Button,
  Box,
  Typography,
} from '@material-ui/core';
import {
  Upload as UploadIcon, UploadCloud,
} from 'react-feather';

import handleApiResponse from '../../../utils/handleApiResponse';
import request from '../../../requests/api/client';

function ClientUploadFiles({
  open, setOpen, clientId,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCert, setSelectedCert] = useState('');
  const [selectedFoldNumbers, setSelectedFoldNumbers] = useState('');
  const [loading, setLoading] = useState(false);

  const handleUploadFile = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('files', selectedCert);
      formData.append('files', selectedFoldNumbers);
      const response = await request.postFiles(clientId, formData);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    } finally {
      setLoading(false);
      setSelectedCert('');
      setSelectedFoldNumbers('');
    }
  };

  return (<>
        <Dialog open={open} onClose={() => setOpen(false)} >
            <DialogTitle>
                <Typography>
                    Subir archivos de cliente
                </Typography>
            </DialogTitle>
            <Box sx={{ padding: 20 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Button
                            startIcon={<UploadIcon />}
                            component="label"
                        >
                            { selectedCert.name || 'Importar certificado' }
                            <input
                                type="file"
                                accept=".pfx"
                                hidden
                                onChange={(e) => setSelectedCert(e.target.files[0])}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            startIcon={<UploadIcon />}
                            component="label"
                        >
                            {selectedFoldNumbers.name || 'Importar Folios'}
                            <input
                                type="file"
                                accept=".xml"
                                hidden
                                onChange={(e) => setSelectedFoldNumbers(e.target.files[0])}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ marginTop: 20 }} >
                            <Button
                                disabled={loading}
                                startIcon={<UploadCloud />}
                                type='Submit'
                                onClick={handleUploadFile}
                                variant='contained'
                            >
                                { loading ? 'Subiendo archivos...' : 'Subir Archivos' }
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    </>);
}

ClientUploadFiles.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  clientId: PropTypes.string,
};

export default ClientUploadFiles;
