import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SplashScreen from '../../../components/Loading/SplashScreen';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import storeRequest from '../../../requests/api/store';
import clientRequest from '../../../requests/api/client';
import { getStocksPerPage } from '../../../requests/api/productStock';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';

function StockListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((s) => s.account);
  const history = useHistory();
  const historyPreviousPath = history.location.state && history.location.state.prevPath === '/inventario/editar';
  const reducerFilters = useSelector((s) => s.filter.filter);
  const [stocks, setStocks] = useState(null);
  const [page, setPage] = useState(historyPreviousPath ? reducerFilters.page : 0);
  const [limit, setLimit] = useState(historyPreviousPath ? reducerFilters.limit : 10);
  const [searchValue, setSearchValue] = useState(historyPreviousPath ? reducerFilters.searchValue : '');
  const [selectedStore, setSelectedStore] = useState(historyPreviousPath ? {
    id: reducerFilters.selectedStore.id,
    name: reducerFilters.selectedStore.name,
  } : {
    id: null,
    name: null,
  });
  const [productId, setProductId] = useState('');

  const [clients, setClients] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedClient, setSelectedClient] = useState(historyPreviousPath ? {
    id: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.id
      : account.user.clientId,
    name: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.name
      : null,
  } : {
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });
  const [count, setCount] = useState(null);
  const [productsToUpdateCSV, setProductsToUpdateCSV] = useState(null);
  const [selectedStoreCSV, setSelectedStoreCSV] = useState({});

  useEffect(() => {
    const getStocks = async () => {
      try {
        const response = await getStocksPerPage(
          limit,
          page,
          searchValue,
          selectedStore.id,
          productId,
          selectedClient.id,
        );
        if (!response.success) {
          setStocks(null);
          return;
        }
        setStocks(response.stock);
        setCount(response.totalStock);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (selectedClient.id && selectedStore.id) getStocks();
    if (selectedClient.id && !selectedStore.id) setStocks(null);
  }, [limit, page, searchValue, selectedStore, productId, selectedClient, productsToUpdateCSV]);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await storeRequest.getStoresPerPage('', '', '', selectedClient.id);
        if (!response.success) {
          setStores([{
            id: null,
            name: 'Sin tiendas asociadas',
          }]);
          setSelectedStore({
            id: null,
            name: 'Sin tiendas asociadas',
          });
          return;
        }
        setStores(response.allStores);
        if (!selectedStore.id) {
          setSelectedStore({
            id: response.allStores[0].id,
            name: response.allStores[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage('', '', '');
        setClients(response.clients);
        if (!selectedClient.id) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (account.user.role === SUPER_ADMIN) getClients();
    if (selectedClient.id) getStores();
  }, [selectedClient]);

  if (!selectedClient.id) return <SplashScreen />;
  if (stores[0] && (stores[0].id && !selectedStore.id)) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Stocks"
    >
      <Container maxWidth={false}>
        <Header
          stocks={stocks}
          clientId={selectedClient.id}
          stores={stores}
          productsToUpdateCSV={productsToUpdateCSV}
          setProductsToUpdateCSV={setProductsToUpdateCSV}
          selectedStoreCSV={selectedStoreCSV}
          setSelectedStoreCSV={setSelectedStoreCSV}
        />
        <Box mt={3}>
          <Results
            stocks={stocks}
            stores={stores}
            page={page}
            count={count || 0}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            role={account.user.role}
            clients={clients}
            setSelectedClient={setSelectedClient}
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
            setProductId={setProductId}
            selectedClient={selectedClient}
            productsToUpdateCSV={productsToUpdateCSV}
            selectedStoreCSV={selectedStoreCSV}
            history={history}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default StockListView;
