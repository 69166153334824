import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import clientRequest from '../../../requests/api/client';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';

function ClientListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [clients, setClients] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [count, setCount] = useState(null);
  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest
          .getClientsPerPage(page, limit, searchValue);
        setClients(response.clients);
        setCount(response.totalClients);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getClients();
  }, [limit, page, searchValue]);

  return (
    <Page
      className={classes.root}
      title="Clientes"
    >
      <Container maxWidth={false}>
        <Header />
        {clients && (
          <Box mt={3}>
            <Results
              clients={clients}
              page={page}
              count={count || 0}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default ClientListView;
