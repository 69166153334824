import axios from '../../utils/axios';

const getModuleByClient = async (clientId) => {
  const response = await axios.get(`/api/module/client/${clientId}`);
  return response.data;
};

const putModule = async (clientId, moduleId, clientModule) => {
  const response = await axios.put('/api/module/edit', { clientId, moduleId, clientModule });
  return response.data;
};

export {
  getModuleByClient,
  putModule,
};
