import React from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import UpdateStockForm from './UpdateStockForm';
import useStyles from './styles';

function UpdateStockView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Editar inventario"
    >
      <Container maxWidth={false}>
        <Header />
          <Box mt={3}>
            <UpdateStockForm />
          </Box>
      </Container>
    </Page>
  );
}

export default UpdateStockView;
