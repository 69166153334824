import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import useStyles from './styles';
import { putPriceList } from '../../../requests/api/priceLists';
import handleApiResponse from '../../../utils/handleApiResponse';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';

function PriceListEditForm({
  products,
  priceList,
  className,
  setProductsPricesMod,
  ...rest
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [editPriceListName, setEditPriceListName] = useState(false);

  return (
    <Box className={classes.editPriceListWrapper}>
      <Box className={classes.editPriceListFormWrapper}>
        <Card>
          <Formik
            initialValues={{
              id: priceList ? priceList.id : '',
              name: priceList ? priceList.name : '',
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required('Debe ingresar un nombre'),
            })}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting,
            }) => {
              try {
                // Make API request
                const data = {
                  id: values.id,
                  name: values.name,
                };
                const response = await putPriceList(data, priceList.clientId);

                handleApiResponse(enqueueSnackbar, response, true);
                setStatus({ success: true });
                setSubmitting(false);
              } catch (error) {
                handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/precios">Volver a Lista de Precios</Button>);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                className={clsx(classes.rootCreateForm, className)}
                onSubmit={handleSubmit}
                {...rest}
              >

                <CardContent>
                  {!editPriceListName && values.name && (
                    <Box className={classes.modifyPriceListNameAndButton}>
                      <Typography
                        variant="h4"
                        color="textPrimary"
                      >
                        {values.name}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => setEditPriceListName(true)}
                      >
                        Modificar Nombre
                      </Button>
                    </Box>)}
                  {!editPriceListName && !values.name && 'Error en cargar nombre de la lista'}
                  <Grid
                    container
                  >
                    {editPriceListName && (
                      <Box className={classes.modifyPriceListNameAndButton}>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <CustomTextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label="Nombre"
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Box
                          mt={2}
                          display='flex'
                          justifyContent='flex-end'
                          width='100%'
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                            onClick={() => setEditPriceListName(false)}
                          >
                            Guardar
                          </Button>
                        </Box>
                      </Box>

                    )}
                  </Grid>
                </CardContent>
              </form>
            )}
          </Formik>
          <Divider />
          <CardContent>
            <Formik
              initialValues={{
                id: '',
                price: '',
                sku: '',
                name: '',
              }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                id: Yup.string().max(255).required('Debe seleccionar un producto'),
                price: Yup.string().max(255).required('Debe ingresar un precio mayor a 0'),
              })}
              onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting,
              }) => {
                try {
                  // Make API request
                  const data = {
                    id: priceList.id,
                    productPriceToAdd: [{
                      productId: values.id,
                      priceListId: priceList.id,
                      price: values.price,
                      deleted: false,
                    }],
                  };
                  const response = await putPriceList(data, priceList.clientId);

                  handleApiResponse(enqueueSnackbar, response, true);
                  setStatus({ success: true });
                  setSubmitting(false);
                  setProductsPricesMod((prevVal) => ([
                    ...prevVal,
                    {
                      id: values.id,
                      sku: values.sku,
                      name: values.name,
                      price: values.price,
                    },
                  ]));
                } catch (error) {
                  handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/precios">Volver a Lista de Precios</Button>);
                  setStatus({ success: false });
                  setErrors({ submit: error.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form
                  className={clsx(classes.rootCreateForm, className)}
                  onSubmit={handleSubmit}
                  {...rest}
                >
                  <Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      mb={2}
                    >
                      <Typography
                        variant="h4"
                        color="textPrimary"
                      >
                        Agregar Producto
                      </Typography>
                    </Box>
                    <Box>
                      <Box className={classes.modifyPriceListNameAndButton}>
                        <CustomAutocomplete
                          disablePortal
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          fullWidth
                          sx={{ marginRight: '25px', marginTop: '-22.5px' }}
                          noOptionsText='Sin productos para mostrar'
                          placeholder="Productos"
                          options={products}
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={(e, value) => {
                            if (value === null) {
                              setFieldValue('id', '');
                              return;
                            }
                            setFieldValue('id', value.id);
                            setFieldValue('sku', value.sku);
                            setFieldValue('name', value.name);
                            setFieldValue('price', value.price);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              label="Producto"
                              variant="outlined"
                            />
                          )}
                        />
                        <CustomTextField
                          error={Boolean(touched.price && errors.price)}
                          fullWidth
                          helperText={(touched.price && errors.price) || ' '}
                          label="Precio"
                          name="price"
                          type='number'
                          InputProps={{ inputProps: { min: 1 } }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.price}
                          variant="outlined"
                          // className={classes.textFieldPrice}
                        />

                        <Box
                          mt={2}
                          display='flex'
                          justifyContent='flex-end'
                          width='100%'
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Guardar
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </Box>

  );
}

PriceListEditForm.propTypes = {
  className: PropTypes.string,
};

export default PriceListEditForm;
