import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import CancelButton from '../../../components/Buttons/Cancel/CancelButton';

function Header({ name, className, ...rest }) {
  const history = useHistory();

  const handleGoBackToStores = () => {
    history.push('/administracion/tiendas', { prevPath: '/tienda/editar' });
  };

  return (
    <Grid
      className={clsx(className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
    <Grid container spacing= {1}>
      <Grid item>
        <Typography
          variant="h3"
          color="textSecondary"
          >
          Editar Tienda
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          {name}
        </Typography>
    </Grid>
    </Grid>
  </Grid>
      <Grid item>
      <CancelButton
        component={Button}
        onClick={() => handleGoBackToStores()}
        >
          Cancelar
        </CancelButton>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
