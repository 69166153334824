import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import CustomTextField from '../../components/Inputs/TextField/CustomTextField';
import accountActions from '../../actions/accountActions';
import { postRecoverPassword } from '../../requests/api/auth';

import useStyles from './styles';
import handleApiResponse from '../../utils/handleApiResponse';

function LoginForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleResetPassword = async (email) => {
    try {
      const response = await postRecoverPassword(email);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const BO = 'BACKOFFICE';

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email requerido'),
        password: Yup.string().max(255).required('Contraseña requerida'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          await dispatch(accountActions.login(values.email, values.password, BO));
          onSubmitSuccess();
        } catch (error) {
          const message = error || 'Ha ocurrido un error. Por favor intente nuevamente más tarde.';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <CustomTextField
            error={Boolean(touched.email && errors.email)}
            color="secondary"
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <CustomTextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            color="secondary"
            helperText={touched.password && errors.password}
            label="Contraseña"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Iniciar Sesión
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
          </Box>
          <Button
            className={classes.resetPassword}
            onClick={() => handleResetPassword(values.email)}
          >
            ¿Olvidaste tu contraseña?
          </Button>
          {/* TODO: Descomentar si se permitirá el registro de usuarios fuera del Dashboard
          y crear una ruta que acepte password */}
          {/* <a href="/registro">Registarse</a> */}
        </form>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

LoginForm.defaultProps = {
  onSubmitSuccess: () => {},
};

export default LoginForm;
