import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  X as ClearIcon,
  Search as SearchIcon,
  FileText as DetailIcon,
} from 'react-feather';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import useStyles from './styles';
import { saveFilters } from '../../../actions/filterActions';

function Results({
  returns,
  count,
  searchValue,
  setSearchValue,
  page,
  setPage,
  limit,
  setLimit,
  role,
  clients,
  selectedClient,
  setSelectedClient,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  history,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    page,
    limit,
    searchValue,
    selectedClient,
    startDate,
    endDate,
  });

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    setFilters((prevVal) => ({ ...prevVal, page: newPage }));
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, limit: e.target.value }));
  };

  const handleOrderSearchValue = (e) => {
    e.preventDefault();
    setSearchValue({
      billing: '',
      order: e.target.value,
    });
    setFilters((prevVal) => ({
      ...prevVal,
      searchValue: {
        billing: '',
        order: e.target.value,
      },
    }));
  };
  const handleBillingSearchValue = (e) => {
    e.preventDefault();
    setSearchValue({
      billing: e.target.value,
      order: '',
    });
    setFilters((prevVal) => ({
      ...prevVal,
      searchValue: {
        billing: e.target.value,
        order: '',
      },
    }));
  };
  const handleGoToOrderDetail = (orderId) => {
    dispatch(saveFilters(filters));
    history.push(`/pedidos/${orderId}/detalle`, { prevPath: '/devoluciones' });
  };
  const handleGoToReturnDetail = (returnId) => {
    dispatch(saveFilters(filters));
    history.push(`/devoluciones/${returnId}/detalle`);
  };

  return (
<div>
  <Card>
  <Box
    p={2}
    minHeight={56}
    display="flex"
    alignItems="center"
  >
   <Grid container spacing={1}>
    {role === SUPER_ADMIN
    && <Grid item md={2} xs={12}>
      <CustomAutocomplete
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disablePortal
        noOptionsText='Sin clientes para mostrar'
        id="combo-box-demo"
        placeholder="Buscar cliente"
        options={clients}
        value={selectedClient}
        getOptionLabel={(option) => `${option.name}`}
        onChange={(e, value) => {
          if (value === null) {
            setSelectedClient({
              id: clients[0].id,
              name: clients[0].name,
            });
            return;
          }
          setSelectedClient({
            id: value.id,
            name: value.name,
          });
          setFilters((prevVal) => ({
            ...prevVal,
            selectedClient: {
              id: value.id,
              name: value.name,
            },
          }));
        }}
        renderInput={(params) => (
          <CustomTextField
          {...params}
          label="Buscar cliente"
          variant="outlined"
          />
        )}
     />
     </Grid>
  }
  <Grid item md={2} xs={12}>
    <CustomTextField
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon
              fontSize="small"
              color="action"
            >
              <SearchIcon />
            </SvgIcon>
          </InputAdornment>
        ),
      }}
      onChange={handleOrderSearchValue}
      placeholder="Buscar n° orden"
      value={searchValue.order}
      variant="outlined"
    />
  </Grid>
  <Grid item md={2} xs={12}>
  <CustomTextField
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SvgIcon
            fontSize="small"
            color="action"
          >
            <SearchIcon />
          </SvgIcon>
        </InputAdornment>
      ),
    }}
    onChange={handleBillingSearchValue}
    placeholder="Buscar nota de crédito"
    value={searchValue.billing}
    variant="outlined"
  />
  </Grid>
  <Grid item md={2} xs={12}>
    <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={esLocale}
      >
      <DatePicker
        views={['day', 'month', 'year']}
        label="Fecha de inicio"
        value={startDate}
        onChange={(value) => {
          if (value === null) {
            setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
            return;
          }
          setStartDate(value);
          setFilters((prevVal) => ({ ...prevVal, startDate: value }));
        }}
        renderInput={(params) => (
        <CustomTextField
        {...params}
        variant='outlined'
        fullWidth
        />
        )}
      />
    </LocalizationProvider>
  </Grid>
  <Grid item md={2} xs={12}>
    <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={esLocale}
      >
      <DatePicker
        views={['day', 'month', 'year']}
        label="Fecha límite"
        value={endDate}
        onChange={(value) => {
          if (value === null) {
            setEndDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
            return;
          }
          setEndDate(value);
          setFilters((prevVal) => ({ ...prevVal, endDate: value }));
        }}
        renderInput={(params) => (
        <CustomTextField
        {...params}
        variant='outlined'
        fullWidth
        />
        )}
      />
    </LocalizationProvider>
  </Grid>
    <Tooltip title='Limpiar fechas'>
      <IconButton
        component={Button}
        onClick={() => {
          setEndDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
          setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
        }}
      >
        <SvgIcon fontSize="small">
          <ClearIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  </Grid>
  </Box>
  </Card>
  <br/>
  <TableContainer component={Card}>
  <Table sx={{ minWidth: 700 }}>
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell align='center'>
          Razón
        </TableCell>
        <TableCell align='center'>
          Estado
        </TableCell>
        <TableCell align='center'>
          Número de orden
        </TableCell>
        <TableCell align='center'>
          Fecha de devolución
        </TableCell>
        <TableCell align="right">
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={count <= 0 ? 0 : page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por página"
        />
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {returns.length > 0 ? returns.map((devolution) => {
        const fixedDate = devolution.createdAt.substring(0, 10).split('-').reverse().join('/');
        return (<TableRow
            hover
            key={devolution.id}
          >
            <TableCell>
              <Box
                display="flex"
                alignItems="center"
              >
                <Avatar
                  className={classes.avatar}
                >
                  {devolution.id}
                </Avatar>
              </Box>
            </TableCell>
            <TableCell align='center'>
              {devolution.reason}
            </TableCell>
            <TableCell align='center'>
              {devolution.status}
            </TableCell>
            <TableCell align='center'>
              <Tooltip title='Ver detalle del pedido'>
                <Button
                onClick={() => handleGoToOrderDetail(devolution.orderId)}
                value={devolution.orderId}>
                  {devolution.order.orderNumber}
                </Button>
              </Tooltip>
            </TableCell>
            <TableCell align='center'>
              {fixedDate}
            </TableCell>
            <TableCell align="right">
              <Tooltip title='Ver detalle'>
                <IconButton
                  component={Button}
                  onClick={() => handleGoToReturnDetail(devolution.id)}
                >
                  <SvgIcon fontSize="small">
                    <DetailIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>);
      })
        : <TableRow>
          <TableCell colSpan="6" align='center'>
            Sin devoluciones para mostrar
          </TableCell>
        </TableRow>}
    </TableBody>
  </Table>
  </TableContainer>
</div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.object.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  returns: PropTypes.array.isRequired,
};

Results.defaultProps = {
  returns: [],
};

export default Results;
