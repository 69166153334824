import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import CancelButton from '../../../components/Buttons/Cancel/CancelButton';

function Header({ className, ...rest }) {
  const history = useHistory();
  const handleGoBackToReturns = () => {
    history.push('/devoluciones', { prevPath: '/devoluciones/detalle' });
  };
  return (
    <Grid
      className={clsx(className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
     >
     <Grid item>
      <Grid container spacing= {1}>
        <Grid item>
          <Typography
            variant="h3"
            color="textSecondary"
            >
            Detalle
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h3"
            color="textPrimary"
            >
            de Devoluciones
          </Typography>
        </Grid>
      </Grid>
     </Grid>
      <Grid item>
        <CancelButton
          component={Button}
          onClick={() => handleGoBackToReturns()}
        >
        Volver a devoluciones
        </CancelButton>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
