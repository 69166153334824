import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Page from '../../../../components/Layout/Page';
import Header from './Header';
import { SUPER_ADMIN } from '../../../../utils/const/user_types';
import UserCreateForm from './UserCreateForm';
import clientRequest from '../../../../requests/api/client';
import useStyles from './styles';
import handleApiResponse from '../../../../utils/handleApiResponse';

function UserCreateView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [clients, setClients] = useState([]);
  const user = useSelector((s) => s.account.user);

  useEffect(() => {
    const getClients = async () => {
      const page = 0;
      const limit = '';
      const searchValue = '';
      try {
        const response = await clientRequest.getClientsPerPage(page, limit, searchValue);
        setClients(response.clients);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (user.role === SUPER_ADMIN) getClients();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Agregar Usuario"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
        <UserCreateForm
        user={user}
        clients={clients}
        />
        </Box>
      </Container>
    </Page>
  );
}

export default UserCreateView;
