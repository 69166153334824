import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import CancelButton from '../../../components/Buttons/Cancel/CancelButton';

function Header({ className, ...rest }) {
  return (
    <Grid
      className={clsx(className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
      <Grid container spacing= {1}>
      <Grid item>
        <Typography
          variant="h3"
          color="textSecondary"
          >
          Agregar {' '}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Nuevo Costo
        </Typography>
      </Grid>
      </Grid>
      </Grid>
      <Grid item>
        <CancelButton
          href="/costos-fijos"
        >
          Cancelar
        </CancelButton>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
