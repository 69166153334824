import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import clientRequest from '../../../requests/api/client';
import { getUsersPerPage } from '../../../requests/api/users';

import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getPriceListsPerPage } from '../../../requests/api/priceLists';
import TabSelection from './TabSelection';
import SellersAssociation from './SellersAssociation';

function StoreCreateView() {
  const classes = useStyles();
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [numberUsers, setNumberUsers] = useState(0);
  const [priceLists, setPriceLists] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(
    account.user.role === SUPER_ADMIN ? null : account.user.clientId,
  );
  const { storePage } = useSelector((state) => state.store);

  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const isSuperAdmin = false;

  const getUsers = async () => {
    try {
      const response = await getUsersPerPage(page, limit, searchValue, 'name|ASC', selectedClientId, isSuperAdmin);
      setUsers(response.users);
      setNumberUsers(response.totalUsers);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    if (account.user.role === SUPER_ADMIN) {
      const getClients = async () => {
        try {
          const response = await clientRequest
            .getClientsPerPage(page, limit, searchValue, selectedClientId);
          setClients(response.clients);
        } catch (err) {
          handleApiResponse(enqueueSnackbar, err, false);
        }
      };

      getClients();
    }

    const getPriceLists = async () => {
      try {
        const response = await getPriceListsPerPage(page, limit, searchValue, selectedClientId);
        setPriceLists(response.priceLists);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    if (selectedClientId) {
      getUsers();
      getPriceLists();
    }
  }, [selectedClientId]);

  useEffect(() => {
    if (selectedClientId) {
      getUsers();
    }
  }, [page, limit, searchValue]);

  return (
    <Page
      className={classes.root}
      title="Agregar Tienda"
    >
      <Container maxWidth={false}>
        <Header />
        <TabSelection />
        <Box mt={3}>
          {storePage === 0 && (
            <Results
              clients={clients}
              account={account}
              users={users}
              setSelectedClientId={setSelectedClientId}
              priceLists={priceLists}
              setSearchValue={setSearchValue}
            />
          )}
          {storePage === 1 && (
            <SellersAssociation
              users={users}
              totalUsers={numberUsers}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              setSearchValue={setSearchValue}
            />
          )}

        </Box>
      </Container>
    </Page>
  );
}

export default StoreCreateView;
