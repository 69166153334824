import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  withStyles,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useDispatch, useSelector } from 'react-redux';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import useStyles from './styles';
import couponRequest from '../../../requests/api/coupon';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';
import {
  setCouponAllProductsCheck, setCouponAllStoresCheck, setCouponCreationPage, setCouponDetails,
} from '../../../actions/couponActions';
import { MONEY, PERCENTAGE } from '../../../utils/const/couponType';

function EditCouponForm({
  className,
  coupon,
  couponId,
  allStores,
  totalStores,
  products,
  couponStoresIds,
  setCouponStoresIds,
  storesToShow,
  setStoresToShow,
  availableStores,
  setAvailableStores,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const account = useSelector((s) => s.account);
  const {
    couponInformation, couponAllProductsCheck, couponAllStoresCheck,
  } = useSelector((state) => state.coupon);

  const DefaultCheckbox = withStyles((theme) => ({
    root: {
      color: theme.palette.primary.main,
      '&$checked': {
        color: theme.palette.primary.main,
      },
    },
    checked: {},
  }))((props) => <Checkbox color="default" {...props} />);
  const popperSx = {
    '& .MuiPaper-root': {
      border: '1px solid black',
      color: 'rgb(229, 228, 226)',
      backgroundColor: 'rgba(64, 64, 64, 0.5)',
    },
    '& .MuiPickersDay-dayWithMargin': {
      color: 'rgb(229, 228, 226)',
      backgroundColor: 'rgba(93, 97, 93)',
    },
  };
  const typeOptions = [
    {
      type: PERCENTAGE,
      name: 'porcentaje',
    },
    {
      type: MONEY,
      name: 'dinero',
    },
  ];
  let couponType = couponInformation.type === typeOptions[0].type ? typeOptions[0] : typeOptions[1];

  const handleCheckboxChange = (e, source) => {
    if (source === 'stores') dispatch(setCouponAllStoresCheck(e.target.checked));
    else if (source === 'products') dispatch(setCouponAllProductsCheck(e.target.checked));
  };

  useEffect(() => {
    if (allStores.length !== 0 && couponStoresIds.length !== 0) {
      // identify stores that are not related to the coupon or are deleted
      const filteredStores = allStores.filter((s) => !couponStoresIds.includes(s.id) && s);
      setAvailableStores(filteredStores);
    }
  }, [allStores]);

  if (account.user.role !== SUPER_ADMIN && account.user.clientId !== couponInformation.clientId) return <Redirect to='/administracion/usuarios' />;
  if (submitSuccess) return <Redirect to="/cupones" />;
  if (coupon === null) return null;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        clientId: couponInformation.clientId,
        name: couponInformation.name || '',
        start: couponInformation.start || null,
        end: couponInformation.end || null,
        type: couponInformation.type || null,
        value: couponInformation.value || '',
        maxDiscount: couponInformation.maxDiscount || '',
        maxTotalUses: couponInformation.maxTotalUses || '',
        maxCustomerUses: couponInformation.maxCustomerUses || '',
        deleted: couponInformation.deleted || false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        type: Yup.string().max(255).required('Debe seleccionar el tipo de descuento'),
        value: Yup.string().max(255).required('Debe ingresar el valor del descuento'),
        start: Yup.date().nullable(),
        end: Yup.date().nullable(),
        maxDiscount: Yup.string().max(255).required('Debe ingresar un valor máximo de descuento'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const sharedData = {
          id: couponInformation.id,
          clientId: values.clientId,
          name: values.name,
          start: values.start,
          end: values.end,
          type: values.type,
          value: values.type === MONEY ? values.value : values.value / 100,
          maxDiscount: values.maxDiscount,
          maxTotalUses: values.maxTotalUses || null,
          maxCustomerUses: values.maxCustomerUses || null,
          deleted: values.deleted,
        };
        if (couponAllStoresCheck && couponAllProductsCheck) {
          try {
            const data = {
              ...sharedData,
              storeIds: allStores.map((item) => item.id),
              productIds: products.map((item) => item.id),
            };
            const response = await couponRequest.editCoupon(data);
            handleApiResponse(enqueueSnackbar, response, true);
            setStatus({ success: true });
            setSubmitting(false);
            setSubmitSuccess(true);
          } catch (error) {
            handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/cupones">Volver a Cupones</Button>);
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        } else {
          dispatch(setCouponDetails(sharedData));
          if (couponAllStoresCheck) {
            dispatch(setCouponCreationPage(2));
          } else {
            dispatch(setCouponCreationPage(1));
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomAutocomplete
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    options={typeOptions}
                    value={couponType}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(e, value) => {
                      if (value === null) return;
                      setFieldValue('type', value.type);
                      couponType = value;
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        required
                        label="Tipo de descuento"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      views={['day', 'month', 'year']}
                      label="Fecha de inicio"
                      value={values.start}
                      onChange={(value) => {
                        if (value === null) return;
                        setFieldValue('start', value);
                      }}
                      PopperProps={{
                        sx: popperSx,
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant='outlined'
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      views={['day', 'month', 'year']}
                      label="Fecha de finalización"
                      value={values.end}
                      onChange={(value) => {
                        if (value === null) return;
                        setFieldValue('end', value);
                      }}
                      PopperProps={{
                        sx: popperSx,
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant='outlined'
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.value && errors.value)}
                    fullWidth
                    helperText={touched.value && errors.value}
                    label="Valor"
                    name="value"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.type === MONEY ? values.value : values.value * 100}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    fullWidth
                    label="Descuento máximo"
                    name="maxDiscount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.maxDiscount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    fullWidth
                    label="Usos máximos total"
                    name="maxTotalUses"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.maxTotalUses}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    fullWidth
                    label="Usos máximos por persona"
                    name="maxCustomerUses"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.maxCustomerUses}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box
                display='flex'
                mt={2}
                justifyContent='space-between'
              >
                <Box
                  display='flex'
                >
                  <FormGroup row>
                    <FormControlLabel
                      control={<DefaultCheckbox checked={couponAllStoresCheck} onChange={(e) => handleCheckboxChange(e, 'stores')} name="allStoresbox" />}
                      label="Asociar todas las tiendas"
                    />
                    <FormControlLabel
                      control={<DefaultCheckbox checked={couponAllProductsCheck} onChange={(e) => handleCheckboxChange(e, 'products')} name="allProdcutsCheckbox" />}
                      label="Asociar todos los productos"
                    />
                  </FormGroup>
                </Box>
                <Box display='flex' justifyContent='flex-end'>

                  {couponAllStoresCheck && couponAllProductsCheck
                    ? (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.orangeButton}
                        disabled={isSubmitting}
                      >
                        Editar Cupón
                      </Button>
                    )
                    : (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.blueButton}
                        disabled={isSubmitting}
                      >
                        Siguiente
                      </Button>
                    )}

                </Box>
              </Box>
            </CardContent>
          </Card>

        </form>
      )}
    </Formik>

  );
}

EditCouponForm.propTypes = {
  className: PropTypes.string,
};

export default EditCouponForm;
