import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import machineRequest from '../../../requests/api/machines';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';

function Results({
  className,
  machine,
  stores,
  clientId,
  selectedStore,
  setSelectedStore,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to='/administracion/maquinas' />;
  }

  return (
        <Formik
         initialValues={{
           machineId: machine.machineId,
           model: machine.model,
           serialNumber: machine.serialNumber,
           storeId: machine.storeId,
         }}
         validationSchema={Yup.object().shape({
           machineId: Yup.string().required('Debe ingresar el id de la máquina'),
           model: Yup.string().required('Debe ingresar el modelo de la máquina'),
           serialNumber: Yup.string().required('Debe ingresar el serial de la máquina'),
           storeId: Yup.number().required('Debe ingresar la tienda'),
         })
        }
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const data = {
              id: machine.id,
              machineId: values.machineId,
              model: values.model,
              serialNumber: values.serialNumber,
              storeId: parseInt(values.storeId, 10),
              clientId,
            };
            const response = await machineRequest.putMachineById(data);

            handleApiResponse(enqueueSnackbar, response, true);
            setStatus({ success: true });
            setSubmitting(false);
            setSubmitSuccess(true);
          } catch (error) {
            handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/app/maquinas">Volver a Máquinas</Button>);
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
        >
          {
            ({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
            }) => (
              <form
              className={clsx(classes.emptyRoot, className)}
              onSubmit={handleSubmit}
              {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <CustomTextField
                        disabled
                        error={Boolean(touched.machineId && errors.machineId)}
                        fullWidth
                        helperText={touched.machineId && errors.machineId}
                        label="Id de la máquina"
                        name="machineId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.machineId}
                        variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <CustomTextField
                        error={Boolean(touched.model && errors.model)}
                        fullWidth
                        helperText={touched.model && errors.model}
                        label="Modelo de la máquina"
                        name="model"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.model}
                        variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <CustomTextField
                        error={Boolean(touched.serialNumber && errors.serialNumber)}
                        fullWidth
                        helperText={touched.serialNumber && errors.serialNumber}
                        label="Numero se serie"
                        name="serialNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.serialNumber}
                        variant="outlined"
                        />
                      </Grid>
                      {stores.length !== 0
                      && <Grid item md={6} xs={12}>
                      <CustomAutocomplete
                      fullWidth
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disablePortal
                      noOptionsText='Sin tiendas para mostrar'
                      placeholder="Buscar nueva tienda"
                      options={stores}
                      value={selectedStore}
                      filterSelectedOptions
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={(e, value) => {
                        if (value === null) {
                          setSelectedStore({
                            id: machine.storeId,
                            name: machine.store.name,
                          });
                          setFieldValue('storeId', machine.storeId);
                          return;
                        }
                        setSelectedStore({
                          id: value.id,
                          name: value.name,
                        });
                        setFieldValue('storeId', value.id);
                      }}
                      renderInput={(params) => (
                       <CustomTextField
                       {...params}
                       label="Buscar nueva tienda"
                       variant="outlined"
                       />
                      )}
                      />
                    </Grid>
                    }
                    </Grid>
                    <Box mt={2} display='flex' justifyContent='flex-end'>
                      <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      >
                      Guardar
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
        </Formik>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  machine: PropTypes.object.isRequired,
};

export default Results;
