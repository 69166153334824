import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ClientCreateForm from './ClientCreateForm';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import clientRequest from '../../../requests/api/client';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

function ClientCreateView() {
  const classes = useStyles();
  const [actecos, setActecos] = useState([]);
  const account = useSelector((state) => state.account);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    const getActecos = async () => {
      const response = await clientRequest.getActecos();
      if (isMountedRef.current) {
        setActecos(response.actecos);
      }
    };

    getActecos();
  }, []);

  if (account.user.role !== SUPER_ADMIN) {
    return <Redirect to="/administracion/usuarios" />;
  }

  return (
    <Page
      className={classes.root}
      title="Agregar Cliente"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
        <ClientCreateForm actecos={actecos} />
        </Box>
      </Container>
    </Page>
  );
}

export default ClientCreateView;
