import React from 'react';
import { Redirect, useHistory } from 'react-router';
import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import Page from '../../components/Layout/Page';
import Logo from '../../components/Layout/Logo';
import LoginForm from './LoginForm';
import AuthService from '../../services/authService';

import useStyles from './styles';

function LoginView() {
  const classes = useStyles();
  const history = useHistory();

  if (AuthService.isAuthenticated()) {
    return <Redirect to="/administracion/admins" />;
  }

  const handleSubmitSuccess = () => {
    history.push('/administracion/admins');
  };

  return (
    <Page
      className={classes.root}
      title="Iniciar Sesión"
    >
      <Container maxWidth="md">
        <Box
          mb={8}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent='center'
        >
          <Logo imgUrl="/img/logos/logo_color.svg" className={classes.logo} />
          <Logo imgUrl="/img/logos/logo_compraqui.png" className={classes.logo} />
        </Box>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar>
            <Typography
              variant="h2"
              color="textPrimary"
            >
              Ingresa
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Ingresa a la plataforma de backoffice
            </Typography>
            <Box mt={3}>
              <LoginForm onSubmitSuccess={handleSubmitSuccess} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default LoginView;
