import axios from '../../utils/axios';

const getMachinesPerPage = async (page, limit, searchValue, clientId, storeId) => {
  const response = await axios.get(`/api/machines?page=${page}&limit=${limit}&searchValue=${searchValue}&clientId=${clientId || 0}&storeId=${storeId || 0}`);
  return response.data;
};

const postNewMachine = async (
  machine,
) => {
  const response = await axios.post('/api/machine/create', {
    machine,
  });
  return response.data;
};

const getMachineById = async (machineId) => {
  const response = await axios.get(`/api/machine/${machineId}`);
  return response.data;
};

const putMachineById = async (machine) => {
  const response = await axios.put('/api/machine/edit', { machine });
  return response.data;
};

const deleteMachines = async (machinesIds) => {
  const response = await axios.put('/api/machine/delete', { machinesIds });
  return response.data;
};

export default {
  getMachinesPerPage,
  postNewMachine,
  putMachineById,
  getMachineById,
  deleteMachines,
};
