import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Container } from '@material-ui/core';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import machineRequest from '../../../requests/api/machines';
import storeRequest from '../../../requests/api/store';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';

function MachineEditView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [machine, setMachine] = useState({});
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const user = useSelector((s) => s.account.user);
  const clientId = user.role === SUPER_ADMIN ? machine.clientId : user.clientId;
  const page = 0;
  const limit = '';
  const searchValue = '';

  useEffect(() => {
    const getMachineById = async () => {
      try {
        const response = await machineRequest.getMachineById(id);
        if (response.success) {
          setMachine(response.machine);
          setSelectedStore({
            id: response.machine.storeId,
            name: response.machine.store.name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getMachineById();
  }, [id]);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await storeRequest.getStoresPerPage(page, limit, searchValue, clientId);
        setStores(response.allStores);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (machine.id) getStores();
  }, [machine]);

  if (!machine.id || !selectedStore) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Editar máquina"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
        <Results
          machine={machine}
          stores={stores}
          clientId={clientId}
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default MachineEditView;
