import * as React from 'react';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';

export default function ActiveModuleButton(props) {
  return (
    <Tooltip
    arrow
    title={<h3>{props.title}</h3>}
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'white',
          '& .MuiTooltip-arrow': {
            color: '#006E90',
          },
          color: '#006E90',
          border: '1px solid',
          borderColor: '#006E90',
        },
      },
    }}
    >
      <div>
        <Button
          sx={{
            backgroundColor: '#006E90',
            color: 'white',
            paddingX: 4,
            paddingY: 2,
            boxShadow: 3,
            width: '100%',
            justifyContent: 'space-between',
            '&:hover': {
              backgroundColor: '#4d9ab3',
            },
          }}
          {...props}
        >
          {props.children}
        </Button>
      </div>
    </Tooltip>
  );
}
