import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import SplashScreen from '../../../components/Loading/SplashScreen';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getOrderById } from '../../../requests/api/order';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';

function OrderDetailView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [comingFromDevolution, setComingFromDevolution] = useState(false);
  const account = useSelector((s) => s.account);
  const history = useHistory();

  useEffect(() => {
    const getOrder = async () => {
      try {
        const response = await getOrderById(id);
        setOrder(response.order);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (history.location.state && history.location.state.prevPath === '/devoluciones') setComingFromDevolution(true);
    getOrder();
  }, []);

  if (!order) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Pedidos"
    >
      <Container maxWidth={false}>
        <Header
        comingFromDevolution={comingFromDevolution}
        />
          <Box mt={3}>
            <Results
              order={order}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              role={account.user.role}
            />
          </Box>
      </Container>
    </Page>
  );
}

export default OrderDetailView;
