import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import CustomTextField from '../../../../components/Inputs/TextField/CustomTextField';
import { SUPER_ADMIN, CLIENT_ADMIN, CLIENT_SELLER } from '../../../../utils/const/user_types';
import useStyles from './styles';
import { editUserData } from '../../../../requests/api/users';
import handleApiResponse from '../../../../utils/handleApiResponse';

function UserEditForm({
  className,
  user,
  stores,
  ...rest
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/usuarios" />;
  }

  return (
    <Formik
      initialValues={{
        name: user.name || '',
        lastname: user.lastname || '',
        role: user.role || '',
        email: user.email || '',
        active: user.active || false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        lastname: Yup.string().max(255).required('Debe ingresar un apellido'),
        role: Yup.string().required('Debe seleccionar un role'),
        active: Yup.boolean(),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            name: values.name,
            lastname: values.lastname,
            role: values.role,
            active: values.active,
          };

          const response = await editUserData(user.id, data);

          handleApiResponse(enqueueSnackbar, response, true);
          setStatus({ success: true });
          setSubmitting(false);
          setSubmitSuccess(true);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/administracion/usuarios">Volver a usuarios</Button>);
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.rootEditForm, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
            <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label="Apellido"
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastname}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    fullWidth
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    fullWidth
                    label="Rol"
                    name="role"
                    onChange={handleChange}
                    select
                    value={values.role}
                    variant="outlined"
                  >
                    {user.role === SUPER_ADMIN
                    && <MenuItem
                    value={SUPER_ADMIN}
                  >
                    Super Admin
                  </MenuItem>
                  }
                    <MenuItem
                      value={CLIENT_ADMIN}
                    >
                      Admin
                    </MenuItem>
                    <MenuItem
                      value={CLIENT_SELLER}
                    >
                      Vendedor
                    </MenuItem>
                  </CustomTextField>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                   <CustomTextField
                    fullWidth
                    label="Estado"
                    name="active"
                    onChange={handleChange}
                    select
                    value={values.active}
                    variant="outlined"
                  >
                    <MenuItem
                      value={true}
                    >
                      ACTIVO
                    </MenuItem>
                    <MenuItem
                      value={false}
                    >
                      INACTIVO
                    </MenuItem>
                  </CustomTextField>
                </Grid>
                <Grid item />
              </Grid>
              <Box mt={2} display='flex' justifyContent='flex-end'>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

UserEditForm.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  stores: PropTypes.array,
};

export default UserEditForm;
