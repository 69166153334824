/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Download as DownloadIcon,
} from 'react-feather';
import { exportCSVOrder } from '../../../requests/api/order';
import handleApiResponse from '../../../utils/handleApiResponse';

function Header({
  className,
  clientName,
  clientId,
  orders,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadCSV = async () => {
    try {
      const response = await exportCSVOrder({ orders, clientName });
      const universalBOM = '\uFEFF';
      const uri = `data:'text/csv';charset=utf-8,${encodeURI(universalBOM + response.file)}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = response.fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  return (
    <Grid
      className={clsx(className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textSecondary"
        >
          Pedidos
        </Typography>
      </Grid>
      <Grid item>
      {(clientId && orders.length)
        ? <Button
      startIcon={<DownloadIcon />}
      component="label"
      onClick={handleDownloadCSV}
      >
        Exportar CSV
      </Button>
        : null}
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
