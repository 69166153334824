import {
  SAVE_FILTERS,
} from '../actions/filterActions';

const initialState = {
  filter: null,
};

// eslint-disable-next-line default-param-last
const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FILTERS: {
      return {
        filter: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default filterReducer;
