import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import useStyles from './styles';
import machineRequest from '../../../requests/api/machines';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';

function Results({
  className, clients, stores, clientAdmin, account, clientId, setClientId, ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to='/administracion/maquinas' />;
  }

  return (
    <Formik
      initialValues={{
        clientId,
        machineId: '',
        model: '',
        serialNumber: '',
        storeId: '',
      }}
      validationSchema={Yup.object().shape({
        clientId: Yup.number().required('Debe ingresar el cliente'),
        machineId: Yup.string().required('Debe ingresar el id de la máquina'),
        model: Yup.string().required('Debe ingresar el modelo de la máquina'),
        serialNumber: Yup.string().required('Debe ingresar el serial de la máquina'),
        storeId: Yup.number().required('Debe ingresar la tienda'),
      })
      }
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            clientId: parseInt(values.clientId, 10),
            machineId: values.machineId,
            model: values.model,
            serialNumber: values.serialNumber,
            storeId: parseInt(values.storeId, 10),
          };
          const response = await machineRequest.postNewMachine(data);

          handleApiResponse(enqueueSnackbar, response, true);
          setStatus({ success: true });
          setSubmitting(false);
          setSubmitSuccess(true);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/app/maquinas">Volver a Máquinas</Button>);
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {
        ({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form
            className={clsx(classes.emptyRoot, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    {account.user.role === SUPER_ADMIN
                      ? <CustomAutocomplete
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        fullWidth
                        disablePortal
                        id="combo-box-demo"
                        options={clients}
                        getOptionLabel={(option) => `${option.name}`}
                        onChange={(e, value) => {
                          if (value === null) {
                            setClientId(null);
                            return;
                          }
                          setClientId(value.id);
                          setFieldValue('clientId', value.id);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            error={Boolean(touched.clientId && errors.clientId)}
                            helperText={touched.clientId && errors.clientId}
                            label="Cliente"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.clientId}
                            variant="outlined"
                            required
                          />
                        )}
                      />
                      : <CustomTextField
                        disabled
                        fullWidth
                        label='Cliente'
                        variant="outlined"
                        defaultValue={clientAdmin.name}
                      />
                    }
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CustomTextField
                      error={Boolean(touched.machineId && errors.machineId)}
                      fullWidth
                      helperText={touched.machineId && errors.machineId}
                      label="Id de la máquina"
                      name="machineId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.machineId}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CustomTextField
                      error={Boolean(touched.model && errors.model)}
                      fullWidth
                      helperText={touched.model && errors.model}
                      label="Modelo de la máquina"
                      name="model"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.model}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CustomTextField
                      error={Boolean(touched.serialNumber && errors.serialNumber)}
                      fullWidth
                      helperText={touched.serialNumber && errors.serialNumber}
                      label="Numero se serie"
                      name="serialNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.serialNumber}
                      variant="outlined"
                    />
                  </Grid>
                  {clientId
                    && <Grid item md={6} xs={12}>
                      <CustomAutocomplete
                        disablePortal
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        noOptionsText='Sin tiendas para mostrar'
                        id="combo-box-demo"
                        placeholder="Tienda"
                        fullWidth
                        options={stores || []}
                        getOptionLabel={(option) => `${option.name}`}
                        onChange={(e, value) => {
                          if (value === null) return;
                          setFieldValue('storeId', value.id);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            label="Tienda"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  }
                </Grid>
                <Box mt={2} display='flex' justifyContent='flex-end'>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Guardar
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </form>
        )}
    </Formik>
  );
}

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
