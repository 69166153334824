import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import storeRequest from '../../../requests/api/store';
import { getUsersPerPage } from '../../../requests/api/users';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getPriceListsPerPage } from '../../../requests/api/priceLists';
import TabSelection from './TabSelection';
import SellersAssociation from './SellersAssociation';

function StoreEditView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [store, setStore] = useState({});
  const [users, setUsers] = useState([]);
  const [numberUsers, setNumberUsers] = useState(0);
  const [priceLists, setPriceLists] = useState([]);
  const account = useSelector((s) => s.account);
  const { id } = useParams();

  const { storePage } = useSelector((state) => state.store);

  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const isSuperAdmin = false;

  const getUsers = async () => {
    try {
      const response = await getUsersPerPage(page, limit, searchValue, 'name|ASC', store.clientId, isSuperAdmin);
      setUsers(response.users);
      setNumberUsers(response.totalUsers);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    if (Object.keys(store).length !== 0) {
      const getPriceLists = async () => {
        try {
          const response = await getPriceListsPerPage(page, limit, searchValue, store.clientId);
          setPriceLists(response.priceLists);
        } catch (err) {
          handleApiResponse(enqueueSnackbar, err, false);
        }
      };

      getUsers();
      getPriceLists();
    }
  }, [store]);

  useEffect(() => {
    const getStoreById = async () => {
      try {
        const response = await storeRequest.getStoreById(id);
        const data = await response.store;
        setStore(data);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getStoreById();
  }, [id]);

  useEffect(() => {
    if (Object.keys(store).length !== 0) {
      getUsers();
    }
  }, [limit, page, searchValue]);

  if (Object.keys(store).length === 0) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Editar tienda"
    >
      <Container maxWidth={false}>
        <Header name={store.name}/>
        <TabSelection />
        <Box mt={3}>
          {storePage === 0 && (
            <Results
              account={account}
              store={store}
              storeId={id}
              users={users}
              priceLists={priceLists}
              setSearchValue={setSearchValue}
            />
          )}
          {storePage === 1 && (
            <SellersAssociation
              users={users}
              totalUsers={numberUsers}
              page={page}
              limit={limit}
              storeId={id}
              setPage={setPage}
              setLimit={setLimit}
              setSearchValue={setSearchValue}
            />
          )}

        </Box>
      </Container>
    </Page>
  );
}

export default StoreEditView;
