import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
import getInitials from '../../../utils/getInitials';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import useStyles from './styles';

function Results({
  clients,
  count,
  searchValue,
  setSearchValue,
  page,
  setPage,
  limit,
  setLimit,
}) {
  const classes = useStyles();
  const [selectedClients, setSelectedClients] = useState([]);
  const [currentClients, setCurrentClients] = useState(clients);

  useEffect(() => { setCurrentClients(clients); }, [clients]);

  const handleSearchValueChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const handleSelectAllClients = (e) => {
    setSelectedClients(e.target.checked
      ? currentClients.map((client) => client.id)
      : []);
  };

  const handleSelectOneClient = (e, clientId) => {
    if (!selectedClients.includes(clientId)) {
      setSelectedClients((prevSelected) => [...prevSelected, clientId]);
    } else {
      setSelectedClients((prevSelected) => prevSelected.filter((id) => id !== clientId));
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  // const enableBulkOperations = selectedClients.length > 0;
  const selectedSomeClients = selectedClients.length > 0
  && selectedClients.length < currentClients.length;
  const selectedAllClients = selectedClients.length === currentClients.length;

  return (
  <div>
    <Card>
    <Box
      p={2}
      minHeight={56}
      display="flex"
      alignItems="center"
    >
      <Grid container spacing={1}>
        <Grid item md={3} xs={12}>
          <CustomTextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleSearchValueChange}
          placeholder="Buscar cliente"
          value={searchValue}
          variant="outlined"
          />
        </Grid>
      </Grid>
    </Box>
    </Card>
    <br/>
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={selectedAllClients}
                indeterminate={selectedSomeClients}
                onChange={handleSelectAllClients}
              />
            </TableCell>
            <TableCell>
              Nombre
            </TableCell>
            <TableCell>
              Actividad
            </TableCell>
            <TableCell align="right">
            <TablePagination
              component="div"
              count={count}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Filas por página"
            />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentClients.map((client) => {
            const isClientSelected = selectedClients.includes(client.id);
            return (
              <TableRow
                hover
                key={client.id}
                selected={isClientSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isClientSelected}
                    onChange={(e) => handleSelectOneClient(e, client.id)}
                    value={isClientSelected}
                  />
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    <Avatar
                      className={classes.avatar}
                      src={client.avatar}
                    >
                      {getInitials(client.name)}
                    </Avatar>
                    <div>
                      <Typography
                        color="inherit"
                        variant="h6"
                      >
                        {client.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                      >
                        {client.address}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                      >
                       {client.city}{', '} {client.country}
                      </Typography>
                    </div>
                  </Box>
                </TableCell>
                <TableCell>
                  {client.activity}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    component={RouterLink}
                    to={`/pos/clientes/${client.id}/editar`}
                  >
                    <SvgIcon fontSize="small">
                      <EditIcon />
                    </SvgIcon>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  clients: PropTypes.array,
};

Results.defaultProps = {
  clients: [],
};

export default Results;
