import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import thousandSeparator from '../../../utils/thousandSeparator';

function ReturnTable({
  devolution,
  user,
}) {
  const fixedReturnDate = devolution.createdAt.substring(0, 10).split('-').reverse().join('/');
  const devolutionAmount = devolution.products.map((p) => (p.returnProduct.totalPrice))
    .reduce((prev, curr) => prev + curr, 0);

  return (
<Box>
  {user.role === SUPER_ADMIN
    && <Box>
       <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                variant="h5"
                color="textPrimary"
                >
                  Cliente:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                variant="h5"
                color="textSecondary"
                >
                  {devolution.order.user.client.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
      </Box>}
    <Box mt={4}>
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item lg={5} xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                variant="h5"
                color="textPrimary"
                >
                  Motivo de devolución:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                variant="h5"
                color="textSecondary"
                >
                  {devolution.reason.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} xs={12}>
            <Grid container spacing ={1}>
              <Grid item>
                <Typography
                variant="h5"
                color="textPrimary"
                >
                  Monto de la devolución:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                variant="h5"
                color="textSecondary"
                >
                  ${' '}{thousandSeparator(devolutionAmount, '.')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} xs={12}>
            <Grid container spacing ={1}>
              <Grid item>
              <Typography
              variant="h5"
              color="textPrimary"
              >
                Fecha:
              </Typography>
              </Grid>
              <Grid item>
              <Typography
              variant="h5"
              color="textSecondary"
              >
                {fixedReturnDate}
              </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </Box>
</Box>
  );
}
ReturnTable.propTypes = {
  className: PropTypes.string,
  devolution: PropTypes.object,
};

ReturnTable.defaultProps = {
  devolution: {},
};

export default ReturnTable;
