import axios from '../../utils/axios';

const getCouponsPerPage = async (page, limit, searchValue, clientId) => {
  const response = await axios.get(`/api/coupons?page=${page}&limit=${limit}&searchValue=${searchValue}&clientId=${clientId || 0}`);
  return response.data;
};

const editCoupon = async (
  {
    id,
    clientId,
    name,
    start,
    end,
    deleted,
    maxDiscount,
    maxTotalUses,
    maxCustomerUses,
    type,
    value,
    storeIds,
    productIds,
  },
) => {
  const response = await axios.put('/api/coupon/edit', {
    id,
    clientId,
    name,
    start,
    end,
    deleted,
    maxDiscount,
    maxTotalUses,
    maxCustomerUses,
    type,
    value,
    storeIds,
    productIds,
  });

  return response.data;
};

const postCreateCoupon = async (
  {
    clientId,
    name,
    start,
    end,
    type,
    value,
    maxDiscount,
    maxTotalUses,
    maxCustomerUses,
    storeIds,
    productIds,
    customerIds,
  },
) => {
  const response = await axios.post('/api/coupon/create', {
    clientId,
    name,
    start,
    end,
    type,
    value,
    maxDiscount,
    maxTotalUses,
    maxCustomerUses,
    storeIds,
    productIds,
    customerIds,
  });
  return response.data;
};

const deleteCoupons = async (couponIds) => {
  const response = await axios.put('/api/coupon/delete', { couponIds });
  return response.data;
};

const getCouponById = async (id) => {
  const response = await axios.get(`/api/coupon/${id}`);
  return response.data;
};

const updateCouponStores = async (data) => {
  const response = await axios.put('/api/coupon/edit/couponStore', data);

  return response.data;
};

export default {
  getCouponsPerPage,
  editCoupon,
  postCreateCoupon,
  deleteCoupons,
  getCouponById,
  updateCouponStores,
};
