import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../../components/Layout/Page';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import UserEditForm from './UserEditForm';
import Header from './Header';

import { getUserDetail } from '../../../../requests/api/users';

import useStyles from './styles';

function UserEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [user, setUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      const response = await getUserDetail(props.match.params.id);

      if (response.success && isMountedRef.current) {
        setUser(response.user);
      }
    };

    getUser();
  }, [props]);

  if (!user) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Editar Usuario"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
        <UserEditForm user={user} />
        </Box>
      </Container>
    </Page>
  );
}

UserEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(UserEditView);
