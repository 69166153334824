import axios from '../../utils/axios';

const getStocksPerPage = async (limit, page, searchValue, storeId, productId, clientId) => {
  const response = await axios.get(`/api/stocks?limit=${limit}&page=${page}&searchValue=${searchValue}&storeId=${storeId}&productId=${productId}&clientId=${clientId}`);
  return response.data;
};

const postExportCSVStock = async (CSVInformation) => {
  const response = await axios.post('/api/stock/download-csv', CSVInformation);
  return response.data;
};

const updateStock = async (stock) => {
  const response = await axios.post('/api/stock/update', { stocksArray: [stock] });
  return response.data;
};

const putStockCSV = async (stockCSV) => {
  const response = await axios.put('/api/stock/update-csv', stockCSV);
  return response.data;
};

export {
  getStocksPerPage,
  postExportCSVStock,
  updateStock,
  putStockCSV,
};
