/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  RESET_PRODUCTS_CHANGE,
  ADD_PRODUCTS_ITEMS,
} from '../actions/productsActions';

const initialState = {
  productsToAddDB: null,
};

// eslint-disable-next-line default-param-last
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PRODUCTS_CHANGE: {
      return produce(state, (draft) => {
        draft.productsToAddDB = null;
      });
    }

    case ADD_PRODUCTS_ITEMS: {
      return produce(state, (draft) => {
        draft.productsToAddDB = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default productReducer;
