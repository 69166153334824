export const RESET_COUPON_CREATION = '@coupon/reset-coupon-creation';
export const SET_COUPON_DETAILS = '@coupon/set-coupon-details';
export const SET_CUPON_CREATION_PAGE = '@coupon/set-coupon-creation-page';
export const SET_COUPON_ALL_STORES_CHECK = '@coupon/set-coupon-all-stores-check';
export const SET_CUPON_ALL_PRODUCTS_CHECK = '@coupon/set-coupon-all-products-check';

export function resetCouponCreation() {
  return {
    type: RESET_COUPON_CREATION,
  };
}

export function setCouponDetails(payload) {
  return {
    type: SET_COUPON_DETAILS,
    payload,
  };
}

export function setCouponCreationPage(payload) {
  return {
    type: SET_CUPON_CREATION_PAGE,
    payload,
  };
}

export function setCouponAllStoresCheck(payload) {
  return {
    type: SET_COUPON_ALL_STORES_CHECK,
    payload,
  };
}

export function setCouponAllProductsCheck(payload) {
  return {
    type: SET_CUPON_ALL_PRODUCTS_CHECK,
    payload,
  };
}
