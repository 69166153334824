/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  RESET_PRICELIST_CHANGE,
  ADD_PRICELIST_ITEMS,
} from '../actions/priceListActions';

const initialState = {
  productsToAddPricelist: null,
};

// eslint-disable-next-line default-param-last
const priceListReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PRICELIST_CHANGE: {
      return produce(state, (draft) => {
        draft.productsToAddPricelist = null;
      });
    }

    case ADD_PRICELIST_ITEMS: {
      return produce(state, (draft) => {
        draft.productsToAddPricelist = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default priceListReducer;
