import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import thousandSeparator from '../../../utils/thousandSeparator';

function ReturnProductsTable({ devolution }) {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  return (
  <Card>
    <CardContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='h5'>
              Producto:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h5'>
              Cantidad:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h5'>
              Precio unitario:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h5'>
              Subtotal:
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devolution.products.length !== 0 && devolution.products.map((p) => (
            <TableRow key={p.id}>
              <TableCell>
                <Typography
                variant='h5'
                >
                  {p.name.toUpperCase()}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                variant='h5'
                >
                  {p.returnProduct.amount}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                variant='h5'
                >
                  $ {thousandSeparator(p.returnProduct.unitPrice, '.')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                variant='h5'
                >
                  $ {thousandSeparator(p.returnProduct.totalPrice, '.')}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={devolution.products && devolution.products.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </CardContent>
  </Card>
  );
}

ReturnProductsTable.propTypes = {
  className: PropTypes.string,
  devolution: PropTypes.object,
};

ReturnProductsTable.defaultProps = {
  devolution: {},
};

export default ReturnProductsTable;
