import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import SplashScreen from '../../../components/Loading/SplashScreen';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import useStyles from './styles';
import { getProductsPerPage } from '../../../requests/api/products';
import clientRequest from '../../../requests/api/client';
import handleApiResponse from '../../../utils/handleApiResponse';
import { resetProductsChange } from '../../../actions/productsActions';

function ProductsListView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const historyPreviousPath = history.location.state && history.location.state.prevPath === '/producto/editar';
  const reducerFilters = useSelector((s) => s.filter.filter);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(historyPreviousPath ? reducerFilters.page : 0);
  const [limit, setLimit] = useState(historyPreviousPath ? reducerFilters.limit : 10);
  const [searchValue, setSearchValue] = useState(historyPreviousPath ? reducerFilters.searchValue : '');
  const account = useSelector((state) => state.account);
  const [selectedClient, setSelectedClient] = useState(historyPreviousPath ? {
    id: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.id
      : account.user.clientId,
    name: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.name
      : null,
  } : {
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });
  const [clients, setClients] = useState([]);
  const [count, setCount] = useState(null);

  const { productsToAddDB } = useSelector((state) => state.product);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await getProductsPerPage(page, limit, searchValue, selectedClient.id);

        setProducts(response.products
          .map((item) => {
            if (item.productStocks) {
              const initialStock = item.productStocks[0] && item.productStocks[0].quantity;
              return ({ ...item, initialStock });
            }
            return item;
          }));
        setCount(response.totalProducts);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage(page, limit, '', selectedClient.id);
        setClients(response.clients);
        if (!selectedClient.id) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (account.user.role === SUPER_ADMIN) getClients();
    if (selectedClient.id) getProducts();
  }, [limit, page, searchValue, selectedClient, productsToAddDB]);

  useEffect(() => {
    dispatch(resetProductsChange());
  }, []);

  if (!selectedClient.id) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Productos"
    >
      <Container maxWidth={false}>
        <Header
          clientId={selectedClient.id}
          clientName={selectedClient.name || 'Productos'}
          products={products}
        />
        <Box mt={3}>
          <Results
            selectedClient={selectedClient}
            products={products}
            page={page}
            count={count || 0}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSelectedClient={setSelectedClient}
            clients={clients}
            role={account.user.role}
            history={history}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default ProductsListView;
