import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory, Redirect } from 'react-router';

import clientRequest from '../../../requests/api/client';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import useStyles from './styles';
import { getPriceListsPerPage } from '../../../requests/api/priceLists';
import handleApiResponse from '../../../utils/handleApiResponse';
import SplashScreen from '../../../components/Loading/SplashScreen';
import { PRICE_LIST_MANAGEMENT } from '../../../utils/const/modules';

function PriceListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const historyPreviousPath = history.location.state && history.location.state.prevPath === '/precio/editar';
  const reducerFilters = useSelector((s) => s.filter.filter);
  const account = useSelector((s) => s.account);
  const [priceLists, setPriceLists] = useState([]);
  const [page, setPage] = useState(historyPreviousPath ? reducerFilters.page : 0);
  const [limit, setLimit] = useState(historyPreviousPath ? reducerFilters.limit : 10);
  const [searchValue, setSearchValue] = useState(historyPreviousPath ? reducerFilters.searchValue : '');
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(historyPreviousPath ? {
    id: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.id
      : account.user.clientId,
    name: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.name
      : null,
  } : {
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });
  const [count, setCount] = useState(null);
  const [currentPriceLists, setCurrentPriceLists] = useState(priceLists);

  if (!account.user.module[PRICE_LIST_MANAGEMENT]) return <Redirect to='/administracion/usuarios' />;

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage('', '', '');
        setClients(response.clients);
        if (!selectedClient.id) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    if (account.user.role === SUPER_ADMIN) getClients();
  }, [limit, page, searchValue, currentPriceLists]);

  useEffect(() => {
    const getPriceLists = async () => {
      try {
        const response = await getPriceListsPerPage(page, limit, searchValue, selectedClient.id);
        setPriceLists(response.priceLists);
        setCount(response.totalPriceLists);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    if (selectedClient.id) getPriceLists();
  }, [selectedClient]);

  if (!selectedClient.id) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Lista de precios"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results
            priceLists={priceLists}
            page={page}
            count={count || 0}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            role={account.user.role}
            clients={clients}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            history={history}
            currentPriceLists={currentPriceLists}
            setCurrentPriceLists={setCurrentPriceLists}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default PriceListView;
