const COUPON_MANAGEMENT = 'couponManagement';
const CLIENT_MANAGEMENT = 'clientManagement';
const E_BILLING = 'eBilling';
const RDC_PAYMENT = 'rdcPayment';
const PRICE_LIST_MANAGEMENT = 'priceListManagement';
const PRICE_CHANGE = 'priceChange';

module.exports = {
  COUPON_MANAGEMENT,
  CLIENT_MANAGEMENT,
  E_BILLING,
  RDC_PAYMENT,
  PRICE_LIST_MANAGEMENT,
  PRICE_CHANGE,
};
