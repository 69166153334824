const GRAMO = 'gramo';
const KILOGRAMO = 'kilogramo';
const UNIDAD = 'unidad';
const LITRO = 'litro';
const MILILITRO = 'mililitro';

const LABELED_UNITS = {
  [GRAMO]: 'Gramo',
  [KILOGRAMO]: 'Kilogramo',
  [UNIDAD]: 'Unidad',
  [LITRO]: 'Litro',
  [MILILITRO]: 'Mililitro',
};

export {
  GRAMO,
  KILOGRAMO,
  UNIDAD,
  LITRO,
  MILILITRO,
  LABELED_UNITS,
};
