import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SplashScreen from '../../../components/Loading/SplashScreen';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getOrdersPerPage } from '../../../requests/api/order';
import clientRequest from '../../../requests/api/client';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';

function OrderListView() {
  const classes = useStyles();
  const history = useHistory();
  const historyPreviousPath = history.location.state && history.location.state.prevPath === '/pedido/detalle';
  const reducerFilters = useSelector((s) => s.filter.filter);
  const { enqueueSnackbar } = useSnackbar();
  const [orders, setOrders] = useState([]);
  const [ordersToCSV, setOrdersToCSV] = useState([]);
  const [page, setPage] = useState(historyPreviousPath ? reducerFilters.page : 0);
  const [limit, setLimit] = useState(historyPreviousPath ? reducerFilters.limit : 10);
  const [startDate, setStartDate] = useState(historyPreviousPath ? reducerFilters.startDate
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(historyPreviousPath ? reducerFilters.endDate
    : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
  const [orderSearchValue, setOrderSearchValue] = useState(historyPreviousPath ? reducerFilters.orderSearchValue : '');
  const [customerSearchValue, setCustomerSearchValue] = useState(historyPreviousPath ? reducerFilters.customerSearchValue : '');
  const [clients, setClients] = useState([]);
  const account = useSelector((s) => s.account);
  const [selectedClient, setSelectedClient] = useState(historyPreviousPath ? {
    id: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.id
      : account.user.clientId,
    name: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.name
      : null,
  } : {
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });
  const [count, setCount] = useState(null);

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage('', '', '');
        setClients(response.clients);
        if (!selectedClient.id) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getOrders = async () => {
      try {
        const response = await getOrdersPerPage(
          page,
          limit,
          orderSearchValue,
          customerSearchValue,
          selectedClient.id,
          startDate,
          endDate,
        );
        setOrders(response.orders);
        setCount(response.totalOrders);
        const ordersToExport = response.orders.map((o) => (
          {
            orderNumber: o.orderNumber,
            billingType: o.billingType,
            billingId: o.billingId,
            machineId: o.machineId,
            store: o.store.name,
            sellerName: o.user.name,
            sellerLastname: o.user.lastname,
            totalAmount: o.totalAmount,
            status: o.status,
            couponDiscount: o.couponDiscount,
            couponNumber: o.couponId,
            promotionId: o.promotionId,
            promotionDiscount: o.promotionDiscount,
            customerName: o.customer ? o.customer.name : null,
            customerLastname: o.customer ? o.customer.lastname : null,
            customerRut: o.customer ? o.customer.rut : null,
            customerMail: o.customer ? o.customer.email : null,
            orderDate: o.createdAt.substring(0, 10).split('-').reverse().join('-'),
            productsQuantity: o.orderProducts.length,
          }
        ));
        setOrdersToCSV(ordersToExport);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (account.user.role === SUPER_ADMIN) getClients();
    if (selectedClient.id) getOrders();
  }, [selectedClient, limit, page, orderSearchValue, customerSearchValue, startDate, endDate]);
  if (!selectedClient.id) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Pedidos"
    >
      <Container maxWidth={false}>
        <Header
        clientId={selectedClient.id}
        clientName={selectedClient.name}
        orders={ordersToCSV}
        />
          <Box mt={3}>
            <Results
              orders={orders}
              page={page}
              count={count || 0}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              orderSearchValue={orderSearchValue}
              setOrderSearchValue={setOrderSearchValue}
              customerSearchValue={customerSearchValue}
              setCustomerSearchValue={setCustomerSearchValue}
              role={account.user.role}
              clients={clients}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              history={history}
            />
          </Box>
      </Container>
    </Page>
  );
}

export default OrderListView;
