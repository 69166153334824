import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import CancelButton from '../../../components/Buttons/Cancel/CancelButton';

function Header({ className, ...rest }) {
  const history = useHistory();
  const handleGoBackToStock = () => {
    history.push('/inventario', { prevPath: '/inventario/editar' });
  };
  return (
    <Grid
      className={clsx(className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
      <Grid container spacing= {1}>
        <Grid item>
          <Typography
            variant="h3"
            color="textSecondary"
            >
            Actualizar
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h3"
            color="textPrimary"
          >
            Inventario
          </Typography>
      </Grid>
      </Grid>
    </Grid>
      <Grid item>
      <CancelButton
        component={Button}
        onClick={() => handleGoBackToStock()}
        >
          Cancelar
        </CancelButton>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
