import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import couponRequest from '../../../requests/api/coupon';
import { setCouponCreationPage, setCouponDetails } from '../../../actions/couponActions';
import handleApiResponse from '../../../utils/handleApiResponse';

import useStyles from './styles';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';

function StoreAssociation({
  stores, totalStores, page, limit, setPage, setLimit, setSearchValue, /* , setStores */
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [selectedStores, setSelectedStores] = useState([]);
  const [currentStores, setCurrentStores] = useState(stores);
  const [selectedStoreName, setSelectedStoreName] = useState('');
  const dispatch = useDispatch();
  const { couponAllProductsCheck, couponInformation } = useSelector((state) => state.coupon);

  const handleSelectAllStores = (event) => {
    if (event.target.checked) {
      const allStores = currentStores.map((store) => store.id);
      setSelectedStores(allStores);
      dispatch(setCouponDetails({ storeIds: allStores }));
      return;
    }
    setSelectedStores([]);
    dispatch(setCouponDetails({ storeIds: [] }));
  };

  const handleSelectOneStore = (event, storeId) => {
    if (!selectedStores.includes(storeId)) {
      setSelectedStores((prevSelected) => [...prevSelected, storeId]);
      dispatch(setCouponDetails({ storeIds: [...selectedStores, storeId] }));
    } else {
      setSelectedStores((prevSelected) => prevSelected.filter((id) => id !== storeId));
      dispatch(setCouponDetails({ storeIds: selectedStores.filter((id) => id !== storeId) }));
    }
  };

  const selectedSomeStores = selectedStores.length > 0
    && selectedStores.length < currentStores.length;
  const selectedAllStores = selectedStores.length === currentStores.length;

  const handleSearchValue = (e, newValue) => {
    if (newValue !== '') { setSearchValue(selectedStoreName); }
  };

  const handleNextStep = async () => {
    if (selectedStores.length === 0) {
      enqueueSnackbar('Debes seleccionar por lo menos una tienda', {
        variant: 'error',
      });
      return;
    }

    if (couponAllProductsCheck) {
      try {
        const response = await couponRequest.postCreateCoupon({
          ...couponInformation,
          storeIds: selectedStores,
        });
        handleApiResponse(enqueueSnackbar, response, true);
        history.push('/cupones');
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    } else {
      dispatch(setCouponDetails({
        storeIds: selectedStores,
      }));
      dispatch(setCouponCreationPage(2));
    }
  };

  useEffect(() => {
    setCurrentStores(stores);
    setSelectedStores(couponInformation.storeIds || []);
  }, [stores]);

  return (
    <Box>
      <Paper
        elevation={3}
      >
        <Box
          display='flex'
          alignItems='center'
          padding='15px 10px'
          justifyContent='space-between'
        >
          <Box
            flex={1}
            mr={4}
          >
            <CustomAutocomplete
              disablePortal
              isOptionEqualToValue={(option, value) => option.name === value.name}
              id="combo-box-demo"
              fullWidth
              noOptionsText='Sin tiendas para mostrar'
              options={stores}
              filterSelectedOptions
              getOptionLabel={(option) => `${option.name}`}
              onChange={(e, newValue) => {
                if (!newValue) setSearchValue('');
                else setSelectedStoreName(newValue.name);
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Buscar tiendas"
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSearchValue}
            >
              Buscar
            </Button>
          </Box>
        </Box>
      </Paper>
      <Box mt={3}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllStores}
                    indeterminate={selectedSomeStores}
                    onChange={handleSelectAllStores}
                  />
                </TableCell>
                <TableCell>
                  Tienda
                </TableCell>
                <TableCell>
                  Dirección
                </TableCell>
                <TableCell>
                  Ciudad
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stores.length !== 0 && stores.map((store) => {
                const isStoreSelected = selectedStores.includes(store.id);
                return (
                  <TableRow
                    key={store.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isStoreSelected}
                        onChange={(event) => handleSelectOneStore(event, store.id)}
                        value={isStoreSelected}
                      />
                    </TableCell>
                    <TableCell>
                      {store.name}
                    </TableCell>
                    <TableCell>
                      {store.address}
                    </TableCell>
                    <TableCell>
                      {store.city}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box
            mt={2}
            className={classes.tablePagination}
          >
            <TablePagination
              component="div"
              count={totalStores}
              onPageChange={(e, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value);
                setPage(0);
              }}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Filas por página"
            />
              <Box display='flex'>
                <Box
                  mr={1}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      dispatch(setCouponCreationPage(0));
                      setSearchValue('');
                    }}
                  >
                    Atrás
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={couponAllProductsCheck ? classes.orangeButton : null}
                    onClick={handleNextStep}
                  >
                    {couponAllProductsCheck ? 'Crear Cupón' : 'Siguiente'}
                  </Button>
                </Box>
              </Box>
          </Box>
        </Paper>
      </Box>
    </Box >

  );
}

StoreAssociation.propTypes = {
  className: PropTypes.string,
};

export default StoreAssociation;
