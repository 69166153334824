import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import storeRequest from '../../../requests/api/store';
import clientRequest from '../../../requests/api/client';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';
import SplashScreen from '../../../components/Loading/SplashScreen';
import { resetStoreCreation } from '../../../actions/storeActions';

function StoreListView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const historyPreviousPath = history.location.state && history.location.state.prevPath === '/tienda/editar';
  const reducerFilters = useSelector((s) => s.filter.filter);
  const [stores, setStores] = useState([]);
  const [page, setPage] = useState(historyPreviousPath ? reducerFilters.page : 0);
  const [limit, setLimit] = useState(historyPreviousPath ? reducerFilters.limit : 10);
  const [searchValue, setSearchValue] = useState(historyPreviousPath ? reducerFilters.searchValue : '');
  const [clients, setClients] = useState([]);
  const account = useSelector((s) => s.account);
  const [selectedClient, setSelectedClient] = useState(historyPreviousPath ? {
    id: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.id
      : account.user.clientId,
    name: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.name
      : null,
  } : {
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });
  const [count, setCount] = useState(null);

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage('', '', '');
        setClients(response.clients);
        // given that I get clients only if I am superAdmin,
        // check if there is no client selected before setting a default clientId
        // with the response's first client
        if (response.clients.length !== 0
          && selectedClient.id === null) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (account.user.role === SUPER_ADMIN) getClients();
    const getStores = async () => {
      try {
        const response = await storeRequest.getStoresPerPage(
          page,
          limit,
          searchValue,
          selectedClient.id,
        );
        setStores(response.allStores);
        setCount(response.totalStores);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (selectedClient.id) getStores();
  }, [selectedClient, limit, page, searchValue]);

  useEffect(() => {
    dispatch(resetStoreCreation());
  }, []);

  if (!selectedClient.id) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Tiendas"
    >
      <Container maxWidth={false}>
        <Header />
          <Box mt={3}>
            <Results
              stores={stores}
              page={page}
              count={count || 0}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              role={account.user.role}
              clients={clients}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              history={history}
            />
          </Box>
      </Container>
    </Page>
  );
}

export default StoreListView;
