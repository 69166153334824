export const RESET_PRODUCTS_CHANGE = '@products/reset-products-change';
export const ADD_PRODUCTS_ITEMS = '@products/add-products-items';

export function resetProductsChange() {
  return {
    type: RESET_PRODUCTS_CHANGE,
  };
}

export function addProductsItems(payload) {
  return {
    type: ADD_PRODUCTS_ITEMS,
    payload,
  };
}
