import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
} from '@material-ui/core';
import OrderTable from './OrderTable';
import OrderProductsTable from './OrderProductsListView';
import PaymentsTable from './PaymentsList';
import CustomerTable from './CustomerTable';

function Results({
  order,
  page,
  setPage,
  limit,
  setLimit,
  role,
}) {
  return (
      <Grid id='results' container spacing={0}>
        <Grid item xs={12}>
          <OrderTable
          order={order}
          role={role}
          />
        </Grid>
        <Grid item xs={12}>
        <Box mt={4}>
          <OrderProductsTable
          order={order}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          role={role}
          />
        </Box>
        </Grid>
        <Grid item xs={12}>
        <Box mt={4}>
          <PaymentsTable
          order={order}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          role={role}
          />
        </Box>
        </Grid>
        <Grid item xs={12}>
        <Box mt={4}>
          <CustomerTable
          order={order}
          />
        </Box>
        </Grid>
      </Grid>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  order: PropTypes.object,
};

Results.defaultProps = {
  order: {},
};

export default Results;
