import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Upload } from 'react-feather';
import clsx from 'clsx';
import {
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import CancelButton from '../../../components/Buttons/Cancel/CancelButton';
import ClientUploadFiles from './ClientUploadFiles';

function Header({ clientId, className, ...rest }) {
  const [open, setOpen] = useState(false);

  return (
    <Grid
      className={clsx(className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <Typography
              variant="h3"
              color="textSecondary"
            >
              Editar
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h3"
              color="textPrimary"
            >
              Cliente
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button startIcon={<Upload />} variant='contained' onClick={() => setOpen(true)} >
          Subir archivos
        </Button>
      </Grid>
      <Grid item>
        <CancelButton
          component={RouterLink}
          to="/pos/clientes"
        >
          Cancelar
        </CancelButton>
      </Grid>
      <ClientUploadFiles clientId= {clientId} open={open} setOpen={setOpen} />
    </Grid>
  );
}

Header.propTypes = {
  clientId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Header;
