import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import useStyles from './styles';

function TabSelection() {
  const classes = useStyles();
  const {
    storePage, storeAllUsersCheck,
  } = useSelector((state) => state.store);

  const handleStyles = (sourcePage, source) => {
    if ((storePage === 0 && source) || (storePage !== sourcePage && source)) {
      return classes.tabSelectionItemDisabled;
    }

    if (storePage === sourcePage && !source) {
      return classes.tabSelectionItemSelected;
    }
    return classes.tabSelectionItem;
  };

  return (
    <Paper
      elevation={3}
      className={classes.tabSelectionPaper}
    >
      <Box
        className={storePage === 0 ? classes.tabSelectionItemSelected : classes.tabSelectionItem}
      >
        <Typography
          variant="h4"
        >
          Información general:
        </Typography>
      </Box>
      <Box
        className={handleStyles(1, storeAllUsersCheck)}
      >
        <Typography
          variant="h4"
        >
          Asignar vendedores:
        </Typography>
      </Box>
    </Paper>
  );
}

TabSelection.propTypes = {
  className: PropTypes.string,
};

export default TabSelection;
