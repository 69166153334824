import React from 'react';
import { Box } from '@material-ui/core';

import Results from './Results';

function ProductPriceForm({
  priceListId, productPrices, clientId, selectedPriceList,
}) {
  return (
    <Box>
        <Box mt={3}>
          <Results
            priceListId={priceListId}
            productPrices={productPrices}
            clientId={clientId}
            selectedPriceList={selectedPriceList}
          />
        </Box>
    </Box>
  );
}

export default ProductPriceForm;
