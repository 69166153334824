import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import SplashScreen from '../../../components/Loading/SplashScreen';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import couponRequest from '../../../requests/api/coupon';
import clientRequest from '../../../requests/api/client';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';
import { COUPON_MANAGEMENT } from '../../../utils/const/modules';
import { resetCouponCreation } from '../../../actions/couponActions';

function CouponListView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [coupons, setCoupons] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [clients, setClients] = useState([]);
  const account = useSelector((s) => s.account);
  const [selectedClient, setSelectedClient] = useState({
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });
  const [count, setCount] = useState(null);

  if (!account.user.module[COUPON_MANAGEMENT]) return <Redirect to='/administracion/usuarios' />;

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage('', '', '');
        setClients(response.clients);
        if (!selectedClient.id) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getCoupons = async () => {
      try {
        const response = await couponRequest.getCouponsPerPage(
          page,
          limit,
          searchValue,
          selectedClient.id,
        );
        setCoupons(response.coupons);
        setCount(response.totalCoupons);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    if (account.user.role === SUPER_ADMIN) getClients();
    if (selectedClient.id) getCoupons();
  }, [selectedClient, limit, page, searchValue]);

  useEffect(() => {
    dispatch(resetCouponCreation());
  }, []);

  if (!selectedClient.id) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Cupones"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results
            coupons={coupons}
            page={page}
            setPage={setPage}
            limit={limit}
            count={count || 0}
            setLimit={setLimit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            role={account.user.role}
            clients={clients}
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default CouponListView;
