import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  withStyles,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import useStyles from './styles';
import storeRequest from '../../../requests/api/store';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';
import { setStoreAllUsersCheck, setStoreCreationPage, setStoreDetails } from '../../../actions/storeActions';

function Results({
  className, clients, users, setSelectedClientId, priceLists, setSearchValue, account, ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [localPriceList, setLocalPriceList] = useState(null);
  const [discountLocalPriceList, setDiscountLocalPriceList] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { storeAllUsersCheck, storeInformation } = useSelector((state) => state.store);

  const DefaultCheckbox = withStyles((theme) => ({
    root: {
      color: theme.palette.primary.main,
      '&$checked': {
        color: theme.palette.primary.main,
      },
    },
    checked: {},
  }))((props) => <Checkbox color="default" {...props} />);

  useEffect(() => {
    if (storeInformation.priceListId && storeInformation.priceListName) {
      setLocalPriceList({ id: storeInformation.priceListId, name: storeInformation.priceListName });
    }
    if (storeInformation.discountPriceListId && storeInformation.discountPriceListName) {
      setDiscountLocalPriceList({
        id: storeInformation.discountPriceListId,
        name: storeInformation.discountPriceListName,
      });
    }
  }, []);

  if (submitSuccess) {
    return <Redirect to="/administracion/tiendas" />;
  }

  return (
    <Formik
      initialValues={{
        name: storeInformation.name || '',
        address: storeInformation.name || '',
        country: storeInformation.country || '',
        city: storeInformation.city || '',
        commune: storeInformation.commune || '',
        longitud: storeInformation.longitud || '',
        lattitude: storeInformation.lattitude || '',
        priceListId: storeInformation.priceListId || '',
        priceListName: storeInformation.priceListName || '',
        discountPriceListId: storeInformation.discountPriceListId || '',
        discountPriceListName: storeInformation.discountPriceListName || '',
        clientId: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        address: Yup.string().max(255).required('Debe ingresar una dirección'),
        country: Yup.string().max(255).required('Debe ingresar el país'),
        city: Yup.string().max(255).required('Debe ingresar la ciudad'),
        commune: Yup.string().max(255).required('Debe ingresar la comuna'),
        longitud: Yup.number().typeError('Debe ingresar un número').max(255).required('Debe ingresar la longitud'),
        lattitude: Yup.number().typeError('Debe ingresar un número').required('Debe ingresar la latitud'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const sharedData = {
          name: values.name,
          address: values.address,
          country: values.country,
          city: values.city,
          commune: values.commune,
          longitud: values.longitud,
          lattitude: values.lattitude,
          priceListId: values.priceListId,
          discountPriceListId: values.discountPriceListId,
          clientId: values.clientId,
        };

        if (storeAllUsersCheck) {
          try {
            const data = {
              ...sharedData,
              userIds: users.map((item) => item.id),
            };

            const response = await storeRequest.postCreateStore(data);

            handleApiResponse(enqueueSnackbar, response, true);
            setStatus({ success: true });
            setSubmitting(false);
            setSubmitSuccess(true);
          } catch (error) {
            handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/administracion/tiendas">Volver a Tiendas</Button>);
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        } else {
          setSearchValue('');
          dispatch(setStoreDetails({
            ...sharedData,
            priceListName: values.priceListName,
            discountPriceListName: values.discountPriceListName,
          }));
          dispatch(setStoreCreationPage(1));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldValue,
        values,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.country && errors.country)}
                    fullWidth
                    helperText={touched.country && errors.country}
                    label="País"
                    name="country"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.country}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.commune && errors.commune)}
                    fullWidth
                    helperText={touched.commune && errors.commune}
                    label="Comuna"
                    name="commune"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.commune}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.address && errors.address)}
                    fullWidth
                    helperText={touched.address && errors.address}
                    label="Dirección"
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.city && errors.city)}
                    fullWidth
                    helperText={touched.city && errors.city}
                    label="Ciudad"
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.city}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.longitud && errors.longitud)}
                    fullWidth
                    helperText={touched.longitud && errors.longitud}
                    label="Longitud"
                    name="longitud"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.longitud}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.lattitude && errors.lattitude)}
                    fullWidth
                    helperText={touched.lattitude && errors.lattitude}
                    label="Latitud"
                    name="lattitude"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lattitude}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomAutocomplete
                    disablePortal
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    id="combo-box-demo"
                    fullWidth
                    // value={{
                    //   id: values.priceListId || '',
                    //   name: priceLists.filter((item) =>
                    // item.id === values.priceListId)[0]?.name || '',
                    // }}
                    options={priceLists}
                    value={localPriceList}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(e, value) => {
                      if (value === null) return;
                      setFieldValue('priceListId', value.id);
                      setFieldValue('priceListName', value.name);
                      setLocalPriceList(value);
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        label="Lista Precios"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomAutocomplete
                    disablePortal
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    id="combo-box-demo"
                    fullWidth
                    options={priceLists}
                    getOptionLabel={(option) => `${option.name}`}
                    value={discountLocalPriceList}
                    onChange={(e, value) => {
                      if (value === null) return;
                      setFieldValue('discountPriceListId', value.id);
                      setFieldValue('discountPriceListName', value.name);
                      setDiscountLocalPriceList(value);
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        label="Lista Precios Descuento"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {account.user.role === SUPER_ADMIN
                  && <Grid item md={6} xs={12}>
                    <CustomAutocomplete
                      disablePortal
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      id="combo-box-demo"
                      fullWidth
                      filterSelectedOptions
                      options={clients}
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={(e, value) => {
                        if (value === null) return;
                        setFieldValue('clientId', value.id);
                        setSelectedClientId(value.id);
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          label="Buscar cliente"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                }
              </Grid>
              <Box
                display='flex'
                mt={2}
                justifyContent='space-between'
              >
                <Box
                  display='flex'
                > <FormGroup row>
                    <FormControlLabel
                      control={<DefaultCheckbox checked={storeAllUsersCheck} onChange={(e) => dispatch(setStoreAllUsersCheck(e.target.checked))} name="allUsersbox" />}
                      label="Asociar todos las usuarios"
                    />
                  </FormGroup>
                </Box>
                <Box display='flex' justifyContent='flex-end'>

                  {storeAllUsersCheck
                    ? (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.orangeButton}
                        disabled={isSubmitting}
                      >
                        Crear Tienda
                      </Button>
                    )
                    : (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.blueButton}
                        disabled={isSubmitting}
                      >
                        Siguiente
                      </Button>
                    )}

                </Box>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
