import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';

import { Redirect } from 'react-router-dom';
import clientRequest from '../../../requests/api/client';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';

function ClientEditForm({
  className,
  client,
  actecos,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [selected, setSelected] = useState(client.actecos);

  if (submitSuccess) {
    return <Redirect to="/pos/clientes" />;
  }

  return (
    <Formik
      initialValues={{
        name: client.name,
        country: client.country,
        address: client.address,
        activity: client.activity,
        commune: client.commune,
        city: client.city,
        identificationNumber: client.identificationNumber,
        // New fields
        phone: client.phone,
        email: client.email,
        certRut: client.certRut,
        certToken: '',
        officeSII: client.officeSII,
        actecos: selected,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        country: Yup.string().max(255).required('Debe ingresar el país'),
        address: Yup.string().max(255).required('Debe ingresar una dirección'),
        activity: Yup.string().max(255).required('Debe ingresar un rubro/actividad'),
        commune: Yup.string().max(255).required('Debe ingresar la comuna'),
        city: Yup.string().max(255).required('Debe ingresar la ciudad'),
        identificationNumber: Yup.string().required('Debe ingresar el identificador tributario'),
        certRut: Yup.string().max(255).required('Debe ingresar el RUT del certificado'),
        phone: Yup.string().max(255).required('Debe ingresar el teléfono'),
        email: Yup.string().max(255).required('Debe ingresar un correo'),
        officeSII: Yup.string().max(255).required('Debe ingresar la oficina'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            id: client.id,
            name: values.name,
            country: values.country,
            address: values.address,
            activity: values.activity,
            commune: values.commune,
            city: values.city,
            certRut: values.certRut,
            certToken: values.certToken,
            phone: values.phone,
            email: values.email,
            officeSII: values.officeSII,
            identificationNumber: values.identificationNumber,
            actecos: selected,
          };
          if (!values.certToken) delete data.certToken;
          const response = await clientRequest.putClientById(data);

          handleApiResponse(enqueueSnackbar, response, true);
          setStatus({ success: true });
          setSubmitting(false);
          setSubmitSuccess(true);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/pos/clientes">Volver a Clientes</Button>);
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.country && errors.country)}
                    fullWidth
                    helperText={touched.country && errors.country}
                    label="Pais"
                    name="country"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.country}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.address && errors.address)}
                    fullWidth
                    helperText={touched.address && errors.address}
                    label="Dirección"
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.activity && errors.activity)}
                    fullWidth
                    helperText={touched.activity && errors.activity}
                    label="Rubro o actividad"
                    name="activity"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.activity}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.commune && errors.commune)}
                    fullWidth
                    helperText={touched.commune && errors.commune}
                    label="Comuna"
                    name="commune"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.commune}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                <CustomTextField
                    error={Boolean(touched.city && errors.city)}
                    fullWidth
                    helperText={touched.city && errors.city}
                    label="Ciudad"
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.city}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                <CustomTextField
                    error={Boolean(touched.identificationNumber && errors.identificationNumber)}
                    fullWidth
                    helperText={touched.identificationNumber && errors.identificationNumber}
                    label="Identificador tributario"
                    name="identificationNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.identificationNumber}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.certRut && errors.certRut)}
                    fullWidth
                    helperText={touched.certRut && errors.certRut}
                    label="Rut Certificado"
                    name="certRut"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.certRut}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.certToken && errors.certToken)}
                    fullWidth
                    helperText={touched.certToken && errors.certToken}
                    label="Clave certificado"
                    name="certToken"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.certToken}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.officeSII && errors.officeSII)}
                    fullWidth
                    helperText={touched.officeSII && errors.officeSII}
                    label="Oficina SII"
                    name="officeSII"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.officeSII}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    label="Teléfono"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                <Autocomplete
                  multiple
                  name='actecos'
                  options={actecos}
                  value={selected}
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    setSelected(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Actividades Económicas"
                      variant="outlined"
                    />
                  )}
                />
                </Grid>
              </Grid>
              <Box mt={2} display='flex' justifyContent='flex-end'>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ClientEditForm.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
  actecos: PropTypes.array.isRequired,
};

export default ClientEditForm;
