import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#006E90',
    },
    '& .MuiInputLabel-root': {
      color: '#006E90',
    },
  },
  emptyRoot: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  filtersContainer: {
    padding: '20px 10px',
  },
  containerBox: {
    width: '100%',
    height: '400px',
  },
  iconContainer: {
    margin: '5% 0% 0% 0%',
    width: '100%',
    height: '100px',
  },
  textContainer: {
    height: '70px',
    padding: '3% 0% 0% 40%',
  },
}));

export default useStyles;
