/* eslint-disable no-underscore-dangle */
import axios from '../../utils/axios';

const getProductsPerPage = async (page, limit, searchValue, clientId) => {
  const response = await axios.get(`/api/products?page=${page}&limit=${limit}&searchValue=${searchValue}&clientId=${clientId || 0}`);
  return response.data;
};

const getProductById = async (productId) => {
  const response = await axios.get(`/api/product/${productId}`);
  return response.data;
};

const postNewProduct = async (product) => {
  const response = await axios.post('/api/product/create', product);
  return response.data;
};

const postCheckProductsCSV = async (fileToCheck) => {
  const response = await axios.post(
    '/api/product/check-file',
    fileToCheck,
    {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fileToCheck._boundary}`,
      },
    },
  );
  return response.data;
};

const postProductsFromCSV = async ({ clientId, newUpdateProducts }) => {
  const response = await axios.post('/api/product/upload-products-csv', { clientId, newUpdateProducts });
  return response.data;
};

const postExportCSVProducts = async (CSVInformation) => {
  const response = await axios.post('/api/product/download-csv', CSVInformation);
  return response.data;
};

const putProduct = async (product) => {
  const response = await axios.put('/api/product/edit', { ...product });
  return response.data;
};

const deleteProducts = async (productsIds) => {
  const response = await axios.put('/api/products/delete', { productsIds });
  return response.data;
};

export {
  getProductsPerPage,
  getProductById,
  postNewProduct,
  postCheckProductsCSV,
  postProductsFromCSV,
  postExportCSVProducts,
  putProduct,
  deleteProducts,
};
