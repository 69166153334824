import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import clientRequest from '../../../requests/api/client';
import storeRequest from '../../../requests/api/store';
import handleApiResponse from '../../../utils/handleApiResponse';

import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';

function MachineListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [stores, setStores] = useState([]);
  const account = useSelector((s) => s.account);
  const [clients, setClients] = useState(null);
  const [clientId, setClientId] = useState(
    account.user.role === SUPER_ADMIN ? null : account.user.clientId,
  );
  const [clientAdmin, setClientAdmin] = useState(null);
  const limit = '';
  const page = 0;
  const searchValue = '';

  useEffect(() => {
    const getClientById = async () => {
      try {
        const response = await clientRequest.getClientById(clientId);
        setClientAdmin(response.client);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (account.user.role !== SUPER_ADMIN && clientId) getClientById();
  }, [clientId, limit, page, searchValue]);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await storeRequest
          .getStoresPerPage(page, limit, searchValue, clientId);
        setStores(response.allStores);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    const getClients = async () => {
      try {
        const response = await clientRequest
          .getClientsPerPage(page, limit, searchValue, clientId);
        setClients(response.clients);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (clientId) getStores();
    if (account.user.role === SUPER_ADMIN) getClients();
  }, [clientId, limit, page, searchValue]);

  if ((account.user.role === SUPER_ADMIN && !clients)
  || (account.user.role !== SUPER_ADMIN && !clientAdmin)) return 0;

  return (
    <Page
      className={classes.root}
      title="Máquinas"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results
          clients={clients}
          stores={stores}
          clientAdmin={clientAdmin}
          account={account}
          clientId={clientId}
          setClientId={setClientId}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default MachineListView;
