import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getOrdersByStatus } from '../../../requests/api/order';
import clientRequest from '../../../requests/api/client';
import storeRequest from '../../../requests/api/store';
import { getCostsPerPage } from '../../../requests/api/fixedCosts';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import { ORDER_APPROVED } from '../../../utils/const/orderStatus';
import { TARJETA_CREDITO } from '../../../utils/const/paymentTypes';
import SplashScreen from '../../../components/Loading/SplashScreen';

export default function SellAndCostChart() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const requestUser = useSelector((s) => s.account.user);
  const [clients, setClients] = useState(null);
  const [selectedStore, setSelectedStore] = useState({
    id: null,
    name: null,
  });
  const [selectedClient, setSelectedClient] = useState({
    id: requestUser.role === SUPER_ADMIN ? null : requestUser.clientId,
    name: null,
  });
  const [stores, setStores] = useState([
    {
      address: null,
      city: null,
      clientId: selectedClient.id,
      id: null,
      name: 'Todas',
    },
  ]);
  const [orders, setOrders] = useState(null);
  const [incomes, setIncomes] = useState({
    received: null,
    pending: null,
  });
  const [costs, setCosts] = useState({
    fixedCosts: 0,
    variableCosts: 0,
  });
  const [filterDate, setFilterDate] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });
  const [costsDate, setCostsDate] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });

  const today = new Date();
  const twoDaysAgo = new Date(today - 1000 * 60 * 60 * 24 * 2);

  const page = '';
  const limit = '';
  const searchValue = '';

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage(page, limit, searchValue);
        if (!clients) setClients(response.clients);
        if (selectedClient.id === null) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getStores = async () => {
      try {
        const response = await storeRequest.getStoresPerPage(
          page,
          limit,
          searchValue,
          selectedClient.id,
        );
        if (!response.success) {
          setStores([{
            id: null,
            name: 'Sin tiendas asociadas',
          }]);
          setSelectedStore({
            id: null,
            name: 'Sin tiendas asociadas',
          });
          return;
        }
        setStores([
          {
            address: null,
            city: null,
            clientId: selectedClient.id,
            id: null,
            name: 'Todas',
          },
          ...response.allStores,
        ]);
        if (selectedStore.id === null && selectedStore.name !== 'Todas') {
          setSelectedStore({
            id: response.allStores[0].id,
            name: response.allStores[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getApprovedOrders = async () => {
      try {
        const response = await getOrdersByStatus(
          selectedClient.id,
          ORDER_APPROVED,
          dayjs(filterDate.startDate),
          dayjs(filterDate.endDate),
        );
        if (!response.orders.length) {
          setCosts((prevVal) => ({
            ...prevVal,
            variableCosts: 0,
          }));
          setOrders(null);
          return;
        }
        setOrders(response.orders);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getCosts = async () => {
      const response = await getCostsPerPage(
        page,
        limit,
        searchValue,
        selectedStore.id,
        selectedClient.id,
        dayjs(costsDate.startDate),
        dayjs(costsDate.endDate),
      );
      if (response.success) {
        const costsSum = response.costs
          .map((c) => c.cost)
          .reduce((prevVal, currVal) => prevVal + currVal, 0);
        setCosts((prev) => ({ ...prev, fixedCosts: costsSum }));
      }
    };
    if (selectedClient.id) {
      getApprovedOrders();
      getCosts();
      getStores();
    }
    if (requestUser.role === SUPER_ADMIN && !selectedClient.id) getClients();
  }, [selectedClient, filterDate, selectedStore]);

  useEffect(() => {
    if (!orders) {
      setCosts((prevVal) => ({
        ...prevVal,
        variableCosts: 0,
      }));
      setIncomes({
        received: null,
        pending: null,
      });
      return;
    }
    // keep every order's payments
    const payments = orders.map((o) => o.payments).flat();
    // keep payments made by credit card
    const cardArray = payments.filter((p) => p.type === TARJETA_CREDITO).flat();
    // keep as pending payments made by credit card less than 2 days ago
    const pendingPayments = cardArray.filter((p) => new Date(p.createdAt) > twoDaysAgo).flat();
    // keep payments made with another payment method other than card
    const approvedPayments = payments.filter((p) => p.type !== TARJETA_CREDITO).flat();
    // add to approved payments those that were made by card more than two days ago
    cardArray.forEach((p) => {
      if (new Date(p.createdAt) <= twoDaysAgo) approvedPayments.push(p);
    });
    // make pending payments sum
    let pendingAmount = pendingPayments.map((p) => p.amount);
    if (pendingAmount.length) pendingAmount = pendingAmount.reduce((prev, curr) => prev + curr);
    // make approved payments sum
    let approvedAmount = approvedPayments.map((p) => p.amount);
    if (approvedAmount.length) {
      approvedAmount = approvedAmount.reduce((prev, curr) => prev + curr);
    }
    setIncomes({
      received: approvedAmount,
      pending: pendingAmount,
    });
    const productsSoldCost = orders.map((o) => o.orderProducts).flat();
    const productsSoldCostAmount = productsSoldCost.length !== 0 ? productsSoldCost
      .map((p) => p.quantity * p.unitCost)
      .reduce((prev, curr) => prev + curr) : 0;
    setCosts((prev) => ({ ...prev, variableCosts: productsSoldCostAmount }));
  }, [orders]);

  if ((!selectedClient.id && !incomes.pending)
    || (!selectedClient.id && !selectedStore.id)
    || (!selectedClient.id && !orders)
    || (!selectedClient.id && !costs.fixedCosts)) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title='Gráficos'>
      <Container>
        <Header />
        <Box mt={3}>
          <Results
            incomes={incomes}
            stores={stores}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            costsDate={costsDate}
            setCostsDate={setCostsDate}
            costs={costs}
            clients={clients}
            role={requestUser.role}
            SUPER_ADMIN={SUPER_ADMIN}
          />
        </Box>
      </Container>
    </Page>
  );
}
