import axios from '../../utils/axios';

const postCreateStore = async (
  {
    name,
    address,
    country,
    city,
    commune,
    longitud,
    lattitude,
    priceListId,
    discountPriceListId,
    clientId,
    userIds,
  },
) => {
  const response = await axios.post('/api/store/create', {
    name,
    address,
    country,
    city,
    commune,
    longitud,
    lattitude,
    priceListId,
    discountPriceListId,
    clientId,
    userIds,
  });
  return response.data;
};

const getStoresPerPage = async (page, limit, searchValue, clientId) => {
  const response = await axios.get(`/api/store/all?page=${page}&limit=${limit}&searchValue=${searchValue}&clientId=${clientId}`);
  return response.data;
};

const putStore = async (store) => {
  const response = await axios.put(`/api/store/${store.storeId}/edit`, { store });
  return response.data;
};

const getStoreById = async (storeId) => {
  const response = await axios.get(`/api/store/${storeId}`);
  return response.data;
};

const putUserStoreFromStore = async (data) => {
  const { storeId } = data;
  const response = await axios.put(`/api/store/${storeId}/userStore`, data);
  return response.data;
};

const deleteStores = async (storesIds) => {
  const response = await axios.put('/api/store/delete', storesIds);
  return response.data;
};

export default {
  postCreateStore,
  getStoresPerPage,
  putStore,
  getStoreById,
  putUserStoreFromStore,
  deleteStores,
};
