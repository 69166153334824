import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useParams, Redirect } from 'react-router';
import Page from '../../../components/Layout/Page';
import EditCouponForm from './EditCouponForm';
import Header from './Header';

import { COUPON_MANAGEMENT } from '../../../utils/const/modules';
import useStyles from './styles';
import couponRequest from '../../../requests/api/coupon';
import storeRequest from '../../../requests/api/store';
import handleApiResponse from '../../../utils/handleApiResponse';
import TabSelection from './TabSelection';
import { setCouponAllProductsCheck, setCouponAllStoresCheck, setCouponDetails } from '../../../actions/couponActions';
import StoreAssociation from './StoreAssociation';
import ProductsAssociation from './ProductsAssociation';
import { getProductsPerPage } from '../../../requests/api/products';

function CouponEditView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const { couponPage, couponInformation } = useSelector((state) => state.coupon);

  const [couponStoresIds, setCouponStoresIds] = useState([]);
  const [availableStores, setAvailableStores] = useState([]);
  const [storesToShow, setStoresToShow] = useState([]);
  const [stores, setStores] = useState([]);
  const [numberStores, setNumberStores] = useState(0);
  const [products, setProducts] = useState([]);
  const [numberProducts, setNumberProducts] = useState(0);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [limitProduct, setLimitProduct] = useState(5);
  const [pageProduct, setPageProduct] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueProduct, setSearchValueProduct] = useState('');

  if (!account.user.module[COUPON_MANAGEMENT]) return <Redirect to='/administracion/usuarios' />;

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await storeRequest
          .getStoresPerPage(page, limit, searchValue, couponInformation.clientId);
        // save stores that are not related to coupon yet to set autocomplete options
        setStores(response.allStores);
        setNumberStores(response.totalStores);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    const getProducts = async () => {
      try {
        const response = await getProductsPerPage(
          pageProduct,
          limitProduct,
          searchValueProduct,
          couponInformation.clientId,
        );
        setProducts(response.products);
        setNumberProducts(response.totalProducts);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (Object.keys(couponInformation).length) {
      getStores();
      getProducts();
    }
  }, [couponInformation]);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await storeRequest
          .getStoresPerPage(page, limit, searchValue, couponInformation.clientId);
        // save stores that are not related to coupon yet to set autocomplete options
        setStores(response.allStores);
        setNumberStores(response.totalStores);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (Object.keys(couponInformation).length) {
      getStores();
    }
  }, [page, limit, searchValue, couponInformation]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await getProductsPerPage(
          pageProduct,
          limitProduct,
          searchValueProduct,
          couponInformation.clientId,
        );
        setProducts(response.products);
        setNumberProducts(response.totalProducts);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (Object.keys(couponInformation).length) {
      getProducts();
    }
  }, [pageProduct, limitProduct, searchValueProduct, couponInformation]);

  useEffect(() => {
    const getCoupon = async () => {
      try {
        const response = await couponRequest.getCouponById(id);
        const auxCouponInformationStore = couponInformation.storeIds
          ? couponInformation.storeIds : [];
        const auxCouponInformationProduct = couponInformation.productIds
          ? couponInformation.productIds : [];
        dispatch(setCouponDetails({
          ...response.coupon,
          storeIds: [...new Set([
            ...auxCouponInformationStore,
            ...response.coupon.couponStores.map((item) => item.storeId),
          ])],
          productIds: [...new Set([
            ...auxCouponInformationProduct,
            ...response.coupon.couponProducts.map((item) => item.productId),
          ])],
        }));
        // save stores that are related to coupon and are not deleted
        const storesNotDeleted = response.coupon.couponStores
          && response.coupon.couponStores.filter((el) => el.deleted === false);
        const productsNotDeleted = response.coupon.couponProducts
          && response.coupon.couponProducts.filter((el) => el.deleted === false);

        if (couponPage === 0) {
          dispatch(setCouponAllStoresCheck(!(storesNotDeleted && storesNotDeleted.length > 0)));
          dispatch(setCouponAllProductsCheck(!(productsNotDeleted
            && productsNotDeleted.length > 0)));
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getCoupon();
  }, []);

  if (!Object.keys(couponInformation).length || !stores) return null;

  return (
    <Page
      className={classes.root}
      title="Editar cupones"
    >
      <Container maxWidth={false}>
        <Header
          name={couponInformation.name}
        />
        <TabSelection />
        {couponPage === 0 && (
          <Box mt={3}>
            <EditCouponForm
              allStores={stores}
              totalStores={numberStores}
              products={products}
              couponId={id}
              couponStoresIds={couponStoresIds}
              setCouponStoresIds={setCouponStoresIds}
              storesToShow={storesToShow}
              setStoresToShow={setStoresToShow}
              availableStores={availableStores}
              setAvailableStores={setAvailableStores}
            />
          </Box>
        )}
        {couponPage === 1 && (
          <Box mt={3}>
            <StoreAssociation
              stores={stores}
              totalStores={numberStores}
              setStores={setStores}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              setSearchValue={setSearchValue}
            />
          </Box>
        )}
        {couponPage === 2 && (
          <Box mt={3}>
            <ProductsAssociation
              products={products}
              totalProducts={numberProducts}
              page={pageProduct}
              limit={limitProduct}
              setPage={setPageProduct}
              setLimit={setLimitProduct}
              setSearchValue={setSearchValue}
              setSearchValueProduct={setSearchValueProduct}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default CouponEditView;
