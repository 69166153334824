/* eslint-env browser */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormData from 'form-data';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import {
  Download as DownloadIcon,
  Upload as UploadIcon,
} from 'react-feather';
import CancelButton from '../../../components/Buttons/Cancel/CancelButton';

import useStyles from './styles';
import { postExportCSV, postCheckCSV } from '../../../requests/api/priceLists';
import handleApiResponse from '../../../utils/handleApiResponse';
import { addPriceListItems } from '../../../actions/priceListActions';

function Header({
  priceListName, products, clientId, className, ...rest
}) {
  const history = useHistory();

  const handleGoBackToPriceList = () => {
    history.push('/precios', { prevPath: '/precio/editar' });
  };

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState('');

  const handleUploadFile = async () => {
    try {
      const fileToCheck = new FormData();
      fileToCheck.append('file', selectedFile);
      fileToCheck.append('clientId', clientId);
      const response = await postCheckCSV(fileToCheck);
      dispatch(addPriceListItems(response));
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
      setSelectedFile('');
    }
  };

  const handleDownloadCSV = async () => {
    try {
      const response = await postExportCSV({ products, priceListName });
      const universalBOM = '\uFEFF';
      const uri = `data:'text/csv';charset=utf-8,${encodeURI(universalBOM + response.file)}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = response.fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  return (
    <Grid
      className={clsx(classes.rootHeader, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
    <Grid container spacing= {1}>
      <Grid item>
        <Typography
          variant="h3"
          color="textSecondary"
          >
          Editar
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Lista de Precios
        </Typography>
    </Grid>
    </Grid>
  </Grid>
      <Grid item>
      {!selectedFile && (
          <>
            <Button
              startIcon={<UploadIcon />}
              component="label"
            >
              Importar CSV
              <input
                type="file"
                accept=".csv"
                hidden
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
            </Button>
          </>
      )}
        {selectedFile && (
          <Button
            startIcon={<UploadIcon />}
            type='Submit'
            onClick={handleUploadFile}
          >
            Subir Archivo
          </Button>
        )}
        <Button
          startIcon={<DownloadIcon />}
          component="label"
          onClick={handleDownloadCSV}
        >
          Exportar CSV
        </Button>
        <CancelButton
          component={Button}
          onClick={() => handleGoBackToPriceList()}
        >
          Cancelar
        </CancelButton>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
