import axios from '../../utils/axios';

const getOrdersPerPage = async (
  page,
  limit,
  orderSearchValue,
  customerSearchValue,
  clientId,
  startDate,
  endDate,
) => {
  const response = await axios.get(`/api/orders?page=${page}&limit=${limit}&&orderSearchValue=${orderSearchValue}&customerSearchValue=${customerSearchValue}&clientId=${clientId}&startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};

const getOrderById = async (id) => {
  const response = await axios.get(`/api/order/${id}`);
  return response.data;
};

const exportCSVOrder = async (csvData) => {
  const response = await axios.post('/api/order/downloadCsv', csvData);
  return response.data;
};

const getOrdersByStatus = async (clientId, status, startDate, endDate) => {
  const response = await axios.get(`/api/orders/status?clientId=${clientId}&status=${status}&startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};
export {
  getOrdersPerPage,
  getOrderById,
  exportCSVOrder,
  getOrdersByStatus,
};
