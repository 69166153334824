/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  RESET_COUPON_CREATION,
  SET_COUPON_ALL_STORES_CHECK,
  SET_COUPON_DETAILS,
  SET_CUPON_ALL_PRODUCTS_CHECK,
  SET_CUPON_CREATION_PAGE,
} from '../actions/couponActions';

const initialState = {
  couponInformation: {},
  couponPage: 0,
  couponAllStoresCheck: false,
  couponAllProductsCheck: false,
};

// eslint-disable-next-line default-param-last
const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_COUPON_CREATION: {
      return produce(state, (draft) => {
        draft.couponInformation = {};
        draft.couponPage = 0;
        draft.couponAllStoresCheck = false;
        draft.couponAllProductsCheck = false;
      });
    }

    case SET_COUPON_DETAILS: {
      return produce(state, (draft) => {
        const auxCouponInfo = { ...state.couponInformation };
        draft.couponInformation = { ...auxCouponInfo, ...action.payload };
      });
    }

    case SET_CUPON_CREATION_PAGE: {
      return produce(state, (draft) => {
        draft.couponPage = action.payload;
      });
    }

    case SET_COUPON_ALL_STORES_CHECK: {
      return produce(state, (draft) => {
        draft.couponAllStoresCheck = action.payload;
      });
    }

    case SET_CUPON_ALL_PRODUCTS_CHECK: {
      return produce(state, (draft) => {
        draft.couponAllProductsCheck = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default couponReducer;
