import React from 'react';
import {
  Card,
  CardContent,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

export default function CustomerTable({ order }) {
  return (
<Card>
  <CardContent>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant='h4'>
            Comprador:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
            {order.customer ? `${order.customer.name} ${order.customer.lastname}` : 'No registrado'}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
            Teléfono:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
            {order.customer ? order.customer.phone : 'S/I'}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
            Vendedor/a:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
            {order.user.name}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant='h4'>
            Sucursal:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
            {order.store.name}
            </Typography>
            <Typography variant='h4'>
              {order.store.address}{', '}
            </Typography>
            <Typography variant='h4'>
              {order.store.city}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
              SmartPOS TBK:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
              {order.rdcMachine ? order.rdcMachine.machineId : 'S/I'}
            </Typography>
            <Typography variant='h4'>
              {order.rdcMachine ? order.rdcMachine.serialNumber : 'S/I'}
            </Typography>
            <Typography variant='h4'>
              {order.rdcMachine ? order.rdcMachine.model : 'S/I'}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
              Número de boleta:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
              {order.orderNumber}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  </CardContent>
</Card>
  );
}
