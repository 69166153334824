import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  Search as SearchIcon,
} from 'react-feather';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import getInitials from '../../../utils/getInitials';
import useStyles from './styles';

function Results({
  customersToShow,
  count,
  searchValue,
  setSearchValue,
  page,
  setPage,
  limit,
  setLimit,
  role,
  clients,
  selectedClient,
  setSelectedClient,
  customersWithDebt,
  setCustomersWithDebt,
}) {
  const classes = useStyles();

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  const handleSearchValueChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <Card>
        <Box
          p={2}
          minHeight={56}
          display="flex"
          alignItems="center"
        >
          <Grid container spacing={1}>
            {role === SUPER_ADMIN
              && <Grid item md={4} xs={12}>
                <CustomAutocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disablePortal
                  noOptionsText='Sin clientes para mostrar'
                  id="combo-box-demo"
                  placeholder="Buscar dueño"
                  options={clients}
                  value={selectedClient}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, value) => {
                    if (value === null) {
                      setSelectedClient({
                        id: clients[0].id,
                        name: clients[0].name,
                      });
                      return;
                    }
                    setSelectedClient({
                      id: value.id,
                      name: value.name,
                    });
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      label="Buscar dueño"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            }
            <Grid item md={4} xs={12}>
              <CustomTextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearchValueChange}
                placeholder="Buscar cliente"
                value={searchValue}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Box ml={2} mt={1}>
                <FormControlLabel
                  label='Clientes con deuda'
                  control={
                    <Checkbox
                      checked={customersWithDebt}
                      onChange={() => setCustomersWithDebt(!customersWithDebt)}
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <br />
      <TableContainer component={Card}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='center'>
                Cliente
              </TableCell>
              <TableCell align='center'>
                Total de pedidos
              </TableCell>
              <TableCell align='center'>
                Ticket promedio
              </TableCell>
              <TableCell align='center'>
                Deuda
              </TableCell>
              <TableCell align="right">
                <TablePagination
                  component="div"
                  count={count}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={count <= 0 ? 0 : page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage="Filas por página"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customersToShow.length > 0 ? customersToShow.map((customer) => (<TableRow
              hover
              key={customer.id}
            >
              <TableCell>
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Avatar
                    className={classes.avatar}
                  >
                    {getInitials(`${customer.name} ${customer.lastname}`)}
                  </Avatar>
                </Box>
              </TableCell>
              <TableCell align='center'>
                {customer.name}{' '}{customer.lastname}
              </TableCell>
              <TableCell align='center'>
                {customer.ordersCount}
              </TableCell>
              <TableCell align='center'>
                {'$ '}{customer.averageOrdersAmount}
              </TableCell>
              <TableCell align='center'>
                {'$ '}{customer.averageDebt}
              </TableCell>
              <TableCell />
            </TableRow>))
              : <TableRow>
                <TableCell colSpan="6" align='center'>
                  Sin clientes para mostrar
                </TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  customersToShow: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
  clients: PropTypes.array.isRequired,
  selectedClient: PropTypes.object.isRequired,
  setSelectedClient: PropTypes.func.isRequired,
  customersWithDebt: PropTypes.bool.isRequired,
  setCustomersWithDebt: PropTypes.func.isRequired,
};

Results.defaultProps = {
  customersToShow: [],
};

export default Results;
