export const RESET_PRICELIST_CHANGE = '@priceList/reset-priceList-change';
export const ADD_PRICELIST_ITEMS = '@priceList/add-priceList-items';

export function resetPriceListChange() {
  return {
    type: RESET_PRICELIST_CHANGE,
  };
}

export function addPriceListItems(payload) {
  return {
    type: ADD_PRICELIST_ITEMS,
    payload,
  };
}
