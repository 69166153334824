import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Container } from '@material-ui/core';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import clientRequest from '../../../requests/api/client';
import { getModuleByClient } from '../../../requests/api/module';
import handleApiResponse from '../../../utils/handleApiResponse';
import SplashScreen from '../../../components/Loading/SplashScreen';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import useStyles from './styles';
import EditModuleForm from './EditModuleForm';

function EditModuleView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [clientModule, setClientModule] = useState(null);
  const [clients, setClients] = useState([]);
  const requestUser = useSelector((s) => s.account.user);
  const [selectedClient, setSelectedClient] = useState({
    id: requestUser.role === SUPER_ADMIN ? null : requestUser.clientId,
    name: null,
  });
  const page = 0;
  const limit = '';
  const searchValue = '';

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage(page, limit, searchValue);
        if (response.success) {
          setClients(response.clients);
          if (!selectedClient.id) {
            setSelectedClient({
              id: response.clients[0].id,
              name: response.clients[0].name,
            });
          }
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getModule = async () => {
      try {
        const response = await getModuleByClient(selectedClient.id);
        if (response.success) {
          setClientModule(response.searchedModule);
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (requestUser.role === SUPER_ADMIN) getClients();
    if (selectedClient.id) getModule();
  }, [selectedClient]);

  if (!selectedClient.id || !clientModule) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Módulos"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <EditModuleForm
          clientModule={clientModule}
          clients={clients}
          requestUser={requestUser}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          />
          </Box>
      </Container>
    </Page>
  );
}

export default EditModuleView;
