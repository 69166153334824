import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
} from 'react-feather';
import useStyles from '../styles';
import { putProductsPricesFromCSV, putPriceList } from '../../../../requests/api/priceLists';
import handleApiResponse from '../../../../utils/handleApiResponse';
import CustomTextField from '../../../../components/Inputs/TextField/CustomTextField';
import { resetPriceListChange } from '../../../../actions/priceListActions';

function Results({
  className,
  priceListId,
  selectedPriceList,
  productPrices,
  clientId,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // this is so we can get the prodictId from the priceList so we can delete it.
  const [selectedProductId, setSelectedProductId] = useState([]);
  const [selectedPriceProduct, setSelectedPriceProduct] = useState([]);
  const [currentPriceProduct, setCurrentPriceProduct] = useState(productPrices);
  const [prices, setPrices] = useState({});
  const [productsChanges, setProductsChanges] = useState(null);
  const { productsToAddPricelist } = useSelector((state) => state.priceList);

  useEffect(() => { setCurrentPriceProduct(productPrices); }, [productPrices]);

  const handleSelectAllPriceLists = (e) => {
    setSelectedPriceProduct(e.target.checked
      ? currentPriceProduct.map((priceList) => priceList.id)
      : []);
  };

  const handleSelectOnePriceList = (e, priceProductId, productId) => {
    if (!selectedPriceProduct.includes(priceProductId)) {
      setSelectedPriceProduct((prevSelected) => [...prevSelected, priceProductId]);
      // save the productId from priceList.
      setSelectedProductId((prevVal) => [...prevVal, productId]);
    } else {
      setSelectedPriceProduct((prevSelected) => prevSelected.filter((id) => id !== priceProductId));
      // save productId from priceList
      setSelectedProductId((prevVal) => prevVal.filter((id) => id !== productId));
    }
  };

  const enableBulkOperations = selectedPriceProduct.length > 0;
  const selectedSomePriceLists = selectedPriceProduct.length > 0
    && selectedPriceProduct.length < currentPriceProduct.length;
  const selectedAllPriceLists = selectedPriceProduct.length === currentPriceProduct.length;

  const handleChange = (e, id) => {
    if (Number(e.target.value) && e.target.value > 0) {
      setPrices((prevState) => ({
        ...prevState,
        [id]: e.target.value,
      }));
    }
  };

  const handleBlur = async (e, singleProduct) => {
    const newPrice = e.target.value;
    const productPriceToAdd = [{
      productId: singleProduct.productId,
      priceListId: singleProduct.priceListId,
      price: newPrice,
      deleted: false,
    }];

    try {
      const response = await putPriceList({
        id: singleProduct.priceListId, productPriceToAdd,
      }, clientId);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
      setPrices((prevState) => ({
        ...prevState,
        [singleProduct.id]: singleProduct.price,
      }));
    }
  };

  const handleAddUpdateProducts = async () => {
    const newUpdateProducts = productsChanges
      .filter((item) => (item.update || item.new))
      .map((item) => ({ price: item.price, id: item.id }));
    try {
      const res = await putProductsPricesFromCSV({ priceListId, newUpdateProducts });
      handleApiResponse(enqueueSnackbar, res, true);
      dispatch(resetPriceListChange());
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    if (productPrices) {
      const auxPrices = {};
      productPrices.forEach((item) => {
        auxPrices[item.id] = item.price;
      });
      setPrices((prevState) => ({ ...prevState, ...auxPrices }));
    }
  }, [productPrices]);

  useEffect(() => {
    // Check differences
    if (productsToAddPricelist) {
      let itemsInTable = {};
      productPrices.forEach((item) => {
        itemsInTable = {
          ...itemsInTable,
          [item.sku]: {
            id: item.productId,
            price: item.price,
          },
        };
      });
      const auxProductsToAdd = productsToAddPricelist.map((item) => {
        if (Object.keys(itemsInTable).includes(item.sku)) {
          return ({
            id: itemsInTable[item.sku].id,
            price: item.price ? item.price : itemsInTable[item.sku].price,
            name: item.name,
            sku: item.sku,
            new: false,
            update: !!(item.price && Number(item.price) !== itemsInTable[item.sku].price),
          });
        }
        return ({
          id: item.id,
          price: item.price,
          name: item.name,
          sku: item.sku,
          new: true,
          update: false,
        });
      });

      setProductsChanges(auxProductsToAdd);
    } else {
      setProductsChanges(null);
    }
  }, [productsToAddPricelist, productPrices]);

  const deleteSelectedProducts = async () => {
    try {
      const response = await putPriceList({
        name: selectedPriceList.name,
        id: parseInt(priceListId, 10),
        productPriceIdsToRemove: selectedProductId,
      }, clientId);
      setCurrentPriceProduct(currentPriceProduct
        .filter((e) => (!selectedPriceProduct.includes(e.id))));
      setSelectedPriceProduct([]);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Divider />
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllPriceLists}
              indeterminate={selectedSomePriceLists}
              onChange={handleSelectAllPriceLists}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteSelectedProducts()}
            >
              Eliminar
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  SKU
                </TableCell>
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell>
                  Precio
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsChanges
                ? productsChanges.map((priceProduct) => {
                  const isPriceProductSelected = selectedPriceProduct.includes(priceProduct.id);
                  let relevantClass = null;
                  if (priceProduct.new) relevantClass = 'addProduct';
                  else if (priceProduct.update) relevantClass = 'updateProduct';
                  return (<TableRow
                    hover
                    key={priceProduct.id}
                    selected={isPriceProductSelected}
                    className={classes[relevantClass]}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isPriceProductSelected}
                        // get the productId from priceList and save it.
                        onChange={(e) => {
                          handleSelectOnePriceList(e, priceProduct.id, priceProduct.productId);
                        }}
                        value={isPriceProductSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {priceProduct.sku}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {priceProduct.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {priceProduct.price || 1}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>);
                })
                : (currentPriceProduct && currentPriceProduct.map((priceProduct) => {
                  const isPriceProductSelected = selectedPriceProduct.includes(priceProduct.id);
                  return (
                    <TableRow
                      hover
                      key={priceProduct.id}
                      selected={isPriceProductSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isPriceProductSelected}
                          // get productId from priceList and save it.
                          onChange={(e) => {
                            handleSelectOnePriceList(e, priceProduct.id, priceProduct.productId);
                          }}
                          value={isPriceProductSelected}
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                        >
                          <Typography
                            color="inherit"
                            variant="h6"
                          >
                            {priceProduct.sku}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                        >
                          <Typography
                            color="inherit"
                            variant="h6"
                          >
                            {priceProduct.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                        >
                          <CustomTextField
                            value={prices[priceProduct.id] ? prices[priceProduct.id] : 1}
                            onChange={(e) => handleChange(e, priceProduct.id)}
                            onBlur={(e) => handleBlur(e, priceProduct)}
                            variant='standard'
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }))}
            </TableBody>
          </Table>
          {productsChanges
            && (
              <Box
                display='flex'
                justifyContent='flex-end'
                mt={3}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddUpdateProducts}
                >
                  Guardar desde CSV
                </Button>
              </Box>
            )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  productPrices: PropTypes.array,
};

Results.defaultProps = {
  productPrices: [],
};

export default Results;
