import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import handleApiResponse from '../../../utils/handleApiResponse';
import storeRequest from '../../../requests/api/store';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import getInitials from '../../../utils/getInitials';
import useStyles from './styles';
import { saveFilters } from '../../../actions/filterActions';

function Results({
  stores,
  count,
  searchValue,
  setSearchValue,
  page,
  setPage,
  limit,
  setLimit,
  role,
  clients,
  selectedClient,
  setSelectedClient,
  history,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStores, setSelectedStores] = useState([]);
  const [currentStores, setCurrentStores] = useState(stores);
  const [filters, setFilters] = useState({
    page,
    limit,
    searchValue,
    selectedClient,
  });

  useEffect(() => {
    setCurrentStores(stores);
  }, [stores]);

  const handleSelectAllStores = (e) => {
    setSelectedStores(e.target.checked
      ? currentStores.map((store) => store.id)
      : []);
  };

  const handleSelectOneStore = (e, storeId) => {
    if (!selectedStores.includes(storeId)) {
      setSelectedStores((prevSelected) => [...prevSelected, storeId]);
    } else {
      setSelectedStores((prevSelected) => prevSelected.filter((id) => id !== storeId));
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    setFilters((prevVal) => ({ ...prevVal, page: newPage }));
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, limit: e.target.value }));
  };
  const handleStoreChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, searchValue: e.target.value }));
  };

  const enableBulkOperations = selectedStores.length > 0;
  const selectedSomeStores = selectedStores.length > 0
  && selectedStores.length < currentStores.length;
  const selectedAllStores = selectedStores.length === currentStores.length;

  const deleteSelectedStores = async () => {
    try {
      const response = await storeRequest.deleteStores({ storesIds: selectedStores });
      handleApiResponse(enqueueSnackbar, response, true);
      setCurrentStores(currentStores.filter((e) => (!selectedStores.includes(e.id))));
      setSelectedStores([]);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleGoToEdit = (storeId) => {
    dispatch(saveFilters(filters));
    history.push(`/administracion/tiendas/${storeId}/editar`);
  };

  return (
    <div>
      <Card
        sx={{ marginBottom: 6 }}
        >
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <Grid container spacing={1}>
          {role === SUPER_ADMIN
        && <Grid item md={3} xs={12}>
          <CustomAutocomplete
        disablePortal
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText='Sin clientes para mostrar'
        id="combo-box-demo"
        placeholder="Buscar cliente"
        options={clients}
        value={selectedClient}
        getOptionLabel={(option) => `${option.name}`}
        onChange={(e, value) => {
          if (value === null) return;
          setSelectedClient({
            id: value.id,
            name: value.name,
          });
        }}
        renderInput={(params) => (
          <CustomTextField
          {...params}
          label="Buscar cliente"
          variant="outlined"
          />
        )}
         />
          </Grid>
      }
          <Grid item md={3} xs={12}>
          <CustomTextField
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon
                fontSize="small"
                color="action"
              >
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        onChange={handleStoreChange}
        placeholder="Buscar tienda"
        value={searchValue}
        variant="outlined"
      />
          </Grid>
        </Grid>
      </Box>
      </Card>
      <br/>
      <TableContainer component={Card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllStores}
                    indeterminate={selectedSomeStores}
                    onChange={handleSelectAllStores}
                  />
                </TableCell>
                <TableCell>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      Tienda
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                  {enableBulkOperations && (
                    <div className={classes.bulkActions}>
                      <Button
                       variant="outlined"
                       className={classes.bulkAction}
                       onClick={() => deleteSelectedStores()}
                       >
                        Eliminar
                      </Button>
                    </div>
                  )}
                  </Grid>
                </TableCell>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell align="right">
                <TablePagination
                  component="div"
                  count={count}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={count <= 0 ? 0 : page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage="Filas por página"
                />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentStores ? currentStores.map((store) => {
                const isStoreSelected = selectedStores.includes(store.id);
                return (
                  <TableRow
                    hover
                    key={store.id}
                    selected={isStoreSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isStoreSelected}
                        onChange={(e) => handleSelectOneStore(e, store.id)}
                        value={isStoreSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Avatar
                          className={classes.avatar}
                        >
                          {getInitials(store.name.toString())}
                        </Avatar>
                        <div>
                          <Typography
                            color="inherit"
                            variant="h6"
                          >
                            {store.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                          >
                            {store.address}{', '}{store.city}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                          >
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {store.clientId}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={Button}
                        onClick={() => handleGoToEdit(store.id)}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
                : <TableRow>
                  <TableCell align="center" colSpan="4">
                    No hay tiendas para mostrar
                  </TableCell>
                  </TableRow>}
            </TableBody>
          </Table>
      </TableContainer>
  </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  stores: PropTypes.array,
};

Results.defaultProps = {
  stores: [],
};

export default Results;
