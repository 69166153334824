import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.background.tableHeader,
      paddingTop: theme.spacing(3),
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  header: {
    backgroundColor: theme.palette.background.dark,
  },
  listModal: {
    height: '400px',
    width: '100%',
    overflow: 'auto',
  },
  listContainer: {
    width: '100%',
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0, 136, 205, 0.2)',
    },
  },
  listItemHighlight: {
    backgroundColor: '#0088CD',
    color: 'white',
  },
  uploadProjectModalCard: {
    width: '85%',
    maxWidth: '775px',
    height: '65%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  uploadProjectModalCardContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  inputLabelModal: {
    margin: '0px 0px 10px 0px',
  },
  buttonModal: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  updateProduct: {
    backgroundColor: '#C5C200',
    '&:hover': {
      backgroundColor: '#C5C200',
    },
  },
}));

export default useStyles;
