import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  // Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  X as ClearIcon,
  Search as SearchIcon,
  FileText as DetailIcon,
} from 'react-feather';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useDispatch } from 'react-redux';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import useStyles from './styles';
import SplashScreen from '../../../components/Loading/SplashScreen';
import thousandSeparator from '../../../utils/thousandSeparator';
import { saveFilters } from '../../../actions/filterActions';

function Results({
  orders,
  count,
  orderSearchValue,
  setOrderSearchValue,
  customerSearchValue,
  setCustomerSearchValue,
  page,
  setPage,
  limit,
  setLimit,
  role,
  clients,
  selectedClient,
  setSelectedClient,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  history,
}) {
  const classes = useStyles();
  // const [selectedOrders, setSelectedOrders] = useState([]);
  const dispatch = useDispatch();
  const [currentOrders, setCurrentOrders] = useState(orders);
  const [filters, setFilters] = useState({
    page,
    limit,
    orderSearchValue,
    customerSearchValue,
    selectedClient,
    startDate,
    endDate,
  });
  useEffect(() => {
    setCurrentOrders(orders);
  }, [orders]);

  // const handleSelectAllOrders = (e) => {
  //   setSelectedOrders(e.target.checked
  //     ? currentOrders.map((order) => order.id)
  //     : []);
  // };

  // const handleSelectOneOrder = (e, orderId) => {
  //   if (!selectedOrders.includes(orderId)) {
  //     setSelectedOrders((prevSelected) => [...prevSelected, orderId]);
  //   } else {
  //     setSelectedOrders((prevSelected) => prevSelected.filter((id) => id !== orderId));
  //   }
  // };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    setFilters((prevVal) => ({ ...prevVal, page: newPage }));
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, limit: e.target.value }));
  };

  // const enableBulkOperations = selectedOrders.length > 0;
  // const selectedSomeOrders = selectedOrders.length > 0
  // && selectedOrders.length < currentOrders.length;
  // const selectedAllOrders = selectedOrders.length === currentOrders.length;
  const handleOrderSearchValueChange = (e) => {
    e.preventDefault();
    setOrderSearchValue(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, orderSearchValue: e.target.value }));
  };
  const handleCustomerSearchValueChange = (e) => {
    e.preventDefault();
    setCustomerSearchValue(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, customerSearchValue: e.target.value }));
  };
  const handleGoToDetail = (orderId) => {
    dispatch(saveFilters(filters));
    history.push(`/pedidos/${orderId}/detalle`);
  };

  if (!selectedClient.id) return <SplashScreen />;
  return (
  <div>
    <Card>
    <Box
      p={2}
      minHeight={56}
      display="flex"
      alignItems="center"
    >
     <Grid container spacing={1}>
      {role === SUPER_ADMIN
      && <Grid item md={2} xs={12}>
        <CustomAutocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disablePortal
      noOptionsText='Sin clientes para mostrar'
      id="combo-box-demo"
      placeholder="Buscar cliente"
      options={clients}
      value={selectedClient}
      getOptionLabel={(option) => `${option.name}`}
      onChange={(e, value) => {
        if (value === null) {
          setSelectedClient({
            id: clients[0].id,
            name: clients[0].name,
          });
          return;
        }
        setSelectedClient({
          id: value.id,
          name: value.name,
        });
        setFilters((prevVal) => ({
          ...prevVal,
          selectedClient: {
            id: value.id,
            name: value.name,
          },
        }));
      }}
      renderInput={(params) => (
        <CustomTextField
        {...params}
        label="Buscar cliente"
        variant="outlined"
        />
      )}
       />
       </Grid>
    }
    <Grid item md={2} xs={12}>
    <CustomTextField
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon
                fontSize="small"
                color="action"
              >
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        onChange={handleOrderSearchValueChange}
        placeholder="Buscar n° pedido"
        value={orderSearchValue}
        variant="outlined"
      />
    </Grid>
      <Grid item md={2} xs={12}>
      <CustomTextField
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon
                fontSize="small"
                color="action"
              >
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        onChange={handleCustomerSearchValueChange}
        placeholder="Buscar comprador"
        value={customerSearchValue}
        variant="outlined"
      />
      </Grid>
      <Grid item md={2} xs={12}>
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
          >
          <DatePicker
            views={['day', 'month', 'year']}
            label="Fecha de inicio"
            value={startDate}
            onChange={(value) => {
              if (value === null) {
                setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
                return;
              }
              setStartDate(value);
              setFilters((prevVal) => ({ ...prevVal, startDate: value }));
            }}
            renderInput={(params) => (
            <CustomTextField
            {...params}
            variant='outlined'
            fullWidth
            />
            )}
          />
          </LocalizationProvider>
        </Grid>
        <Grid item md={2} xs={12}>
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
          >
          <DatePicker
            views={['day', 'month', 'year']}
            label="Fecha límite"
            value={endDate}
            onChange={(value) => {
              if (value === null) {
                setEndDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
                return;
              }
              setEndDate(value);
              setFilters((prevVal) => ({ ...prevVal, endDate: value }));
            }}
            renderInput={(params) => (
            <CustomTextField
            {...params}
            variant='outlined'
            fullWidth
            />
            )}
          />
          </LocalizationProvider>
        </Grid>
          <Tooltip title='Limpiar fechas'>
            <IconButton
              component={Button}
              onClick={() => {
                setEndDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
                setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
              }}
            >
              <SvgIcon fontSize="small">
                <ClearIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
      </Grid>
    </Box>
    </Card>
    <br/>
    <TableContainer component={Card}>
      <Table>
        <TableHead>
          <TableRow>
             <TableCell>
              <Grid container spacing={0}>
                <Grid item xs={8}>
                N° de pedido
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              Total a abonar
            </TableCell>
            <TableCell>
              Comprador
            </TableCell>
            <TableCell>
              Fecha de orden
            </TableCell>
            <TableCell align="right">
            <TablePagination
              component="div"
              count={count}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={count <= 0 ? 0 : page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Filas por página"
            />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentOrders ? currentOrders.map((order) => (
              <TableRow
                hover
                key={order.id}
              >
                <TableCell>
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    <Avatar
                      className={classes.avatar}
                    >
                    </Avatar>
                    <Typography
                      color="inherit"
                      variant="h6"
                    >
                      {order.orderNumber}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  {thousandSeparator(order.totalAmount, '.')}
                </TableCell>
                <TableCell>
                  {order.customer ? `${order.customer.name} ${order.customer.lastname}` : 'S/I'}
                </TableCell>
                <TableCell>
                  {order.createdAt.substring(0, 10)}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title='Ver detalle'>
                    <IconButton
                      component={Button}
                      onClick={() => handleGoToDetail(order.id)}
                    >
                      <SvgIcon fontSize="small">
                        <DetailIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
          ))
            : <TableRow>
              <TableCell align="center" colSpan="5">
              No hay pedidos para mostrar
              </TableCell>
              </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  orderSearchValue: PropTypes.string.isRequired,
  setOrderSearchValue: PropTypes.func.isRequired,
  customerSearchValue: PropTypes.string.isRequired,
  setCustomerSearchValue: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
};

Results.defaultProps = {
  orders: [],
};

export default Results;
