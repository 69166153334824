import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import SplashScreen from '../../../components/Loading/SplashScreen';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import useStyles from './styles';
import { getPriceListById } from '../../../requests/api/priceLists';
import PriceListEditForm from './PriceListEditForm';
import ProductPriceForm from './ProductPriceForm';
import { getProductsPerPage } from '../../../requests/api/products';
import handleApiResponse from '../../../utils/handleApiResponse';
import { resetPriceListChange } from '../../../actions/priceListActions';

function PriceListEditView() {
  const classes = useStyles();
  const { priceListId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((s) => s.account.user);
  const [selectedPriceList, setSelectedPriceList] = useState();
  const [productsPricesMod, setProductsPricesMod] = useState([]);
  const [productsListing, setProductsListing] = useState([]);
  const [clientId, setClientId] = useState(null);
  const { productsToAddPricelist } = useSelector((state) => state.priceList);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const getPriceListDetails = async () => {
      try {
        const response = await getPriceListById(priceListId);
        setSelectedPriceList(response.searchedPriceList);
        setClientId(response.searchedPriceList.clientId);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    const getAllProducts = async () => {
      try {
        const response = await getProductsPerPage('', '', '', clientId);
        setProductsListing(response.products);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    dispatch(resetPriceListChange());
    getPriceListDetails();
    if (clientId) getAllProducts();
  }, [clientId]);

  useEffect(() => {
    const getPriceListDetails = async () => {
      try {
        const response = await getPriceListById(priceListId);
        setSelectedPriceList(response.searchedPriceList);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    getPriceListDetails();
  }, [productsToAddPricelist]);

  useEffect(() => {
    if (selectedPriceList) {
      const auxProduct = selectedPriceList.productPrices.map((item) => {
        const { product, ...rest } = item;
        return {
          ...rest,
          name: product.name,
          sku: product.sku,
        };
      });

      setProductsPricesMod(auxProduct);
    }
  }, [selectedPriceList]);

  if (!selectedPriceList || !clientId) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Lista de precios | Editar lista de precios"
    >
      <Container maxWidth={false}>
        <Header
          priceListName={selectedPriceList && selectedPriceList.name}
          products={productsPricesMod}
          clientId={user.role === SUPER_ADMIN ? selectedPriceList.clientId : user.clientId}
        />
        <Box mt={3}>
          <PriceListEditForm
          products={productsListing}
          priceList={selectedPriceList}
          setProductsPricesMod={setProductsPricesMod}
          />
          <ProductPriceForm
            priceListId={priceListId}
            productPrices={productsPricesMod}
            selectedPriceList={selectedPriceList}
            clientId={user.role === SUPER_ADMIN ? selectedPriceList.clientId : user.clientId}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default PriceListEditView;
