import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import thousandSeparator from '../../../utils/thousandSeparator';

function PaymentsTable({
  order,
  page,
  setPage,
  limit,
  setLimit,
}) {
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  return (
<Card>
  <CardContent>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant='h4'>
            Estado del pago:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
            Tipo de pago:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
            Subtotal:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h4'>
            Error:
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {order.payments.length && order.payments.map((p, index) => (
          <TableRow key={index}>
            <TableCell>
              <Typography
              variant='h5'
              >
                {p.status.toUpperCase()}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
              variant='h5'
              >
                {p.type.toUpperCase()}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
              variant='h5'
              >
                $ {thousandSeparator(p.amount, '.')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
              variant='h5'
              >
                {}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <TablePagination
        component="div"
        count={order.orderProducts && order.orderProducts.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
  </CardContent>
      </Card>
  );
}

PaymentsTable.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  order: PropTypes.object,
};

PaymentsTable.defaultProps = {
  order: {},
};

export default PaymentsTable;
