import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import { getReturnsPerPage } from '../../../requests/api/returns';
import clientRequest from '../../../requests/api/client';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';
import SplashScreen from '../../../components/Loading/SplashScreen';

function ReturnListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const historyPreviousPath = history.location.state && (history.location.state.prevPath === '/devoluciones/detalle' || history.location.state.prevPath === '/pedido/detalle');
  const reducerFilters = useSelector((s) => s.filter.filter);
  const [returns, setReturns] = useState([]);
  const [page, setPage] = useState(historyPreviousPath ? reducerFilters.page : 0);
  const [limit, setLimit] = useState(historyPreviousPath ? reducerFilters.limit : 10);
  const [searchValue, setSearchValue] = useState(historyPreviousPath ? {
    billing: reducerFilters.searchValue.billing,
    order: reducerFilters.searchValue.order,
  } : {
    billing: '',
    order: '',
  });
  const [clients, setClients] = useState([]);
  const account = useSelector((s) => s.account);
  const [startDate, setStartDate] = useState(historyPreviousPath ? reducerFilters.startDate
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(historyPreviousPath ? reducerFilters.endDate
    : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
  const [selectedClient, setSelectedClient] = useState(historyPreviousPath ? {
    id: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.id
      : account.user.clientId,
    name: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.name
      : null,
  } : {
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });
  const [count, setCount] = useState(null);

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage('', '', '');
        setClients(response.clients);
        // given that I get clients only if I am superAdmin,
        // check if there is no client selected before setting a default clientId
        // with the response's first client
        if (response.clients.length !== 0
          && selectedClient.id === null) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getReturns = async () => {
      try {
        const response = await getReturnsPerPage(
          page,
          limit,
          searchValue.billing,
          searchValue.order,
          selectedClient.id,
          startDate,
          endDate,
        );
        setReturns(response.returns);
        setCount(response.totalReturns);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (account.user.role === SUPER_ADMIN) getClients();
    if (selectedClient.id) getReturns();
  }, [selectedClient, limit, page, searchValue, startDate, endDate]);

  if (!selectedClient.id) return <SplashScreen />;
  return (
    <Page
      className={classes.root}
      title="Devoluciones"
    >
      <Container maxWidth={false}>
        <Header />
          <Box mt={3}>
            <Results
              returns={returns}
              page={page}
              count={count || 0}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              role={account.user.role}
              clients={clients}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              history={history}
            />
          </Box>
      </Container>
    </Page>
  );
}

export default ReturnListView;
