/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  RESET_STORE_CREATION,
  SET_STORE_DETAILS,
  SET_STORE_CREATION_PAGE,
  SET_STORE_ALL_USERS_CHECK,
} from '../actions/storeActions';

const initialState = {
  storeInformation: {},
  storePage: 0,
  storeAllUsersCheck: false,
};

// eslint-disable-next-line default-param-last
const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STORE_CREATION: {
      return produce(state, (draft) => {
        draft.storeInformation = {};
        draft.storePage = 0;
        draft.storeAllUsersCheck = false;
      });
    }

    case SET_STORE_DETAILS: {
      return produce(state, (draft) => {
        const auxStoreInfo = { ...state.storeInformation };
        draft.storeInformation = { ...auxStoreInfo, ...action.payload };
      });
    }

    case SET_STORE_CREATION_PAGE: {
      return produce(state, (draft) => {
        draft.storePage = action.payload;
      });
    }

    case SET_STORE_ALL_USERS_CHECK: {
      return produce(state, (draft) => {
        draft.storeAllUsersCheck = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default storeReducer;
