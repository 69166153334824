/* eslint-disable no-underscore-dangle */
import axios from '../../utils/axios';

const getClientsPerPage = async (page, limit, searchValue) => {
  const response = await axios.get(`/api/clients?page=${page}&limit=${limit}&searchValue=${searchValue}`);
  return response.data;
};

const putClientById = async (
  {
    id,
    name,
    address,
    activity,
    commune,
    city,
    country,
    identificationNumber,
    phone,
    email,
    officeSII,
    certRut,
    certToken,
    actecos,
  },
) => {
  const response = await axios.put('/api/client/edit', {
    id,
    name,
    address,
    activity,
    commune,
    city,
    country,
    identificationNumber,
    phone,
    email,
    officeSII,
    certRut,
    certToken,
    actecos,
  });

  return response.data;
};

const getClientById = async (id) => {
  const response = await axios.get(`/api/client/${id}`);
  return response.data;
};

const postCreateClient = async (
  {
    name,
    address,
    activity,
    commune,
    city,
    country,
    identificationNumber,
    phone,
    email,
    officeSII,
    certRut,
    certToken,
    actecos,
  },
) => {
  const response = await axios.post('/api/client/create', {
    name,
    address,
    activity,
    commune,
    city,
    country,
    identificationNumber,
    phone,
    email,
    officeSII,
    certRut,
    certToken,
    actecos,
  });
  return response.data;
};

const getActecos = async () => {
  const response = await axios.get('/api/clients/actecos');
  return response.data;
};

const postFiles = async (id, file) => {
  const response = await axios.post(
    `/api/client/${id}/files`,
    file,
    {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${file._boundary}`,
      },
    },
  );
  return response.data;
};

export default {
  getClientsPerPage,
  putClientById,
  getClientById,
  postCreateClient,
  getActecos,
  postFiles,
};
