/* eslint-env browser */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormData from 'form-data';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import {
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
} from 'react-feather';

import useStyles from './styles';
import storeRequest from '../../../requests/api/store';
import { postCheckProductsCSV, postExportCSVProducts } from '../../../requests/api/products';
import { addProductsItems, resetProductsChange } from '../../../actions/productsActions';
import handleApiResponse from '../../../utils/handleApiResponse';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import CancelButton from '../../../components/Buttons/Cancel/CancelButton';

function Header({
  clientId, clientName, products, className, ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);
  const [storeCount, setStoreCount] = useState(0);
  const [errorValues, setErrorValues] = useState({ modal: false });
  const account = useSelector((s) => s.account);

  const DUPLICATES = 'duplicates';
  const BLANKS = 'blanks';

  const handleUploadFile = async () => {
    try {
      const fileToCheck = new FormData();
      fileToCheck.append('file', selectedFile);
      fileToCheck.append('clientId', clientId);
      fileToCheck.append('storeCheck', Number(storeCount) === 1);
      const response = await postCheckProductsCSV(fileToCheck);

      const productsToChange = response.filter((item) => item.update || item.new);

      if (productsToChange && productsToChange.length === 0) {
        enqueueSnackbar('No hay productos nuevos y/o campos por modificar', {
          variant: 'warning',
        });
        setSelectedFile('');
        dispatch(resetProductsChange());
        return;
      }

      dispatch(addProductsItems(productsToChange));
      setSelectedFile('');
    } catch (err) {
      if (err.response.data.data.duplicates) {
        setErrorValues((prevState) => ({
          ...prevState,
          modal: true,
          source: DUPLICATES,
          data: err.response.data.data.duplicates,
          arraySize: err.response.data.data.duplicates.length,
        }));
      } else if (err.response.data.data.whiteCells) {
        setErrorValues((prevState) => ({
          ...prevState,
          modal: true,
          source: BLANKS,
          data: err.response.data.data.whiteCells,
          arraySize: err.response.data.data.duplicates.length,
        }));
      } else if (err.response.data.errorMessage) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
      setSelectedFile('');
    }
  };

  const handleDownloadCSV = async () => {
    try {
      const response = await postExportCSVProducts({ products, clientName });
      const universalBOM = '\uFEFF';
      const uri = `data:'text/csv';charset=utf-8,${encodeURI(universalBOM + response.file)}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = response.fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    const getNumberStores = async () => {
      try {
        const response = await storeRequest.getStoresPerPage(
          0, // page
          1, // limit
          '', // searchedValue
          account.user.role === SUPER_ADMIN ? null : account.user.clientId, // clientId
        );
        setStoreCount(response.totalStores);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getNumberStores();
  }, []);

  return (
    <Grid
      className={clsx(className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textSecondary"
        >
          Productos
        </Typography>
      </Grid>
      <Grid item>
        {!selectedFile && clientId && (
          <>
            <Button
              startIcon={<UploadIcon />}
              component="label"
            >
              Importar CSV
              <input
                type="file"
                accept=".csv"
                hidden
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
            </Button>
          </>
        )}
        {selectedFile && clientId && (
          <Button
            startIcon={<UploadIcon />}
            type='Submit'
            onClick={handleUploadFile}
          >
            Subir Archivo
          </Button>
        )}
        {clientId && (<Button
          startIcon={<DownloadIcon />}
          component="label"
          onClick={handleDownloadCSV}
        >
          Exportar CSV
        </Button>)}
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          className={classes.action}
          href="/productos/crear"
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <PlusCircleIcon color='white' />
          </SvgIcon>
          Agregar Producto
        </Button>
      </Grid>
      <Modal
        open={errorValues.modal}
        onClose={() => setErrorValues({ ...errorValues, modal: false })}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          margin='0px 20px'
          height='100vh'
        >
          <Card>
            <CardContent>
              {errorValues.modal && <Box>
                <Typography
                  color="textPrimary"
                  variant='h4'
                >
                  {errorValues.source === DUPLICATES && 'Hemos encontrado valores duplicados en el excel en campos que son únicos. Favor revisar los siguientes valores:'}
                  {errorValues.source === BLANKS && 'Hemos encontrado valores en blanco en el excel en campos que necesitan información. Favor revisar los siguientes valores:'}
                </Typography>
                <Box>
                  <List
                    className={classes.listModal}
                  >
                    {errorValues.data && errorValues.data.map((value) => (
                      <ListItem button key={value.line}>
                        <ListItemText primary={`Linea: ${value.line}`} />
                        {
                          errorValues.source === DUPLICATES
                          && <ListItemText primary={`SKU: ${value.info[1]}`} />
                        }
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>}
              <Box
                display='flex'
                justifyContent='end'
                padding='10px 24px'
              >
                <CancelButton
                  onClick={() => setErrorValues({ modal: false })}
                  variant='contained'
                >
                  Cerrar Modal
                </CancelButton>
              </Box>

            </CardContent>
          </Card>

        </Box>
      </Modal>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
