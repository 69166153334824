import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import {
  STOCK, LOSSES, ERROR, ENTRY, EGRESS,
} from '../../../utils/const/reasonStockChange';
import useStyles from './styles';
import { updateStock } from '../../../requests/api/productStock';
import handleApiResponse from '../../../utils/handleApiResponse';

function UpdateStockForm({ className, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const stock = useSelector((s) => s.stock.stock);
  const reasonStockChange = [
    {
      name: 'Ingreso de producto',
      id: STOCK,
    },
    {
      name: 'Merma o pérdida',
      id: LOSSES,
    },
    {
      name: 'Ajuste',
      id: ERROR,
    },
  ];

  if (submitSuccess) {
    return <Redirect to="/inventario" />;
  }

  return (
        <Formik
          initialValues={{
            storeId: stock.storeId,
            productId: stock.productId,
            quantity: '',
            orderId: null,
            billNumber: '',
            reason: '',
            unitsIn: '',
            unitsOut: '',
            error: '',
            cost: stock.product.cost,
          }}
          validationSchema={Yup.object().shape({
            quantity: Yup.number().typeError('Debe ingresar un número').min(0, 'La cantidad debe ser mayor a 0').required('Por favor, ingrese la cantidad'),
            reason: Yup.string().required('Por favor, seleccione la razón del ajuste'),
            unitsOut: Yup.number().typeError('Debe ingresar un número').max(stock.quantity, 'No hay esa cantidad de productos'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              let fixedQuantity;
              let fixedUnitsIn = 0;
              let fixedUnitsOut;
              if (values.reason === STOCK || values.error === ENTRY) {
                fixedQuantity = parseInt(values.quantity, 10);
                fixedUnitsIn = parseInt(values.quantity, 10);
                fixedUnitsOut = 0;
              }
              if (values.reason === LOSSES || values.error === EGRESS) {
                if (values.quantity > stock.quantity) {
                  setErrors('No hay esa cantidad de productos');
                  return;
                }
                fixedQuantity = parseInt(-values.quantity, 10);
                fixedUnitsOut = parseInt(values.quantity, 10);
              }
              const data = {
                storeId: stock.storeId,
                productId: stock.productId,
                quantity: fixedQuantity,
                orderId: values.orderId,
                billNumber: values.billNumber,
                reason: values.reason,
                unitsIn: fixedUnitsIn,
                unitsOut: fixedUnitsOut,
                cost: values.cost,
              };
              const response = await updateStock(data);
              handleApiResponse(enqueueSnackbar, response, true);
              setStatus({ success: true });
              setSubmitting(false);
              setSubmitSuccess(true);
            } catch (error) {
              handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/inventario">Volver a Stock</Button>);
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            isSubmitting,
            touched,
            setFieldValue,
            values,
          }) => (
            <form
              className={clsx(classes.emptyRoot, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    {values.reason === '' ? (
                      <Grid item xs={12}>
                      <Box>
                        <CustomAutocomplete
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={reasonStockChange}
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={(e, value) => {
                            if (value === null) {
                              setFieldValue('reason', '');
                              return;
                            }
                            setFieldValue('reason', value.id);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                            label="Seleccionar motivo de cambio en inventario"
                            variant="outlined"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    ) : (
                      <Grid item xs={6}>
                      <Box>
                        <CustomAutocomplete
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={reasonStockChange}
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={(e, value) => {
                            if (value === null) {
                              setFieldValue('reason', '');
                              return;
                            }
                            setFieldValue('reason', value.id);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                            label="Seleccionar motivo de cambio en inventario"
                            variant="outlined"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    )}
                    {values.reason === STOCK && (
                      <Grid item xs={12} md={6}>
                        <CustomTextField
                        style={{ width: '100%', marginRight: 10 }}
                        disabled
                        label='Código de barras'
                        value={stock.product.sku}
                        variant="outlined"
                        />
                      </Grid>
                    )}
                    {values.reason === STOCK && (
                      <Grid item xs={12}>
                        <Box>
                          <CustomTextField
                          type='number'
                          style={{ width: '49%', marginRight: 10 }}
                          label='Costo'
                          value={values.cost}
                          name='cost'
                          onChange={(e) => {
                            if (e.target.value === null) return;
                            setFieldValue('cost', e.target.value);
                          }}
                          variant="outlined"
                          />
                          <CustomTextField
                          error={Boolean(touched.quantity && errors.quantity)}
                          helperText={(touched.quantity && errors.quantity)}
                          style={{ width: '49%', marginLeft: 10 }}
                          label='Cantidad que ingresó'
                          name="quantity"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value === null) return;
                            setFieldValue('unitsIn', e.target.value);
                            setFieldValue('quantity', e.target.value);
                          }}
                          required
                          value={values.quantity}
                          variant="outlined"
                          />
                          <CustomTextField
                          style={{ width: '49%', marginTop: 10 }}
                          label='Número de factura'
                          value={values.billNumber}
                          variant="outlined"
                          onChange={(e) => {
                            if (e.target.value === null) return;
                            setFieldValue('billNumber', e.target.value);
                          }}
                          />
                        </Box>
                      </Grid>
                    )}
                    {values.reason === LOSSES && (
                      <Grid item xs={12} md={6}>
                        <Box>
                        <CustomTextField
                          error={Boolean((touched.quantity && errors.quantity) || errors.unitsOut)}
                          fullWidth
                          helperText={(touched.quantity && errors.quantity) || errors.unitsOut}
                          label='Cantidad perdida'
                          name="quantity"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value === null) return;
                            setFieldValue('unitsOut', e.target.value);
                            setFieldValue('quantity', e.target.value);
                          }}
                          required
                          value={values.quantity}
                          variant="outlined"
                        />
                        </Box>
                      </Grid>
                    )}
                    {values.reason === ERROR && (
                      <Grid item xs={12} md={6}>
                      <Box>
                        <CustomTextField
                        type='number'
                        error={Boolean((touched.quantity && errors.quantity) || errors.unitsOut)}
                        fullWidth
                        helperText={(touched.quantity && errors.quantity) || errors.unitsOut}
                        label="Cantidad para ajustar"
                        name="quantity"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (e.target.value === null) return;
                          if (values.error === ENTRY) {
                            setFieldValue('unitsIn', e.target.value);
                          } else setFieldValue('unitsOut', e.target.value);
                          setFieldValue('quantity', e.target.value);
                        }}
                        required
                        value={values.quantity}
                        variant="outlined"
                        />
                      </Box>
                    </Grid>
                    )}
                    {values.reason === ERROR && (
                    <Grid item xs={12}>
                      <Box>
                        <FormControl
                        ow='true'
                        >
                          <Box ml={1}>
                            <FormLabel id='errorType'>
                              <Typography
                               variant="h5"
                               color="textPrimary"
                              >
                                Tipo de ajuste
                              </Typography>
                            </FormLabel>
                            <RadioGroup
                            row
                            value={values.error}
                            onChange={(e, value) => {
                              setFieldValue('error', value);
                            }}
                            >
                              <FormControlLabel
                              value={ENTRY}
                              control={<Radio />}
                              label='Ajustar con ingreso de inventario'
                              />
                              <FormControlLabel
                              value={EGRESS}
                              control={<Radio />}
                              label='Ajustar con egreso de inventario'
                              />
                            </RadioGroup>
                          </Box>
                        </FormControl>
                      </Box>
                    </Grid>)}
                    <Grid item xs={12}>
                      <Box mt={2} display='flex' justifyContent='flex-end'>
                        {values.quantity !== ''
                          ? (
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Actualizar información
                        </Button>)
                          : (
                          <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled
                        >
                          Actualizar información
                        </Button>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
  );
}

UpdateStockForm.propTypes = {
  className: PropTypes.string,
};

export default UpdateStockForm;
