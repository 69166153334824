import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.background.tableHeader,
      paddingTop: theme.spacing(3),
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
  },
  tabSelectionPaper: {
    display: 'flex',
    marginTop: '24px',
    flex: 1,
  },
  tabSelectionItem: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0px',
  },
  tabSelectionItemSelected: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
  },
  tabSelectionItemDisabled: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0px',
    backgroundColor: '#BDBDBD',
    color: theme.palette.primary.main,
  },
  tablePagination: {
    display: 'flex',
    backgroundColor: '#f0f1f0',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '10px',
  },
  orangeButton: {
    backgroundColor: '#F18F01',
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
