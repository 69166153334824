import {
  Box, Card, CardContent, Divider, Grid, Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import {
  DollarSign as DollarSignIcon,
  User as UserIcon,
  List as ListIcon,
} from 'react-feather';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import ActiveModuleButton from '../../../components/Buttons/Modules/ActiveModuleButton';
import DisabledModuleButton from '../../../components/Buttons/Modules/DisabledModuleButton';
import { putModule } from '../../../requests/api/module';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';
import { CLIENT_MANAGEMENT, PRICE_LIST_MANAGEMENT, PRICE_CHANGE } from '../../../utils/const/modules';

export default function EditModuleForm({
  clientModule, clients, requestUser, selectedClient, setSelectedClient,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [moduleCheck, setModuleCheck] = useState({
    rdcPayment: clientModule.rdcPayment,
    eBilling: clientModule.eBilling,
    couponManagement: clientModule.couponManagement,
    clientManagement: clientModule.clientManagement,
    priceListManagement: clientModule.priceListManagement,
    priceChange: clientModule.priceChange,
  });
  const editModule = async (moduleChange) => {
    try {
      const response = await putModule(selectedClient.id, clientModule.id, moduleChange);
      handleApiResponse(enqueueSnackbar, response, true);
      if (response.success) {
        setTimeout(() => window.location.reload(), 600);
      }
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleModuleCheck = (module) => {
    const newModuleCheck = {
      ...moduleCheck,
      [module]: !moduleCheck[module],
    };
    setModuleCheck(newModuleCheck);
    editModule(newModuleCheck);
  };

  return (
    <Card>
      {requestUser.role === SUPER_ADMIN
      && (
      <Box m={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disablePortal
            noOptionsText='Sin clientes para mostrar'
            id="combo-box-demo"
            placeholder="Buscar cliente"
            options={clients}
            value={selectedClient}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(e, value) => {
              if (value === null) return;
              setSelectedClient({
                id: value.id,
                name: value.name,
              });
            }}
            renderInput={(params) => (
              <CustomTextField
              {...params}
              label="Buscar cliente"
              variant="outlined"
              />
            )}
             />
          </Grid>
        </Grid>
      </Box>
      )}
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {clientModule.clientManagement ? (
              <ActiveModuleButton
              onClick={() => handleModuleCheck(CLIENT_MANAGEMENT)}
              title='Esta opción está activada. Si la seleccionas, se deshabilitará la posibilidad de asociar clientes frecuentes'
              >
                <div style={{ display: 'flex' }}>
                <UserIcon />
                <div style={{ paddingLeft: 10 }}>Asociar Clientes</div>
                </div>
                <div>Activado</div>
              </ActiveModuleButton>
            ) : (
                <DisabledModuleButton
                onClick={() => handleModuleCheck(CLIENT_MANAGEMENT)}

                title='Esta opción está desactivada. Si la seleccionas, se habilitará la posibilidad de asociar clientes frecuentes'
                >
                <div style={{ display: 'flex' }}>
                <UserIcon />
                <div style={{ paddingLeft: 10 }}>Asociar Clientes</div>
                </div>
                <div>GRATIS</div>
              </DisabledModuleButton>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {clientModule.priceListManagement ? (
              <ActiveModuleButton
              onClick={() => handleModuleCheck(PRICE_LIST_MANAGEMENT)}
              title='Esta opción está activada. Si la seleccionas, se deshabilitará la posibilidad de crear distintas listas de precios'
              >
                <div style={{ display: 'flex' }}>
                <ListIcon />
                <div style={{ paddingLeft: 10 }}>Asociar listas de precios</div>
                </div>
                <div>Activado</div>
              </ActiveModuleButton>
            ) : (
              <DisabledModuleButton
              onClick={() => handleModuleCheck(PRICE_LIST_MANAGEMENT)}
              title='Esta opción está desactivada. Si la seleccionas, se habilitará la posibilidad de crear distintas listas de precios (ej: precios regulares, precios con descuento, etc)'
              >
                <div style={{ display: 'flex' }}>
                <ListIcon />
                <div style={{ paddingLeft: 10 }}>Asociar listas de precios</div>
                </div>
                <div>GRATIS</div>
              </DisabledModuleButton>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {clientModule.priceChange ? (
                <ActiveModuleButton
                onClick={() => handleModuleCheck(PRICE_CHANGE)}

                title='Esta opción está activada. Si la seleccionas, se deshabilitará la posibilidad de cambiar los precios de los productos'
                >
                <div style={{ display: 'flex' }}>
                <DollarSignIcon />
                <div style={{ paddingLeft: 10 }}>Cambiar precios</div>
                </div>
                <div>Activado</div>
              </ActiveModuleButton>
            ) : (
              <DisabledModuleButton
              onClick={() => handleModuleCheck(PRICE_CHANGE)}
              title='Esta opción está desactivada. Si la seleccionas, se habilitará la posibilidad de cambiar los precios de los productos'
              >
                <div style={{ display: 'flex' }}>
                <DollarSignIcon />
                <div style={{ paddingLeft: 10 }}>Cambiar precios</div>
                </div>
                <div>GRATIS</div>
              </DisabledModuleButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
            <Divider />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                  variant="h4"
                  color="textSecondary"
                >
                  Costo Mensual
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  color="textPrimary"
                >
                  $ 0
                </Typography>
              </Grid>
            </Grid>
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControlLabel control={<Checkbox
              onChange={() => handleModuleCheck('rdcPayment')}
              checked={moduleCheck.rdcPayment}
              />}
              label='Pago con máquina Redelcom'/>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControlLabel control={<Checkbox
              onChange={() => handleModuleCheck('eBilling')}
              checked={moduleCheck.eBilling}
              />}
              label='Factura electrónica'/>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControlLabel control={<Checkbox
              onChange={() => handleModuleCheck('couponManagement')}
              checked={moduleCheck.couponManagement}
              />}
              label='Asociar cupones'/>
            </FormGroup>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
}
