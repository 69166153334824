import * as React from 'react';
import Button from '@mui/material/Button';

export default function CancelButton(props) {
  return (
    <Button
    sx={{
      backgroundColor: '#F18F01',
      color: 'white',
      paddingX: 10,
      boxShadow: 3,
    }}
    {...props}
  >
    {props.children}
  </Button>
  );
}
