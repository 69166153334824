import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import DashboardLayout from './components/Navigation';
import LoadingScreen from './components/Loading/LoadingScreen';

import Login from './views/Login';
import Verification from './views/Verification';
import PasswordRecovery from './views/PasswordRecovery';
import PostRegister from './views/Register/PostRegister';

import UserListView from './views/Admin/Users/UserListView';
import UserCreateView from './views/Admin/Users/UserCreateView';
import UserEditView from './views/Admin/Users/UserEditView';

import ClientListView from './views/Client/ClientListView/index';
import ClientEditView from './views/Client/ClientEditView';
import ClientCreateView from './views/Client/ClientCreateView/index';

import MachineListView from './views/Machine/MachineListView';
import MachineCreateView from './views/Machine/CreateMachineView';
import MachineEditView from './views/Machine/EditMachineView';

import StoreCreateView from './views/Store/CreateStoreView';
import StoreListView from './views/Store/StoreListView';
import StoreEditView from './views/Store/EditStoreView';

import { SUPER_ADMIN, CLIENT_ADMIN } from './utils/const/user_types';
import ProductsListView from './views/Product/ProductsListView';
import ProductCreateView from './views/Product/ProductCreateView';
import ProductEditView from './views/Product/ProductEditView';
import PriceListView from './views/PriceList/PriceListView';
import PriceListCreateView from './views/PriceList/PriceListCreateView';
import PriceListEditView from './views/PriceList/PriceListEditView';

import CouponListView from './views/Coupon/CouponListView';
import CouponCreateView from './views/Coupon/CreateCouponView';
import CouponEditView from './views/Coupon/EditCouponView';

import StockListView from './views/Stock/StockListView';
import StockEditView from './views/Stock/UpdateStockView';

import OrderListView from './views/Order/OrderListView';
import OrderDetailView from './views/Order/OrderDetailView';

import ReturnListView from './views/Returns/ReturnListView';
import ReturnDetailView from './views/Returns/ReturnDetailView';

import FixedCostsListView from './views/FixedCosts/CostsListView';
import CreateCostView from './views/FixedCosts/CreateCostView';
import EditCostView from './views/FixedCosts/EditCostView';

import EditModuleView from './views/Module/ModuleEditView';
import SellAndCostChart from './views/Chart/SellCostChartView';

import CustomersListView from './views/Customer/CustomerListView';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />,
  },
  {
    id: 2,
    exact: true,
    path: '/login',
    component: () => <Login />,
  },
  {
    id: 4,
    exact: true,
    path: '/verificacion-usuario/:token',
    component: () => <Verification />,
  },
  {
    id: 5,
    exact: true,
    path: '/restablecer-contrasena/:token',
    component: () => <PasswordRecovery />,
  },
  {
    id: 6,
    exact: true,
    path: '/restablecer-contrasena?token=:token',
    component: () => <Redirect to="/restablecer-contrasena/:token" />,
  },
  {
    id: 7,
    exact: true,
    path: '/enviar-registro',
    component: () => <PostRegister />,
  },
  {
    path: '/administracion',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 11,
        exact: true,
        path: '/administracion',
        component: () => <Redirect to="/administracion/usuarios" />,
      },
      {
        id: 12,
        exact: true,
        path: '/administracion/usuarios',
        component: () => <UserListView />,
      },
      {
        id: 13,
        exact: true,
        path: '/administracion/usuarios/crear',
        component: () => <UserCreateView />,
      },
      {
        id: 14,
        exact: true,
        path: '/administracion/usuarios/:id/editar',
        component: () => <UserEditView />,
      },
      {
        id: 18,
        exact: true,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        path: '/administracion/maquinas',
        component: () => <MachineListView />,
      },
      {
        id: 19,
        exact: true,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        path: '/administracion/maquinas/crear',
        component: () => <MachineCreateView />,
      },
      {
        id: 20,
        exact: true,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        path: '/administracion/maquinas/:id/editar',
        component: () => <MachineEditView />,
      },
      {
        id: 21,
        exact: true,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        path: '/administracion/tiendas',
        component: () => <StoreListView />,
      },
      {
        id: 22,
        exact: true,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        path: '/administracion/tiendas/crear',
        component: () => <StoreCreateView />,
      },
      {
        id: 23,
        exact: true,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        path: '/administracion/tiendas/:id/editar',
        component: () => <StoreEditView />,
      },
      {
        id: 100,
        component: () => <Redirect to="/graficos" />,
      },
    ],
  },
  {
    path: '/pos',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN],
    routes: [
      {
        id: 15,
        exact: true,
        path: '/pos/clientes',
        component: () => <ClientListView />,
      },
      {
        id: 16,
        exact: true,
        path: '/pos/clientes/:id/editar',
        component: () => <ClientEditView />,
      },
      {
        id: 17,
        exact: true,
        path: '/pos/clientes/crear',
        component: () => <ClientCreateView />,
      },
      {
        id: 99,
        component: () => <Redirect to="/graficos" />,
      },
    ],
  },
  {
    path: '/productos',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 21,
        exact: true,
        path: '/productos',
        component: () => <ProductsListView />,
      },
      {
        id: 22,
        exact: true,
        path: '/productos/crear',
        component: () => <ProductCreateView />,
      },
      {
        id: 23,
        exact: true,
        path: '/productos/:productId/editar',
        component: () => <ProductEditView />,
      },
      {
        id: 98,
        component: () => <Redirect to="/graficos" />,
      },
    ],
  },
  {
    path: '/precios',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 21,
        exact: true,
        path: '/precios',
        component: () => <PriceListView />,
      },
      {
        id: 22,
        exact: true,
        path: '/precios/crear',
        component: () => < PriceListCreateView />,
      },
      {
        id: 23,
        exact: true,
        path: '/precios/:priceListId/editar',
        component: () => < PriceListEditView/>,
      },
      {
        id: 97,
        component: () => <Redirect to="/graficos" />,
      },
    ],
  },
  {
    path: '/cupones',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 24,
        exact: true,
        path: '/cupones',
        component: () => <CouponListView />,
      },
      {
        id: 25,
        exact: true,
        path: '/cupones/crear',
        component: () => <CouponCreateView />,
      },
      {
        id: 26,
        exact: true,
        path: '/cupones/:id/editar',
        component: () => <CouponEditView />,
      },
    ],
  },
  {
    path: '/inventario',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 27,
        exact: true,
        path: '/inventario',
        component: () => <StockListView />,
      },
      {
        id: 28,
        exact: true,
        path: '/inventario/editar',
        component: () => <StockEditView />,
      },
    ],
  },
  {
    path: '/pedidos',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 29,
        exact: true,
        path: '/pedidos',
        component: () => <OrderListView />,
      },
      {
        id: 30,
        exact: true,
        path: '/pedidos/:id/detalle',
        component: () => <OrderDetailView />,
      },
    ],
  },
  {
    path: '/devoluciones',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 31,
        exact: true,
        path: '/devoluciones',
        component: () => <ReturnListView />,
      },
      {
        id: 32,
        exact: true,
        path: '/devoluciones/:id/detalle',
        component: () => <ReturnDetailView />,
      },
    ],
  },
  {
    path: '/modulos',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 33,
        exact: true,
        path: '/modulos',
        component: () => <EditModuleView />,
      },
    ],
  },
  {
    path: '/costos-fijos',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 34,
        exact: true,
        path: '/costos-fijos',
        component: () => <FixedCostsListView />,
      },
      {
        id: 35,
        exact: true,
        path: '/costos-fijos/crear',
        component: () => <CreateCostView />,
      },
      {
        id: 36,
        exact: true,
        path: '/costos-fijos/:id/editar',
        component: () => <EditCostView />,
      },
    ],
  },
  {
    path: '/graficos',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 37,
        exact: true,
        path: '/graficos',
        component: () => <SellAndCostChart />,
      },
    ],
  },
  {
    path: '/clientes',
    guard: AuthGuard,
    layout: DashboardLayout,
    roles: [SUPER_ADMIN, CLIENT_ADMIN],
    routes: [
      {
        id: 38,
        exact: true,
        path: '/clientes',
        component: () => <CustomersListView />,
      },
    ],
  },
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const { roles } = route;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              (Guard !== Fragment ? <Guard
                roles={roles}
              >
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard> : <Guard>
              <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>)
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
