import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import useStyles from './styles';
import { putProduct } from '../../../requests/api/products';
import handleApiResponse from '../../../utils/handleApiResponse';

function ProductEditForm({
  product,
  className,
  ...rest
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/productos" />;
  }

  return (
    <Formik
      initialValues={{
        id: product ? product.id : '',
        name: product ? product.name : '',
        sku: product ? product.sku : '',
        ean: product ? product.ean : '',
        category: product ? product.category : '',
        price: product ? product.price : '',
        cost: product ? product.cost : '',
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        sku: Yup.string().max(255).required('Debe ingresar un SKU'),
        ean: Yup.string().max(255).required('Debe ingresar un EAN'),
        category: Yup.string().required('Debe seleccionar una categoría'),
        price: Yup.number().typeError('Debe ingresar un número').required('Debe ingresar un precio'),
        cost: Yup.number().typeError('Debe ingresar un número').required('Debe ingresar un costo'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
        setFieldValue,
      }) => {
        try {
          // Make API request
          const data = {
            id: values.id,
            name: values.name,
            sku: values.sku,
            ean: values.ean,
            category: values.category,
            price: values.price,
            cost: values.cost,
          };

          const response = await putProduct(data);

          handleApiResponse(enqueueSnackbar, response, true);
          setStatus({ success: true });
          setSubmitting(false);
          setSubmitSuccess(true);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/productos">Volver a Productos</Button>);
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
          setFieldValue('price', product.price);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.rootCreateForm, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.sku && errors.sku)}
                    fullWidth
                    helperText={touched.sku && errors.sku}
                    label="SKU"
                    name="sku"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.sku}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.ean && errors.ean)}
                    fullWidth
                    helperText={touched.ean && errors.ean}
                    label="EAN"
                    name="ean"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.ean}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.category && errors.category)}
                    fullWidth
                    helperText={touched.category && errors.category}
                    label="Categoría"
                    name="category"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.category}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.price && errors.price)}
                    fullWidth
                    helperText={touched.price && errors.price}
                    label="Precio"
                    name="price"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.price}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.cost && errors.cost)}
                    fullWidth
                    helperText={touched.cost && errors.cost}
                    label="Costo"
                    name="cost"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.cost}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box
                mt={2}
                display='flex'
                justifyContent='flex-end'
                >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ProductEditForm.propTypes = {
  className: PropTypes.string,
};

export default ProductEditForm;
