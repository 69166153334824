import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import useStyles from './styles';

function TabSelection() {
  const classes = useStyles();
  const {
    couponPage, couponAllStoresCheck, couponAllProductsCheck,
  } = useSelector((state) => state.coupon);

  const handleStyles = (sourcePage, source) => {
    if ((couponPage === 0 && source) || (couponPage !== sourcePage && source)) {
      return classes.tabSelectionItemDisabled;
    }

    if (couponPage === sourcePage && !source) {
      return classes.tabSelectionItemSelected;
    }
    return classes.tabSelectionItem;
  };

  return (
    <Paper
      elevation={3}
      className={classes.tabSelectionPaper}
    >
      <Box
        className={couponPage === 0 ? classes.tabSelectionItemSelected : classes.tabSelectionItem}
      >
        <Typography
          variant="h4"
        >
          Información general:
        </Typography>
      </Box>
      <Box
        className={handleStyles(1, couponAllStoresCheck)}
      >
        <Typography
          variant="h4"
        >
          Asociar tiendas
        </Typography>
      </Box>
      <Box
    className={handleStyles(2, couponAllProductsCheck)}
      >
        <Typography
          variant="h4"
        >
          Asociar productos:
        </Typography>
      </Box>

    </Paper>
  );
}

TabSelection.propTypes = {
  className: PropTypes.string,
};

export default TabSelection;
