export const RESET_STORE_CREATION = '@store/reset-store-creation';
export const SET_STORE_DETAILS = '@store/set-store-details';
export const SET_STORE_CREATION_PAGE = '@store/set-store-creation-page';
export const SET_STORE_ALL_USERS_CHECK = '@store/set-coupon-all-users-check';

export function resetStoreCreation() {
  return {
    type: RESET_STORE_CREATION,
  };
}

export function setStoreDetails(payload) {
  return {
    type: SET_STORE_DETAILS,
    payload,
  };
}

export function setStoreCreationPage(payload) {
  return {
    type: SET_STORE_CREATION_PAGE,
    payload,
  };
}

export function setStoreAllUsersCheck(payload) {
  return {
    type: SET_STORE_ALL_USERS_CHECK,
    payload,
  };
}
