import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { X as XIcon } from 'react-feather';
import couponRequest from '../../../requests/api/coupon';
import handleApiResponse from '../../../utils/handleApiResponse';
import { resetCouponCreation, setCouponCreationPage, setCouponDetails } from '../../../actions/couponActions';
import useStyles from './styles';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';

function ProductsAssociation({
  products, totalProducts, page, limit, setPage, setLimit, setSearchValue, setSearchValueProduct,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentProducts, setCurrentProducts] = useState(products);
  const [query, setQuery] = useState('');
  const { couponInformation, couponAllStoresCheck } = useSelector((state) => state.coupon);

  const handleSearchValue = () => {
    if (query.trim()) {
      setSearchValueProduct(query);
    }
  };

  const handleSelectAllProducts = (event) => {
    if (event.target.checked) {
      const allProducts = currentProducts.map((prd) => prd.id);
      setSelectedProducts(allProducts);
      dispatch(setCouponDetails({ productIds: allProducts }));
      return;
    }
    setSelectedProducts([]);
    dispatch(setCouponDetails({ productIds: [] }));
  };

  const handleSelectOneProduct = (event, productId) => {
    if (!selectedProducts.includes(productId)) {
      setSelectedProducts((prevSelected) => [...prevSelected, productId]);
      dispatch(setCouponDetails({ productIds: [...selectedProducts, productId] }));
    } else {
      setSelectedProducts((prevSelected) => prevSelected.filter((id) => id !== productId));
      dispatch(setCouponDetails({ productIds: selectedProducts.filter((id) => id !== productId) }));
    }
  };

  const selectedSomeProducts = selectedProducts.length > 0
    && selectedProducts.length < currentProducts.length;
  const selectedAllProducts = selectedProducts.length === currentProducts.length;

  const handlePreviousStep = () => {
    if (couponAllStoresCheck) {
      setSearchValueProduct('');
      dispatch(setCouponCreationPage(0));
    } else {
      setSearchValue('');
      setSearchValueProduct('');
      dispatch(setCouponCreationPage(1));
    }
  };

  const handleNextStep = async () => {
    if (selectedProducts.length === 0) {
      enqueueSnackbar('Debes seleccionar por lo menos un producto', {
        variant: 'error',
      });
      return;
    }

    try {
      const response = await couponRequest.postCreateCoupon({
        ...couponInformation,
        productIds: selectedProducts,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      dispatch(resetCouponCreation());
      history.push('/cupones');
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    setCurrentProducts(products);
    setSelectedProducts(couponInformation.productIds || []);
  }, [products]);
  return (
    <Box>
      <Paper
        elevation={3}
      >
        <Box
          display='flex'
          alignItems='center'
          padding='15px 10px'
          justifyContent='space-between'
        >
          <Box
            flex={1}
            mr={4}
          >
            <CustomTextField
              fullWidth
              color="secondary"
              placeholder="Nombre, SKU, EAN"
              type="text"
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      query && (<IconButton
                        onClick={() => {
                          setSearchValueProduct('');
                          setQuery('');
                        }}
                      >
                        <XIcon />
                      </IconButton>)
                    }
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSearchValue}
            >
              Buscar
            </Button>
          </Box>
        </Box>
      </Paper>
      <Box mt={3}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllProducts}
                    indeterminate={selectedSomeProducts}
                    onChange={handleSelectAllProducts}
                  />
                </TableCell>
                <TableCell>
                  Producto
                </TableCell>
                <TableCell>
                  Precio
                </TableCell>
                <TableCell>
                  Categoria
                </TableCell>
                <TableCell>
                  SKU
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.length !== 0 && products.map((product) => {
                const isProductSelected = selectedProducts.includes(product.id);
                return (
                  <TableRow
                    key={product.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isProductSelected}
                        onChange={(event) => handleSelectOneProduct(event, product.id)}
                        value={isProductSelected}
                      />
                    </TableCell>
                    <TableCell>
                      {product.name}
                    </TableCell>
                    <TableCell>
                      {product.price}
                    </TableCell>
                    <TableCell>
                      {product.category}
                    </TableCell>
                    <TableCell>
                      {product.sku}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box
            mt={2}
            className={classes.tablePagination}
          >
            <TablePagination
              component="div"
              count={totalProducts}
              onPageChange={(e, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value);
                setPage(0);
              }}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Filas por página"
            />
            <Box display='flex'>
              <Box
                mr={1}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handlePreviousStep}
                >
                  Atrás
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.orangeButton}
                  onClick={handleNextStep}
                >
                  Crear Cupón
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>

  );
}

ProductsAssociation.propTypes = {
  className: PropTypes.string,
};

export default ProductsAssociation;
