import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import CreateCostForm from './CreateCostForm';
import handleApiResponse from '../../../utils/handleApiResponse';
import clientRequest from '../../../requests/api/client';
import storeRequest from '../../../requests/api/store';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import SplashScreen from '../../../components/Loading/SplashScreen';

function CreateCostView() {
  const classes = useStyles();
  const account = useSelector((s) => s.account);
  const { enqueueSnackbar } = useSnackbar();
  const [clients, setClients] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });
  const limit = '';
  const page = '';
  const searchValue = '';

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest
          .getClientsPerPage(page, limit, searchValue, selectedClient.id);
        setClients(response.clients);
        if (!selectedClient.id) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getStores = async () => {
      try {
        const response = await storeRequest
          .getStoresPerPage(page, limit, searchValue, selectedClient.id);
        if (!response.success) {
          setStores([]);
          return;
        }
        setStores(response.allStores);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (account.user.role === SUPER_ADMIN) getClients();
    if (selectedClient.id) getStores();
  }, [limit, page, searchValue, selectedClient.id]);

  if (!selectedClient.id) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Agregar Costo"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
        <CreateCostForm
        clients={clients}
        stores={stores}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        role={account.user.role}
        />
        </Box>
      </Container>
    </Page>
  );
}

export default CreateCostView;
