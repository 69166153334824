import axios from '../../utils/axios';

const postCreateReturn = async (newReturn) => {
  const response = await axios.post('/api/return/create', { ...newReturn });
  return response.data;
};

const getReturnsPerPage = async (
  page,
  limit,
  billingSearchValue,
  orderSearchValue,
  clientId,
  startDate,
  endDate,
) => {
  const response = await axios.get(`/api/returns?page=${page}&limit=${limit}&billingSearchValue=${billingSearchValue}&orderSearchValue=${orderSearchValue}&clientId=${clientId}&startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};
const getReturnById = async (returnId) => {
  const response = await axios.get(`/api/return/${returnId}`);
  return response.data;
};

export {
  postCreateReturn,
  getReturnsPerPage,
  getReturnById,
};
