import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Container } from '@material-ui/core';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import { getCostById } from '../../../requests/api/fixedCosts';
import storeRequest from '../../../requests/api/store';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';
import SplashScreen from '../../../components/Loading/SplashScreen';
import EditCostForm from './EditCostForm';

function EditCostView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [cost, setCost] = useState({});
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedCostType, setSelectedCostType] = useState(null);
  const user = useSelector((s) => s.account.user);
  const clientId = user.role === SUPER_ADMIN ? cost.clientId : user.clientId;
  const page = 0;
  const limit = '';
  const searchValue = '';

  useEffect(() => {
    const getCost = async () => {
      try {
        const response = await getCostById(id);
        if (response.success) {
          setCost(response.cost);
          setSelectedCostType({
            name: response.cost.type,
            value: response.cost.type,
          });
          if (response.cost.store) {
            setSelectedStore({
              id: response.cost.storeId,
              name: response.cost.store.name,
            });
          }
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getCost();
  }, [id]);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await storeRequest.getStoresPerPage(page, limit, searchValue, clientId);
        setStores(response.allStores);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (cost.id) getStores();
  }, [cost]);

  if (!cost.id || (cost.store && !selectedStore) || !selectedCostType) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Editar costo"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
        <EditCostForm
          cost={cost}
          stores={stores}
          clientId={clientId}
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
          role={user.role}
          selectedCostType={selectedCostType}
          setSelectedCostType={setSelectedCostType}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default EditCostView;
