import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Page from '../../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import SplashScreen from '../../../../components/Loading/SplashScreen';
import { SUPER_ADMIN } from '../../../../utils/const/user_types';
import { getUsersPerPage } from '../../../../requests/api/users';
import clientRequest from '../../../../requests/api/client';

import useStyles from './styles';
import handleApiResponse from '../../../../utils/handleApiResponse';

function UserListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const historyPreviousPath = history.location.state && history.location.state.prevPath === '/usuario/editar';
  const reducerFilters = useSelector((s) => s.filter.filter);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(historyPreviousPath ? reducerFilters.page : 0);
  const [limit, setLimit] = useState(historyPreviousPath ? reducerFilters.limit : 10);
  const [query, setQuery] = useState(historyPreviousPath ? reducerFilters.query : '');
  const [sort, setSort] = useState(historyPreviousPath ? reducerFilters.sort : 'updated_at|DESC');

  const account = useSelector((s) => s.account);
  const [selectedClient, setSelectedClient] = useState(historyPreviousPath ? {
    id: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.id
      : account.user.clientId,
    name: account.user.role === SUPER_ADMIN ? reducerFilters.selectedClient.name
      : null,
  } : {
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });
  const [clients, setClients] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(
    historyPreviousPath ? reducerFilters.isSuperAdmin : false,
  );
  const [count, setCount] = useState(null);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await
        getUsersPerPage(page, limit, query, sort, selectedClient.id, isSuperAdmin);
        setUsers(response.users);
        setCount(response.totalUsers);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage(page, '', '');
        setClients(response.clients);
        // check if there is no client selected before setting clientId with
        // the response first client
        if (response.clients.length !== 0 && selectedClient.id === null) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
        if (response.clients.length === 0) throw new Error('No hay clientes para mostrar');
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    if (selectedClient.id) getUsers();
    if (account.user.role === SUPER_ADMIN) getClients();
  }, [sort, limit, page, query, selectedClient, isSuperAdmin]);

  if (!selectedClient.id) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Usuarios"
    >
      <Container maxWidth={false}>
        <Header />
          <Box mt={3}>
            <Results
              users={users}
              count={count || 0}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              query={query}
              setQuery={setQuery}
              sort={sort}
              setSort={setSort}
              role={account.user.role}
              clients={clients}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              isSuperAdmin={isSuperAdmin}
              setIsSuperAdmin={setIsSuperAdmin}
              history={history}
              />
          </Box>
      </Container>
    </Page>
  );
}

export default UserListView;
