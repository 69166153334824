import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import CancelButton from '../../../components/Buttons/Cancel/CancelButton';

function Header({
  className, comingFromDevolution, ...rest
}) {
  const history = useHistory();
  const handleGoBackToOrders = () => {
    history.push('/pedidos', { prevPath: '/pedido/detalle' });
  };
  const handleGoBackToReturns = () => {
    history.push('/devoluciones', { prevPath: '/pedido/detalle' });
  };
  return (
    <Grid
      className={clsx(className)}
      container
      justifyContent="space-between"
      spacing={1}
      {...rest}
    ><Grid item>
    <Grid container spacing= {1}>
      <Grid item>
        <Typography
          variant="h3"
          color="textSecondary"
          >
          Detalle
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          de Pedido
        </Typography>
    </Grid>
    </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          {!comingFromDevolution && (
            <Grid item>
            <CancelButton
            component={Button}
            onClick={() => handleGoBackToOrders()}
            >
            Volver a pedidos
            </CancelButton>
          </Grid>
          )}
          {comingFromDevolution
          && (
          <Grid item>
            <CancelButton
            component={Button}
            onClick={() => handleGoBackToReturns()}
            >
                Volver a devoluciones
              </CancelButton>
          </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
