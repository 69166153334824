import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import Page from '../../../components/Layout/Page';
import ProductEditForm from './ProductEditForm';
import Header from './Header';

import useStyles from './styles';
import { getProductById } from '../../../requests/api/products';
import handleApiResponse from '../../../utils/handleApiResponse';

function ProductEditView() {
  const classes = useStyles();
  const { productId } = useParams();
  const [selectedProduct, setSelectedProduct] = useState();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const response = await getProductById(productId);
        setSelectedProduct(response.searchedProduct);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    getProductDetails();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Productos | Editar Producto"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
        <ProductEditForm product={selectedProduct}/>
        </Box>
      </Container>
    </Page>
  );
}

export default ProductEditView;
