import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { putCostById } from '../../../requests/api/fixedCosts';
import useStyles from './styles';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import handleApiResponse from '../../../utils/handleApiResponse';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import {
  RENT, SALARIES, EXPENSES, SERVICES, OTHER,
} from '../../../utils/const/costTypes';

function EditCostForm({
  className,
  cost,
  stores,
  selectedStore,
  setSelectedStore,
  clientId,
  role,
  selectedCostType,
  setSelectedCostType,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const popperSx = {
    '& .MuiPaper-root': {
      border: '1px solid black',
      color: 'rgb(229, 228, 226)',
      backgroundColor: 'rgba(64, 64, 64, 0.5)',
    },
    '& .MuiPickersDay-dayWithMargin': {
      color: 'rgb(229, 228, 226)',
      backgroundColor: 'rgba(93, 97, 93)',
    },
  };

  const costTypes = [
    {
      name: 'Arriendo',
      value: RENT,
    },
    {
      name: 'Sueldos',
      value: SALARIES,
    },
    {
      name: 'Gastos Comunes',
      value: EXPENSES,
    },
    {
      name: 'Servicios',
      value: SERVICES,
    },
    {
      name: 'Otro',
      value: OTHER,
    },
    {
      name: cost.type,
      value: cost.type,
    },
  ];

  if (submitSuccess) {
    return <Redirect to="/costos-fijos" />;
  }

  return (
    <Formik
      initialValues={{
        clientId,
        storeId: cost.storeId,
        cost: cost.cost,
        type: cost.type,
        date: cost.date,
        autocomplete: cost.type,
      }}
      validationSchema={Yup.object().shape({
        cost: Yup.number().typeError('Debe ingresar un número').required('Debe ingresar el valor del costo'),
        select: Yup.string().max(255).required('Debe ingresar el tipo de costo'),
        date: Yup.date().required('Debe ingresar la fecha del costo'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            id: cost.id,
            clientId: values.clientId,
            storeId: values.storeId,
            cost: values.cost,
            type: values.autocomplete === OTHER ? values.type : values.autocomplete,
            date: values.date,
          };
          const response = await putCostById(data);

          handleApiResponse(enqueueSnackbar, response, true);
          setStatus({ success: true });
          setSubmitting(false);
          setSubmitSuccess(true);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/costos-fijos">Volver a Costos Fijos</Button>);
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                {role === SUPER_ADMIN
                && <Grid item xs={12}>
                <CustomTextField
                fullWidth
                label="Cliente"
                name="client"
                disabled
                value={cost.client.name}
                variant="outlined"
                />
              </Grid>
              }
                {stores
                && <Grid item md={6} xs={12}>
                  <CustomAutocomplete
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disablePortal
                    noOptionsText='Sin tiendas para mostrar'
                    id="combo-box-demo"
                    placeholder="Buscar tienda"
                    options={stores}
                    value={selectedStore}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(e, value) => {
                      if (value === null) return;
                      setFieldValue('storeId', value.id);
                      setSelectedStore({ id: value.id, name: value.name });
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                      {...params}
                      label="Buscar tienda"
                      variant="outlined"
                      />
                    )}
                  />
                  </Grid>}
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      views={['day', 'month', 'year']}
                      label="Fecha"
                      value={values.date}
                      onChange={(value) => {
                        if (value === null) return;
                        setFieldValue('date', value);
                      }}
                      PopperProps={{
                        sx: popperSx,
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant='outlined'
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomAutocomplete
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  disablePortal
                  filterSelectedOptions={true}
                  id="combo-box-demo"
                  placeholder="Tipo de costo"
                  options={costTypes}
                  value={selectedCostType}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, value) => {
                    if (value === null) return;
                    setSelectedCostType({
                      name: value.value,
                      value: value.value,
                    });
                    setFieldValue('autocomplete', value.value);
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                    {...params}
                    label="Tipo de costo"
                    variant="outlined"
                    />
                  )}
                  />
                  {values.autocomplete === OTHER
              && <Box mt={2}>
                <CustomTextField
                   fullWidth
                   label="Nombre del nuevo costo"
                   name="type"
                   onChange={handleChange}
                   onBlur={handleBlur}
                   required
                   value={values.type}
                   variant="outlined"
                 />
              </Box>
                }
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    fullWidth
                    label="Costo"
                    name="cost"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.cost}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box mt={2} ml={2} display='flex' justifyContent='flex-end'>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Confirmar Cambios
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

EditCostForm.propTypes = {
  className: PropTypes.string,
};

export default EditCostForm;
