import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { postNewCost } from '../../../requests/api/fixedCosts';
import useStyles from './styles';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import handleApiResponse from '../../../utils/handleApiResponse';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import {
  RENT, SALARIES, EXPENSES, SERVICES, OTHER,
} from '../../../utils/const/costTypes';

function CreateCostForm({
  className, clients, stores, selectedClient, setSelectedClient, role, ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const popperSx = {
    '& .MuiPaper-root': {
      border: '1px solid black',
      color: 'rgb(229, 228, 226)',
      backgroundColor: 'rgba(64, 64, 64, 0.5)',
    },
    '& .MuiPickersDay-dayWithMargin': {
      color: 'rgb(229, 228, 226)',
      backgroundColor: 'rgba(93, 97, 93)',
    },
  };

  if (submitSuccess) {
    return <Redirect to="/costos-fijos" />;
  }

  return (
    <Formik
      initialValues={{
        clientId: selectedClient.id,
        storeId: '',
        cost: '',
        type: '',
        date: null,
        select: '',
        // TODO: usar valores de una constante o bdd
        frequency: 'no',
      }}
      validationSchema={Yup.object().shape({
        cost: Yup.number().required('Debe ingresar el valor del costo'),
        select: Yup.string().max(255).required('Debe ingresar el tipo de costo'),
        date: Yup.date().required('Debe ingresar la fecha del costo'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            clientId: values.clientId,
            storeId: values.storeId,
            cost: values.cost,
            type: values.select === OTHER ? values.type : values.select,
            date: values.date,
            // TODO: agregar frecuencia
          };
          const response = await postNewCost(data);

          handleApiResponse(enqueueSnackbar, response, true);
          setStatus({ success: true });
          setSubmitting(false);
          setSubmitSuccess(true);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/costos-fijos">Volver a Costos Fijos</Button>);
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                {role === SUPER_ADMIN
                && <Grid item xs={12}>
                <CustomAutocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disablePortal
                  noOptionsText='Sin clientes para mostrar'
                  id="combo-box-demo"
                  placeholder="Cliente"
                  options={clients}
                  value={selectedClient}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, value) => {
                    if (value === null) return;
                    setSelectedClient({
                      id: value.id,
                      name: value.name,
                    });
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                    {...params}
                    label="Cliente"
                    variant="outlined"
                    />
                  )}
                />
              </Grid>
              }
                {stores
                && <Grid item md={6} xs={12}>
                  <CustomAutocomplete
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disablePortal
                    noOptionsText='Sin tiendas para mostrar'
                    id="combo-box-demo"
                    placeholder="Tienda"
                    options={stores}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(e, value) => {
                      if (value === null) return;
                      setFieldValue('storeId', value.id);
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                      {...params}
                      label="Tienda"
                      variant="outlined"
                      />
                    )}
                  />
                  </Grid>}
                  <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                    error={Boolean(touched.date && errors.date)}
                  >
                    <DatePicker
                      name="date"
                      views={['day', 'month', 'year']}
                      label="Fecha"
                      value={values.date}
                      onChange={(value) => {
                        if (value === null) return;
                        setFieldValue('date', value);
                      }}
                      PopperProps={{
                        sx: popperSx,
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant='outlined'
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    fullWidth
                    label="Tipo de costo"
                    name="select"
                    onChange={handleChange}
                    required
                    select
                    value={values.select}
                    variant="outlined"
                  >
                    <MenuItem value={RENT}>
                    Arriendo
                    </MenuItem>
                    <MenuItem value={SALARIES}>
                    Sueldos
                    </MenuItem>
                    <MenuItem value={EXPENSES}>
                    Gastos Comunes
                    </MenuItem>
                    <MenuItem value={SERVICES}>
                    Servicios
                    </MenuItem>
                    <MenuItem value={OTHER}>
                    Otro
                    </MenuItem>
                  </CustomTextField>
                  {values.select === OTHER
              && <Box mt={2}>
                <CustomTextField
                  error={Boolean(touched.type && errors.type)}
                  fullWidth
                  helperText={touched.type && errors.type}
                  label="Nombre del nuevo costo"
                  name="type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  value={values.type}
                  variant="outlined"
                />
              </Box>
             }
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    error={Boolean(touched.cost && errors.cost)}
                    fullWidth
                    helperText={touched.cost && errors.cost}
                    label="Costo"
                    name="cost"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.cost}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    fullWidth
                    label="Frecuencia"
                    name="frequency"
                    onChange={handleChange}
                    required
                    select
                    value={values.frequency}
                    variant="outlined"
                  >
                    <MenuItem value={'no'}>
                    No se repite
                    </MenuItem>
                    <MenuItem value={'dia'}>
                    Se repite cada día
                    </MenuItem>
                    <MenuItem value={'sem'}>
                    Se repite cada semana
                    </MenuItem>
                    <MenuItem value={'quin'}>
                    Se repite cada quincena
                    </MenuItem>
                    <MenuItem value={'mes'}>
                    Se repite cada mes
                    </MenuItem>
                    <MenuItem value={'tri'}>
                    Se repite cada trimestre
                    </MenuItem>
                    <MenuItem value={'semestre'}>
                    Se repite cada semestre
                    </MenuItem>
                    <MenuItem value={'ano'}>
                    Se repite cada año
                    </MenuItem>
                  </CustomTextField>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={2} ml={2} display='flex' justifyContent='flex-end'>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Agregar Costo
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

CreateCostForm.propTypes = {
  className: PropTypes.string,
};

export default CreateCostForm;
