import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import CustomTextField from '../../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../../components/Inputs/Autocomplete/CustomAutocomplete';
import getInitials from '../../../../utils/getInitials';

import useStyles from './styles';
import { deleteUsers } from '../../../../requests/api/users';
import handleApiResponse from '../../../../utils/handleApiResponse';
import { SUPER_ADMIN } from '../../../../utils/const/user_types';
import { saveFilters } from '../../../../actions/filterActions';

const sortOptions = [
  {
    value: 'updated_at|DESC',
    label: 'Más nuevo primero',
  },
  {
    value: 'updated_at|ASC',
    label: 'Más antiguo primero',
  },
];

function Results({
  users,
  count,
  query,
  setQuery,
  page,
  setPage,
  limit,
  setLimit,
  sort,
  setSort,
  role,
  clients,
  selectedClient,
  setSelectedClient,
  isSuperAdmin,
  setIsSuperAdmin,
  history,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentUsers, setCurrentUsers] = useState(users);
  const [filters, setFilters] = useState({
    page,
    limit,
    query,
    selectedClient,
    sort,
    isSuperAdmin,
  });

  useEffect(() => { setSort(sortOptions[0].value); }, []);

  useEffect(() => { setCurrentUsers(users); }, [users]);

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
    setFilters((prevVal) => ({ ...prevVal, query: event.target.value }));
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
    setFilters((prevVal) => ({ ...prevVal, sort: event.target.value }));
  };

  const handleSelectAllUsers = (event) => {
    setSelectedUsers(event.target.checked
      ? currentUsers.map((user) => user.id)
      : []);
  };

  const handleSelectOneUser = (event, userId) => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelected) => [...prevSelected, userId]);
    } else {
      setSelectedUsers((prevSelected) => prevSelected.filter((id) => id !== userId));
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    setFilters((prevVal) => ({ ...prevVal, page: newPage }));
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, limit: e.target.value }));
  };

  const enableBulkOperations = selectedUsers.length > 0;
  const selectedSomeUsers = selectedUsers.length > 0 && selectedUsers.length < currentUsers.length;
  const selectedAllUsers = selectedUsers.length === currentUsers.length;

  const deleteSelectedUsers = async () => {
    try {
      const response = await deleteUsers(selectedUsers);
      handleApiResponse(enqueueSnackbar, response, true);
      setCurrentUsers(currentUsers.filter((e) => (!selectedUsers.includes(e.id))));
      setSelectedUsers([]);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleGoToEdit = (userId) => {
    dispatch(saveFilters(filters));
    history.push(`/administracion/usuarios/${userId}/editar`);
  };

  return (
    <div>
      <Card>
        <Box
          p={2}
          minHeight={56}
          display="flex"
          alignItems="center"
        >
          <Grid container spacing={1}>
            {role === SUPER_ADMIN
              && <Grid item md={3} xs={12}>
                <CustomAutocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disablePortal
                  id="combo-box-demo"
                  options={clients}
                  value={selectedClient}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, value) => {
                    if (value === null) return;
                    setSelectedClient({
                      id: value.id,
                      name: value.name,
                    });
                    setFilters((prevVal) => ({
                      ...prevVal,
                      selectedClient: {
                        id: value.id,
                        name: value.name,
                      },
                    }));
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      label="Seleccionar cliente"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            }
            <Grid item md={3} xs={12}>
              <CustomTextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryChange}
                placeholder="Buscar usuario"
                value={query}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                fullWidth
                label="Orden"
                name="sort"
                onChange={handleSortChange}
                select
                SelectProps={{ native: true }}
                value={sort}
                variant="outlined"
              >
                {sortOptions.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </CustomTextField>
            </Grid>
            {role === SUPER_ADMIN
              && <Grid item md={3} xs={12}>
                <FormControlLabel
                  label='Solo super admins'
                  control={
                    <Checkbox
                      checked={isSuperAdmin}
                      onChange={() => setIsSuperAdmin(!isSuperAdmin)}
                    />
                  }
                />
              </Grid>}
          </Grid>
        </Box>
      </Card>
      <br />
      {/* <Card> */}
      <TableContainer component={Card}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedAllUsers}
                  indeterminate={selectedSomeUsers}
                  onChange={handleSelectAllUsers}
                />
              </TableCell>
              <TableCell>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    Nombre
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {enableBulkOperations && (
                    <div className={classes.bulkActions}>
                      <Button
                        variant="outlined"
                        className={classes.bulkAction}
                        onClick={() => deleteSelectedUsers()}
                      >
                        Eliminar
                      </Button>
                    </div>
                  )}
                </Grid>
              </TableCell>
              <TableCell>
                Rol
              </TableCell>
              <TableCell align="right">
                <TablePagination
                  component="div"
                  count={count}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={count <= 0 ? 0 : page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage="Filas por página"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentUsers.map((user) => {
              const isUserSelected = selectedUsers.includes(user.id);
              return (
                <TableRow
                  hover
                  key={user.id}
                  selected={isUserSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isUserSelected}
                      onChange={(event) => handleSelectOneUser(event, user.id)}
                      value={isUserSelected}
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Avatar
                        className={classes.avatar}
                        src={user.avatar}
                      >
                        {getInitials(user.name)}
                      </Avatar>
                      <div>
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {user.name} {' '} {user.lastname}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textPrimary"
                        >
                          {user.email}
                        </Typography>
                      </div>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {user.role}
                  </TableCell>
                  <TableCell align="right">
                    {user.role !== SUPER_ADMIN
                      && <IconButton
                        component={Button}
                        onClick={() => handleGoToEdit(user.id)}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* </Card> */}
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
  users: PropTypes.array,
};

Results.defaultProps = {
  users: [],
};

export default Results;
