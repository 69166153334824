import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.background.tableHeader,
      paddingTop: theme.spacing(3),
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    width: 500,
  },
  datePopper: {
    '& .MuiPaper-root': {
      border: '1px solid black',
      color: 'rgb(229, 228, 226)',
      backgroundColor: 'rgba(64, 64, 64, 0.5)',
    },
    '& .MuiPickersDay-dayWithMargin': {
      color: 'rgb(229, 228, 226)',
      backgroundColor: 'rgba(93, 97, 93)',
    },
  },
}));

export default useStyles;
