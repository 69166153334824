import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';
import { postProductsFromCSV, deleteProducts } from '../../../requests/api/products';
import { resetProductsChange } from '../../../actions/productsActions';
import { saveFilters } from '../../../actions/filterActions';
import thousandSeparator from '../../../utils/thousandSeparator';
import { LABELED_UNITS } from '../../../constants/unitMeasure';

function Results({
  selectedClient,
  count,
  page,
  setPage,
  limit,
  setLimit,
  products,
  searchValue,
  setSearchValue,
  setSelectedClient,
  clients,
  role,
  history,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = useState({
    page,
    limit,
    searchValue,
    selectedClient,
  });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentProducts, setCurrentProducts] = useState(products);
  const [productsChanges, setProductsChanges] = useState([]);
  const [pageCSV, setPageCSV] = useState(0);
  const [limitCSV, setLimitCSV] = useState(10);
  const [initalIndex, setInitialIndex] = useState(0);
  const [finalIndex, setFinalIndex] = useState(10);
  let clientId;
  if (selectedClient.id) clientId = selectedClient.id;
  const { productsToAddDB } = useSelector((state) => state.product);

  useEffect(() => { setCurrentProducts(products); }, [products]);

  const handleAddUpdateProducts = async () => {
    // Gran supuesto de que el punto es el separador de miles
    const newUpdateProducts = productsChanges
      .filter((item) => (item.update || item.new))
      .map((item) => ({
        name: item.name.replace("'", ''),
        sku: item.sku,
        ean: item.ean,
        category: item.category,
        cost: item.cost,
        price: item.price,
        initialStock: item.initialStock,
        unitMeasure: item.unitMeasure,
      }));

    try {
      const res = await postProductsFromCSV({ clientId, newUpdateProducts });
      handleApiResponse(enqueueSnackbar, res, true);
      dispatch(resetProductsChange());
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleSearchValueChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, searchValue: e.target.value }));
  };

  const handleSelectAllProducts = (e) => {
    setSelectedProducts(e.target.checked
      ? currentProducts.map((product) => product.id)
      : []);
  };

  const handleSelectOneProduct = (e, productId) => {
    if (!selectedProducts.includes(productId)) {
      setSelectedProducts((prevSelected) => [...prevSelected, productId]);
    } else {
      setSelectedProducts((prevSelected) => prevSelected.filter((id) => id !== productId));
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    setFilters((prevVal) => ({ ...prevVal, page: newPage }));
  };

  const handlePageChangeCSV = (e, newPage) => {
    setPageCSV(newPage);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, limit: e.target.value }));
  };

  const handleLimitChangeCSV = (e) => {
    setLimitCSV(e.target.value);
  };

  const enableBulkOperations = selectedProducts.length > 0;
  const selectedSomeProducts = selectedProducts.length > 0
    && selectedProducts.length < currentProducts.length;
  const selectedAllProducts = selectedProducts.length === currentProducts.length;

  useEffect(() => {
    if (productsToAddDB) {
      setProductsChanges(productsToAddDB);
    } else {
      setProductsChanges(null);
    }
  }, [productsToAddDB]);

  // maneja paginacion de productos a cambiar pero que estan en el front
  useEffect(() => {
    if (productsChanges) {
      setInitialIndex(pageCSV * limitCSV);
      if (productsChanges.length !== (limitCSV * (pageCSV + 1))) {
        setFinalIndex(limitCSV * (pageCSV + 1));
      } else {
        setFinalIndex(productsChanges.length);
      }
    }
  }, [productsChanges, pageCSV, limitCSV]);

  const deleteSelectedProducts = async () => {
    try {
      const response = await deleteProducts(selectedProducts);
      handleApiResponse(enqueueSnackbar, response, true);
      setCurrentProducts(currentProducts.filter((e) => (!selectedProducts.includes(e.id))));
      setSelectedProducts([]);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleGoToEdit = (productId) => {
    dispatch(saveFilters(filters));
    history.push(`/productos/${productId}/editar`);
  };

  return (
    <div>
      <Card>
        <Box
          p={2}
          minHeight={56}
          display="flex"
          alignItems="center"
        >
          <Grid container spacing={1}>
            {role === SUPER_ADMIN
              && <Grid item md={3} xs={12}>
                <CustomAutocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disablePortal
                  id="combo-box-demo"
                  options={clients}
                  value={selectedClient}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, value) => {
                    if (value === null) {
                      setSelectedClient({
                        id: clients[0].id,
                        name: clients[0].name,
                      });
                      return;
                    }
                    setSelectedClient({
                      id: value.id,
                      name: value.name,
                    });
                    setFilters((prevVal) => ({
                      ...prevVal,
                      selectedClient: {
                        id: value.id,
                        name: value.name,
                      },
                    }));
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      label="Buscar cliente"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            }
            <Grid item md={3} xs={12}>
              <CustomTextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearchValueChange}
                placeholder="Buscar producto, sku o ean"
                value={searchValue}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <br />
      <TableContainer component={Card}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedAllProducts}
                  indeterminate={selectedSomeProducts}
                  onChange={handleSelectAllProducts}
                />
              </TableCell>
              <TableCell>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    Nombre
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {enableBulkOperations && (
                    <div className={classes.bulkActions}>
                      <Button
                        variant="outlined"
                        className={classes.bulkAction}
                        onClick={() => deleteSelectedProducts()}
                      >
                        Eliminar
                      </Button>
                    </div>
                  )}
                </Grid>
              </TableCell>
              <TableCell>
                SKU
              </TableCell>
              <TableCell>
                EAN
              </TableCell>
              <TableCell>
                Categoría
              </TableCell>
              <TableCell>
                Precio
              </TableCell>
              <TableCell>
                Costo
              </TableCell>
              <TableCell>
                Unidad de Medida
              </TableCell>
              {!productsChanges && (
                <TableCell align="right">
                  <TablePagination
                    component="div"
                    count={count}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={count <= 0 ? 0 : page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage="Filas por página"
                  />
                </TableCell>
              )}
              {productsChanges && (
                <TableCell align="right">
                  <TablePagination
                    component="div"
                    count={productsChanges.length}
                    onPageChange={handlePageChangeCSV}
                    onRowsPerPageChange={handleLimitChangeCSV}
                    page={productsChanges.length <= 0 ? 0 : pageCSV}
                    rowsPerPage={limitCSV}
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage="Filas por página"
                  />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              productsChanges
              && productsChanges.slice(initalIndex, finalIndex).map((product, index) => {
                const isPriceProductSelected = selectedProducts.includes(product.id);
                let relevantClass = null;
                if (product.new) relevantClass = 'addProduct';
                else if (product.update) relevantClass = 'updateProduct';
                return (
                  <TableRow
                    hover
                    key={index}
                    selected={isPriceProductSelected}
                    className={classes[relevantClass]}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isPriceProductSelected}
                        onChange={(e) => handleSelectOneProduct(e, product.id)}
                        value={isPriceProductSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {product.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {product.sku}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {product.ean}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {product.category}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {`$ ${thousandSeparator(product.price, '.')}`}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {`$ ${thousandSeparator(product.cost, '.')}`}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {LABELED_UNITS[product.unitMeasure]}
                    </TableCell>
                    <TableCell />
                  </TableRow>);
              })}
            {(!productsChanges && currentProducts && currentProducts.map((product, index) => {
              const isProductSelected = selectedProducts.includes(product.id);
              return (
                <TableRow
                  hover
                  key={index}
                  selected={isProductSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isProductSelected}
                      onChange={(e) => handleSelectOneProduct(e, product.id)}
                      value={isProductSelected}
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <div>
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {product.name}
                        </Typography>
                      </div>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {product.sku}
                  </TableCell>
                  <TableCell>
                    {product.ean}
                  </TableCell>
                  <TableCell>
                    {product.category}
                  </TableCell>
                  <TableCell>
                    {`$ ${thousandSeparator(product.price, '.')}`}
                  </TableCell>
                  <TableCell>
                    {`$ ${thousandSeparator(product.cost, '.')}`}
                  </TableCell>
                  <TableCell>
                    {LABELED_UNITS[product.unitMeasure]}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      component={Button}
                      onClick={() => handleGoToEdit(product.id)}
                    >
                      <SvgIcon fontSize="small">
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            }))}
            {!currentProducts && <TableRow>
              <TableCell align="center" colSpan="6">
                No hay productos para mostrar
              </TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      {productsChanges
        && (
          <Box
            display='flex'
            justifyContent='flex-end'
            mr={3}
            mt={3}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddUpdateProducts}
            >
              Guardar desde CSV
            </Button>
          </Box>
        )}
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  products: PropTypes.array,
};

Results.defaultProps = {
  products: [],
};

export default Results;
