import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import thousandSeparator from '../../../utils/thousandSeparator';

function OrderTable({
  order,
  role,
}) {
  const fixedOrderDate = order.createdAt.substring(0, 10).split('-').reverse().join('/');

  return (
<Box>
  {role === SUPER_ADMIN
    && <Box>
       <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item>
            <Typography
            variant="h4"
            color="textPrimary"
            >
              Cliente:
            </Typography>
          </Grid>
          <Grid item>
          <Typography
          variant="h4"
          color="textSecondary"
          >
            {order.user.client.name}
          </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
      </Box>}
    <Box mt={4}>
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item>
            <Typography
            variant="h4"
            color="textPrimary"
            >
              Estado del pedido:
            </Typography>
          </Grid>
          <Grid item>
          <Typography
          variant="h4"
          color="textSecondary"
          >
            {order.status.toUpperCase()}
          </Typography>
          </Grid>
          <Grid item>
            <Typography
            variant="h4"
            color="textPrimary"
            >
              Total de la compra:
            </Typography>
          </Grid>
          <Grid item>
          <Typography
          variant="h4"
          color="textSecondary"
          >
            ${' '}{thousandSeparator(order.totalAmount, '.')}
          </Typography>
          </Grid>
          <Grid item>
            <Typography
            variant="h4"
            color="textPrimary"
            >
              Fecha:
            </Typography>
          </Grid>
          <Grid item>
          <Typography
          variant="h4"
          color="textSecondary"
          >
            {fixedOrderDate}
          </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </Box>
</Box>
  );
}
OrderTable.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object,
};

OrderTable.defaultProps = {
  order: {},
};

export default OrderTable;
