import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  autocomplete: {
    '& .MuiInputBase-input': {
      height: '23px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#006E90',
    },
    '& .MuiInputLabel-root': {
      color: '#006E90',
    },
  },
}));

export default useStyles;
