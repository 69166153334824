import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import clientRequest from '../../../requests/api/client';
import useStyles from './styles';
import PriceListCreateForm from './PriceListCreateForm';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';

function PriceListCreateView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((s) => s.account);
  const page = '';
  const limit = '';
  const searchValue = '';
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest
          .getClientsPerPage(page, limit, searchValue, selectedClient.id);
        setClients(response.clients);
        if (!selectedClient.id) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (account.user.role === SUPER_ADMIN) getClients();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Lista de Precios | Agregar lista de precios"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
        <PriceListCreateForm
        role={account.user.role}
        clients={clients}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        />
        </Box>
      </Container>
    </Page>
  );
}

export default PriceListCreateView;
