import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import CancelButton from '../../../../components/Buttons/Cancel/CancelButton';

import useStyles from './styles';

function Header({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.rootHeader, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
    <Grid container spacing= {1}>
      <Grid item>
        <Typography
          variant="h3"
          color="textSecondary"
          >
          Agregar
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Usuario
        </Typography>
    </Grid>
    </Grid>
  </Grid>
      <Grid item>
        <CancelButton
          component={RouterLink}
          to="/administracion/usuarios"
        >
          Cancelar
        </CancelButton>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
