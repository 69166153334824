import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import CustomTextField from '../../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../../components/Inputs/Autocomplete/CustomAutocomplete';
import { SUPER_ADMIN, CLIENT_ADMIN, CLIENT_SELLER } from '../../../../utils/const/user_types';
import useStyles from './styles';
import { postRegister } from '../../../../requests/api/auth';
import handleApiResponse from '../../../../utils/handleApiResponse';

function UserCreateForm({
  className,
  stores,
  user,
  clients,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/usuarios" />;
  }

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        lastname: '',
        role: '',
        clientId: user.role === SUPER_ADMIN ? '' : user.clientId,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        lastname: Yup.string().max(255).required('Debe ingresar un apellido'),
        email: Yup.string().email('Email inválido').required('Debe ingresar email'),
        role: Yup.string().required('Debe seleccionar un rol'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            name: values.name,
            lastname: values.lastname,
            email: values.email,
            role: values.role,
            clientId: values.clientId,
          };

          const response = await postRegister(data);

          handleApiResponse(enqueueSnackbar, response, true);
          setStatus({ success: true });
          setSubmitting(false);
          setSubmitSuccess(true);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/administracion/usuarios">Volver a usuarios</Button>);
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldValue,
        values,
      }) => (
        <form
          className={clsx(classes.rootCreateForm, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label="Apellido"
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastname}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    fullWidth
                    label="Rol"
                    name="role"
                    onChange={handleChange}
                    select
                    value={values.role}
                    variant="outlined"
                    required
                  >
                    {user.role === SUPER_ADMIN
                    && <MenuItem
                    value={SUPER_ADMIN}
                  >
                    Super Admin
                  </MenuItem>
                  }
                    <MenuItem
                      value={CLIENT_ADMIN}
                    >
                      Admin
                    </MenuItem>
                    <MenuItem
                      value={CLIENT_SELLER}
                    >
                      Vendedor
                    </MenuItem>
                  </CustomTextField>
                </Grid>
                {(user.role === SUPER_ADMIN
                && (values.role === CLIENT_ADMIN || values.role === CLIENT_SELLER))
                && <Grid
                item
                md={6}
                xs={12}
                >
                 <CustomAutocomplete
                 disablePortal
                 isOptionEqualToValue={(option, value) => option.id === value.id}
                 noOptionsText='Sin clientes para mostrar'
                 id="combo-box-demo"
                 placeholder="Cliente"
                 fullWidth
                 options={clients}
                 getOptionLabel={(option) => `${option.name}`}
                 onChange={(e, value) => {
                   if (value === null) {
                     setFieldValue('clientId', '');
                     return;
                   }
                   setFieldValue('clientId', value.id);
                 }}
                 renderInput={(params) => (
                     <CustomTextField
                     {...params}
                     label="Cliente"
                     variant="outlined"
                   />
                 )}
                 />
               </Grid>
               }
              </Grid>
              <Box mt={2} display='flex' justifyContent='flex-end'>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

UserCreateForm.propTypes = {
  className: PropTypes.string,
  stores: PropTypes.array,
};

export default UserCreateForm;
