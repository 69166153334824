import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import SplashScreen from '../../../components/Loading/SplashScreen';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getCostsPerPage } from '../../../requests/api/fixedCosts';
import clientRequest from '../../../requests/api/client';
import storeRequest from '../../../requests/api/store';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';

function FixedCostsListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [costs, setCosts] = useState([]);
  const [costsPage, setCostsPage] = useState(0);
  const [costsLimit, setCostsLimit] = useState(5);
  const [count, setCount] = useState(null);
  const [costsSearchValue, setCostsSearchValue] = useState('');
  const [clients, setClients] = useState([]);
  const [stores, setStores] = useState([]);
  const account = useSelector((s) => s.account);
  const [selectedClient, setSelectedClient] = useState({
    id: account.user.role === SUPER_ADMIN ? null : account.user.clientId,
    name: null,
  });
  const [selectedStore, setSelectedStore] = useState({
    id: null,
    name: null,
  });
  const [totalCost, setTotalCost] = useState(0);
  const [filterDate, setFilterDate] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });

  const page = '';
  const limit = '';
  const searchValue = '';

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest.getClientsPerPage(page, limit, searchValue);
        setClients(response.clients);
        if (!selectedClient.id) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    const getStores = async () => {
      try {
        const response = await storeRequest.getStoresPerPage(
          page,
          limit,
          searchValue,
          selectedClient.id,
        );
        if (!response.success) {
          setStores([{
            id: null,
            name: 'Sin tiendas asociadas',
          }]);
          setSelectedStore({
            id: null,
            name: 'Sin tiendas asociadas',
          });
          return;
        }
        setStores(response.allStores);
        setSelectedStore({
          id: response.allStores[0].id,
          name: response.allStores[0].name,
        });
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    const getCosts = async () => {
      try {
        const response = await getCostsPerPage(
          costsPage,
          costsLimit,
          costsSearchValue,
          setSelectedStore.id,
          selectedClient.id,
          filterDate.startDate,
          filterDate.endDate,
        );
        if (response.success) {
          const costsToSum = response.costs
            .map((c) => c.cost)
            .reduce((prevVal, currVal) => prevVal + currVal, 0);
          setTotalCost(costsToSum);
        }
        setCosts(response.costs);
        setCount(response.totalCosts);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    if (account.user.role === SUPER_ADMIN) getClients();
    if (selectedClient.id) {
      getCosts();
      getStores();
    }
  }, [selectedClient, costsLimit, costsPage, costsSearchValue, filterDate]);

  if (!selectedClient.id) return <SplashScreen />;
  if (stores[0] && (stores[0].id && !selectedStore.id)) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Costos Fijos"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results
            costs={costs}
            costsPage={costsPage}
            setCostsPage={setCostsPage}
            costsLimit={costsLimit}
            setCostsLimit={setCostsLimit}
            costsSearchValue={costsSearchValue}
            setCostsSearchValue={setCostsSearchValue}
            count={count || 0}
            role={account.user.role}
            clients={clients}
            stores={stores}
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
            setSelectedStore={setSelectedStore}
            selectedStore={selectedStore}
            totalCost={totalCost}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default FixedCostsListView;
