import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import {
  Box,
  Card,
  Grid,
  MenuItem,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import EmptyChart from './EmptyChart';

dayjs.extend(isBetweenPlugin);
highchartsMore(Highcharts);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({
  theme, dayIsBetween, isFirstDay, isLastDay,
}) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

export default function Results({
  incomes,
  stores,
  selectedClient,
  setSelectedClient,
  selectedStore,
  setSelectedStore,
  filterDate,
  setFilterDate,
  setCostsDate,
  costs,
  clients,
  role,
  SUPER_ADMIN,
}) {
  const [costsToShow, setCostsToShow] = useState({
    fixed: 0,
    variable: 0,
  });
  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Ingresos y Costos',
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    xAxis: {
      categories: ['Costos', 'Ingresos'],
      crosshair: true,
    },
    yAxis: {
      title: {
        text: '$',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    series: [
      {
        name: 'Ingresos Pendientes',
        data: [0, incomes.pending],
        color: '#f18e0070',
      }, {
        name: 'Ingresos Recibidos',
        data: [0, incomes.received],
        color: '#f18e00',
      },
      {
        name: 'Costos Variables',
        data: [Math.round(costsToShow.variable), 0],
        color: '#006e9070',
      }, {
        name: 'Costos Fijos',
        data: [Math.round(costsToShow.fixed), 0],
        color: '#006e90',
      },
    ],
  };

  const WEEKLY = 'weekly';
  const DAILY = 'daily';
  const MONTHLY = 'monthly';
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const daysOfTheMonth = parseInt(new Date(today
    .getFullYear(), today.getMonth() + 1, 0)
    .getDate(), 10);

  const [dateFilterType, setFilterDateType] = useState(MONTHLY);

  function handleDateFilterType(e) {
    e.preventDefault();
    setFilterDateType(e.target.value);
  }

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    const selectedDate = dayjs(selectedDates);
    const start = selectedDate.startOf('week');
    const end = selectedDate.endOf('week');

    const dayIsBetween = dayjs(date).isBetween(start, end, null, '[]');
    const isFirstDay = dayjs(date).isSame(start, 'day');
    const isLastDay = dayjs(date).isSame(end, 'day');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  useEffect(() => {
    if (dateFilterType === DAILY) {
      setFilterDate({
        startDate: today,
        endDate: tomorrow,
      });
      setCostsDate({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      });
    }
    if (dateFilterType === WEEKLY) {
      setFilterDate({
        startDate: dayjs(new Date()).startOf('week').$d,
        endDate: dayjs(new Date()).endOf('week').$d,
      });
      setCostsDate({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      });
    }
    if (dateFilterType === MONTHLY) {
      setFilterDate({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      });
      setCostsDate({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      });
    }
  }, [dateFilterType]);

  useEffect(() => {
    if (dateFilterType === DAILY) {
      setCostsToShow({
        fixed: costs.fixedCosts / daysOfTheMonth,
        variable: costs.variableCosts,
      });
    }
    if (dateFilterType === WEEKLY) {
      setCostsToShow({
        fixed: (costs.fixedCosts / daysOfTheMonth) * 7,
        variable: costs.variableCosts,
      });
    }
    if (dateFilterType === MONTHLY) {
      setCostsToShow({
        fixed: costs.fixedCosts,
        variable: costs.variableCosts,
      });
    }
  }, [costs]);

  return (
<div>
  <Card>
    <Box
      p={2}
      minHeight={56}
      display="flex"
      alignItems="center"
    >
      <Grid container spacing={1}>
        {role === SUPER_ADMIN
          && (
            <Grid item md={3} xs={12}>
              <CustomAutocomplete
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disablePortal
                noOptionsText='Sin clientes para mostrar'
                id="combo-box-demo"
                placeholder="Buscar cliente"
                options={clients}
                value={selectedClient}
                getOptionLabel={(option) => `${option.name}`}
                onChange={(e, value) => {
                  if (value === null) return;
                  setSelectedClient({
                    id: value.id,
                    name: value.name,
                  });
                  setSelectedStore({
                    id: null,
                    name: null,
                  });
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="Buscar cliente"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
        <Grid item md={3} xs={12}>
          <CustomAutocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disablePortal
            noOptionsText='Sin tiendas para mostrar'
            id="combo-box-demo"
            placeholder="Buscar tienda"
            options={stores}
            value={selectedStore}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(e, value) => {
              if (value === null) return;
              setSelectedStore({
                id: value.id,
                name: value.name,
              });
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label="Buscar tienda"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField
            fullWidth
            label="Filtro de fecha"
            name="dateFilter"
            onChange={handleDateFilterType}
            select
            value={dateFilterType}
            variant="outlined"
          >
              <MenuItem value={WEEKLY}>
                Semanal
              </MenuItem>
              <MenuItem value={MONTHLY}>
                Mensual
              </MenuItem>
              <MenuItem value={DAILY}>
                Diario
              </MenuItem>
          </CustomTextField>
        </Grid>
        {dateFilterType === DAILY
        && <Grid item md={3} xs={12}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
          >
            <DatePicker
              views={['day']}
              value={filterDate.startDate}
              maxDate={dayjs(new Date())}
              onChange={(value) => {
                if (value === null) {
                  setFilterDate({
                    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                  });
                  return;
                }
                const dayAfter = new Date(value);
                dayAfter.setDate(dayAfter.getDate() + 1);
                setFilterDate({
                  startDate: value,
                  endDate: dayAfter,
                });
                setCostsDate({
                  startDate: dayjs(value).startOf('month').$d,
                  endDate: dayjs(value).endOf('month').$d,
                });
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant='outlined'
                  fullWidth
                  label="Seleccione el día"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>}
        {dateFilterType === WEEKLY
        && (
          <Grid item md={3} xs={12}>
            <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                label="Seleccione la semana"
                value={filterDate.startDate}
                maxDate={dayjs(new Date())}
                onChange={(value) => {
                  if (value === null) return;
                  setFilterDate({
                    startDate: dayjs(value).startOf('week').$d,
                    endDate: dayjs(value).endOf('week').$d,
                  });
                  setCostsDate({
                    startDate: dayjs(value).startOf('month').$d,
                    endDate: dayjs(value).endOf('month').$d,
                  });
                }}
                renderDay={renderWeekPickerDay}
                renderInput={(params) => <CustomTextField {...params} />}
                inputFormat="'Week of' MMM d"
              />
            </LocalizationProvider>
          </Grid>
        )}
        {dateFilterType === MONTHLY
        && (
          <Grid item md={3} xs={12}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
          >
            <DatePicker
              views={['year', 'month']}
              openTo='year'
              label="Seleccione el mes"
              value={filterDate.startDate}
              maxDate={dayjs(new Date())}
              onChange={(value) => {
                if (value === null) return;
                // set start and end date with first
                // and last day of the selected month (respectively)
                setFilterDate({
                  startDate: new Date(new Date(value)
                    .getFullYear(), new Date(value).getMonth(), 1),
                  endDate: new Date(new Date(value)
                    .getFullYear(), new Date(value).getMonth() + 1, 0),
                });
                setCostsDate({
                  startDate: dayjs(value).startOf('month').$d,
                  endDate: dayjs(value).endOf('month').$d,
                });
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant='outlined'
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>)}
      </Grid>
    </Box>
  </Card>
  <br></br>
  {(selectedClient.id && (
    (!selectedStore.id && selectedStore.name !== 'Todas')
    || (
      !incomes.pending
      && !incomes.received
      && !costs.fixedCosts
    )
  )) ? <EmptyChart />
    : <HighchartsReact highcharts={Highcharts} options={options} />
  }
</div>
  );
}
