import * as React from 'react';
import { Autocomplete } from '@mui/material';
import useStyles from './styles';

export default function CustomAutocomplete(props) {
  const classes = useStyles();
  return (
        <Autocomplete
        className={classes.autocomplete}
        {...props}
        />
  );
}
