import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ProductCreateForm from './ProductCreateForm';
import handleApiResponse from '../../../utils/handleApiResponse';
import clientRequest from '../../../requests/api/client';

import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';

function ProductCreateView() {
  const classes = useStyles();
  const account = useSelector((s) => s.account);
  const { enqueueSnackbar } = useSnackbar();
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState(
    account.user.role === SUPER_ADMIN ? null : account.user.clientId,
  );
  const [limit] = useState(50);
  const [page] = useState(0);
  const [searchValue] = useState('');

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) setClientId(account.user.clientId);
    const getClients = async () => {
      try {
        const response = await clientRequest
          .getClientsPerPage(page, limit, searchValue, clientId);
        setClients(response.clients);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (account.user.role === SUPER_ADMIN) getClients();
  }, [limit, page, searchValue, clientId]);

  return (
    <Page
      className={classes.root}
      title="Productos | Agregar Producto"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <ProductCreateForm
            clients={clients}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default ProductCreateView;
