import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import thousandSeparator from '../../../utils/thousandSeparator';

function OrderProductsTable({
  order,
  page,
  setPage,
  limit,
  setLimit,
}) {
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  return (
  <Card>
    <CardContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='h4'>
              Producto:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h4'>
              Cantidad:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h4'>
              Precio unitario:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h4'>
              Subtotal:
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.orderProducts.length !== 0 && order.orderProducts.map((p) => (
              <TableRow key={p.product.id}>
                <TableCell>
                  <Typography
                  variant='h5'
                  >
                    {p.product.name.toUpperCase()}
                  </Typography>
                  <Typography
                  variant='h6'
                  >
                    sku: {p.product.sku}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                  variant='h5'
                  >
                    {p.quantity}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                  variant='h5'
                  >
                    $ {thousandSeparator(p.unitPrice, '.')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                  variant='h5'
                  >
                    $ {thousandSeparator(p.totalPrice, '.')}
                  </Typography>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={order.orderProducts && order.orderProducts.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </CardContent>
  </Card>
  );
}

OrderProductsTable.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  order: PropTypes.object,
};

OrderProductsTable.defaultProps = {
  order: {},
};

export default OrderProductsTable;
