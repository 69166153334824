import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  X as ClearIcon,
} from 'react-feather';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useSnackbar } from 'notistack';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import { deleteCosts } from '../../../requests/api/fixedCosts';
import handleApiResponse from '../../../utils/handleApiResponse';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import useStyles from './styles';
import { EXPENSES } from '../../../utils/const/costTypes';
import thousandSeparator from '../../../utils/thousandSeparator';

function Results({
  costs,
  count,
  costsPage,
  setCostsPage,
  costsLimit,
  setCostsLimit,
  role,
  clients,
  setSelectedClient,
  selectedClient,
  stores,
  selectedStore,
  setSelectedStore,
  totalCost,
  filterDate,
  setFilterDate,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedCosts, setSelectedCosts] = useState([]);
  const [currentCosts, setCurrentCosts] = useState(costs);

  useEffect(() => {
    setCurrentCosts(costs);
  }, [costs]);

  const handleSelectAllCosts = (e) => {
    setSelectedCosts(e.target.checked
      ? currentCosts.map((cost) => cost.id)
      : []);
  };

  const handleSelectOneCost = (e, costId) => {
    if (!selectedCosts.includes(costId)) {
      setSelectedCosts((prevSelected) => [...prevSelected, costId]);
    } else {
      setSelectedCosts((prevSelected) => prevSelected.filter((id) => id !== costId));
    }
  };

  const handlePageChange = (e, newPage) => {
    setCostsPage(newPage);
  };

  const handleLimitChange = (e) => {
    setCostsLimit(e.target.value);
  };

  const enableBulkOperations = selectedCosts.length > 0;
  const selectedSomeCosts = selectedCosts.length > 0
  && selectedCosts.length < currentCosts.length;
  const selectedAllCosts = selectedCosts.length === currentCosts.length;

  const deleteSelectedCosts = async () => {
    try {
      const response = await deleteCosts(selectedCosts);
      handleApiResponse(enqueueSnackbar, response, true);
      setCurrentCosts(currentCosts.filter((e) => (!selectedCosts.includes(e.id))));
      setSelectedCosts([]);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  return (
<div>
  <Card>
  <Box
    p={2}
    minHeight={56}
    display="flex"
    alignItems="center"
  >
  <Grid container spacing={1}>
      {role === SUPER_ADMIN
    && <Grid item md={2} xs={12}>
      <CustomAutocomplete
    isOptionEqualToValue={(option, value) => option.id === value.id}
    disablePortal
    noOptionsText='Sin clientes para mostrar'
    id="combo-box-demo"
    placeholder="Buscar cliente"
    options={clients}
    value={selectedClient}
    getOptionLabel={(option) => `${option.name}`}
    onChange={(e, value) => {
      if (value === null) return;
      setSelectedClient({
        id: value.id,
        name: value.name,
      });
      setSelectedStore({
        id: null,
        name: null,
      });
    }}
    renderInput={(params) => (
      <CustomTextField
      {...params}
      label="Buscar cliente"
      variant="outlined"
      />
    )}
     />
  </Grid>
    }
    <Grid item md={3} xs={12}>
      <CustomAutocomplete
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disablePortal
        noOptionsText='Sin tiendas para mostrar'
        id="combo-box-demo"
        placeholder="Buscar tienda"
        options={stores}
        value={selectedStore}
        getOptionLabel={(option) => `${option.name}`}
        onChange={(e, value) => {
          if (value === null) return;
          setSelectedStore({
            id: value.id,
            name: value.name,
          });
        }}
        renderInput={(params) => (
      <CustomTextField
      {...params}
      label="Buscar tienda"
      variant="outlined"
      />
        )}
      />
    </Grid>
    <Grid item md={3} xs={12}>
      <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
        <DatePicker
          views={['day', 'month', 'year']}
          label="Fecha de inicio"
          value={filterDate.startDate}
          onChange={(value) => {
            if (value === null) {
              setFilterDate((prevVal) => ({
                ...prevVal,
                startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
              }));
              return;
            }
            setFilterDate((prevVal) => ({
              ...prevVal,
              startDate: value,
            }));
          }}
          renderInput={(params) => (
          <CustomTextField
          {...params}
          variant='outlined'
          fullWidth
          />
          )}
        />
      </LocalizationProvider>
    </Grid>
    <Tooltip title='Limpiar fechas'>
      <IconButton
        component={Button}
        onClick={() => {
          setFilterDate({
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
          });
        }}
      >
        <SvgIcon fontSize="small">
          <ClearIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
    </Grid>
  </Box>
  </Card>
  <br/>
  <TableContainer component={Card}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedAllCosts}
            indeterminate={selectedSomeCosts}
            onChange={handleSelectAllCosts}
          />
        </TableCell>
        <TableCell width='15%'>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              Nombre
            </Grid>
          </Grid>
          <Grid item xs={6}>
          {enableBulkOperations && (
            <div className={classes.bulkActions}>
              <Button
               variant="outlined"
               className={classes.bulkAction}
               onClick={() => deleteSelectedCosts()}
               >
                Eliminar
              </Button>
            </div>
          )}
          </Grid>
        </TableCell>
        <TableCell>
          Tienda
        </TableCell>
        <TableCell>
          Fecha de cobro
        </TableCell>
        <TableCell>
          Costo
        </TableCell>
        <TableCell
        align="right"
        width='35%'
        >
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={costsPage}
          rowsPerPage={costsLimit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por página"
        />
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {currentCosts.length ? currentCosts.map((cost) => {
        const isCostSelected = selectedCosts.includes(cost.id);
        return (
          <TableRow
            hover
            key={cost.id}
            selected={isCostSelected}
          >
            <TableCell padding="checkbox">
              <Checkbox
                checked={isCostSelected}
                onChange={(e) => handleSelectOneCost(e, cost.id)}
                value={isCostSelected}
              />
            </TableCell>
            <TableCell>
              <Typography
                color="inherit"
                variant="h6"
              >
                {cost.type === EXPENSES ? 'GGCC' : cost.type.toUpperCase()}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="inherit"
                variant="h6"
              >
                {cost.store ? cost.store.name : 'Sin tienda asociada'}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="inherit"
                variant="h6"
              >
                {cost.date.toString().substring(0, 10).split('-').reverse()
                  .join('/')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="inherit"
                variant="h6"
              >
                ${' '}{thousandSeparator(cost.cost, '.')}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <IconButton
                component={RouterLink}
                to={`/costos-fijos/${cost.id}/editar`}
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })
        : <TableRow>
        <TableCell align="center" colSpan="6">
            No hay costos fijos para mostrar
        </TableCell>
      </TableRow>}
      <TableRow>
        <TableCell colSpan='6'>
        <Grid container spacing={1}>
          <Grid item>
          <Typography variant='h5' color='textSecondary'>
          Costo total:
        </Typography>
          </Grid>
          <Grid item>
        <Typography variant ='h5'>
          ${' '}{thousandSeparator(totalCost, '.')}
        </Typography>
          </Grid>
        </Grid>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
  </TableContainer>
</div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  costsSearchValue: PropTypes.string.isRequired,
  setCostsSearchValue: PropTypes.func.isRequired,
  costsLimit: PropTypes.number.isRequired,
  setCostsLimit: PropTypes.func.isRequired,
  costsPage: PropTypes.number.isRequired,
  setCostsPage: PropTypes.func.isRequired,
  costs: PropTypes.array,
  clients: PropTypes.array,
  stores: PropTypes.array,
};

Results.defaultProps = {
  costs: [],
};

export default Results;
