import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import storeRequest from '../../../requests/api/store';
import { setStoreCreationPage, setStoreDetails } from '../../../actions/storeActions';
import handleApiResponse from '../../../utils/handleApiResponse';

import useStyles from './styles';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';

function SellersAssociation({
  users, totalUsers, page, limit, setPage, setLimit, setSearchValue,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentUsers, setCurrentUsers] = useState(users);
  const [selectedUserName, setSelectedUserName] = useState('');
  const dispatch = useDispatch();
  const { storeInformation } = useSelector((state) => state.store);

  const handleSelectAllStores = (event) => {
    if (event.target.checked) {
      const allUsers = currentUsers.map((user) => user.id);
      setSelectedUsers(allUsers);
      dispatch(setStoreDetails({ userIds: allUsers }));
      return;
    }
    setSelectedUsers([]);
    dispatch(setStoreDetails({ userIds: [] }));
  };

  const handleSelectOneUser = (event, userId) => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelected) => [...prevSelected, userId]);
      dispatch(setStoreDetails({ userIds: [...selectedUsers, userId] }));
    } else {
      setSelectedUsers((prevSelected) => prevSelected.filter((id) => id !== userId));
      dispatch(setStoreDetails({ userIds: selectedUsers.filter((id) => id !== userId) }));
    }
  };

  const selectedSomeStores = selectedUsers.length > 0
    && selectedUsers.length < currentUsers.length;
  const selectedAllStores = selectedUsers.length === currentUsers.length;

  const handleSearchValue = (e, newValue) => {
    if (newValue !== '') { setSearchValue(selectedUserName); }
  };

  const handleNextStep = async () => {
    if (selectedUsers.length === 0) {
      enqueueSnackbar('Debes seleccionar por lo menos un usuario', {
        variant: 'error',
      });
      return;
    }

    try {
      const response = await storeRequest.postCreateStore({
        ...storeInformation,
        userIds: selectedUsers,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      history.push('/administracion/tiendas');
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    setCurrentUsers(users);
    setSelectedUsers(storeInformation.userIds || []);
  }, [users]);
  return (
    <Box>
      <Paper
        elevation={3}
      >
        <Box
          display='flex'
          alignItems='center'
          padding='15px 10px'
          justifyContent='space-between'
        >
          <Box
            flex={1}
            mr={4}
          >
            <CustomAutocomplete
              disablePortal
              isOptionEqualToValue={(option, value) => option.name === value.name}
              id="combo-box-demo"
              fullWidth
              noOptionsText='Sin usuarios para mostrar'
              options={users}
              filterSelectedOptions
              getOptionLabel={(option) => `${option.name}`}
              onChange={(e, newValue) => {
                if (!newValue) setSearchValue('');
                else setSelectedUserName(newValue.name);
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Buscar usuarios"
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSearchValue}
            >
              Buscar
            </Button>
          </Box>
        </Box>
      </Paper>
      <Box mt={3}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllStores}
                    indeterminate={selectedSomeStores}
                    onChange={handleSelectAllStores}
                  />
                </TableCell>
                <TableCell>
                  Tienda
                </TableCell>
                <TableCell>
                  Dirección
                </TableCell>
                <TableCell>
                  Ciudad
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.length !== 0 && users.map((user) => {
                const isUserSelected = selectedUsers.includes(user.id);
                return (
                  <TableRow
                    key={user.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isUserSelected}
                        onChange={(event) => handleSelectOneUser(event, user.id)}
                        value={isUserSelected}
                      />
                    </TableCell>
                    <TableCell>
                      {user.name}
                    </TableCell>
                    <TableCell>
                      {user.address}
                    </TableCell>
                    <TableCell>
                      {user.city}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box
            mt={2}
            className={classes.tablePagination}
          >
            <TablePagination
              component="div"
              count={totalUsers}
              onPageChange={(e, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value);
                setPage(0);
              }}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Filas por página"
            />
            <Box display='flex'>
              <Box
                mr={1}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => dispatch(setStoreCreationPage(0))}
                >
                  Atrás
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.orangeButton}
                  onClick={handleNextStep}
                >
                  Crear Tienda
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box >

  );
}

SellersAssociation.propTypes = {
  className: PropTypes.string,
};

export default SellersAssociation;
