import * as React from 'react';
import { TextField } from '@material-ui/core';
import useStyles from './styles';

export default function CustomTextField(props) {
  const classes = useStyles();
  return (
        <TextField
        className={classes.textField}
        {...props}
        />
  );
}
