import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import couponRequest from '../../../requests/api/coupon';
import handleApiResponse from '../../../utils/handleApiResponse';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import getInitials from '../../../utils/getInitials';
import useStyles from './styles';
import thousandSeparator from '../../../utils/thousandSeparator';

function Results({
  coupons,
  count,
  searchValue,
  setSearchValue,
  page,
  setPage,
  limit,
  setLimit,
  role,
  clients,
  setSelectedClient,
  selectedClient,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [currentCoupons, setCurrentCoupons] = useState(coupons);

  useEffect(() => {
    setCurrentCoupons(coupons);
  }, [coupons]);

  const handleSelectAllCoupons = (e) => {
    setSelectedCoupons(e.target.checked
      ? currentCoupons.map((coupon) => coupon.id)
      : []);
  };

  const handleSelectOneCoupon = (e, couponId) => {
    if (!selectedCoupons.includes(couponId)) {
      setSelectedCoupons((prevSelected) => [...prevSelected, couponId]);
    } else {
      setSelectedCoupons((prevSelected) => prevSelected.filter((id) => id !== couponId));
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  const enableBulkOperations = selectedCoupons.length > 0;
  const selectedSomeCoupons = selectedCoupons.length > 0
    && selectedCoupons.length < currentCoupons.length;
  const selectedAllCoupons = selectedCoupons.length === currentCoupons.length;

  const deleteSelectedCoupons = async () => {
    try {
      const response = await couponRequest.deleteCoupons(selectedCoupons);
      handleApiResponse(enqueueSnackbar, response, true);
      setCurrentCoupons(currentCoupons.filter((e) => (!selectedCoupons.includes(e.id))));
      setSelectedCoupons([]);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleSearchValueChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <Card>
        <Box
          p={2}
          minHeight={56}
          display="flex"
          alignItems="center"
        >
          <Grid container spacing={1}>
            {role === SUPER_ADMIN
              && <Grid item md={3} xs={12}>
                <CustomAutocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disablePortal
                  noOptionsText='Sin clientes para mostrar'
                  id="combo-box-demo"
                  placeholder="Buscar cliente"
                  options={clients}
                  value={selectedClient}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, value) => {
                    if (value === null) return;
                    setSelectedClient({
                      id: value.id,
                      name: value.name,
                    });
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      label="Buscar cliente"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            }
            <Grid item md={3} xs={12}>
              <CustomTextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearchValueChange}
                placeholder="Buscar cupón"
                value={searchValue}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <br />
      <TableContainer component={Card}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedAllCoupons}
                  indeterminate={selectedSomeCoupons}
                  onChange={handleSelectAllCoupons}
                />
              </TableCell>
              <TableCell>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    Cupón
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {enableBulkOperations && (
                    <div className={classes.bulkActions}>
                      <Button
                        variant="outlined"
                        className={classes.bulkAction}
                        onClick={() => deleteSelectedCoupons()}
                      >
                        Eliminar
                      </Button>
                    </div>
                  )}
                </Grid>
              </TableCell>
              <TableCell>
                Valor de descuento
              </TableCell>
              <TableCell align="right">
                <TablePagination
                  component="div"
                  count={count}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage="Filas por página"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentCoupons.length && currentCoupons.map((coupon) => {
              const isCouponSelected = selectedCoupons.includes(coupon.id);
              return (
                <TableRow
                  hover
                  key={coupon.id}
                  selected={isCouponSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isCouponSelected}
                      onChange={(e) => handleSelectOneCoupon(e, coupon.id)}
                      value={isCouponSelected}
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Avatar
                        className={classes.avatar}
                      >
                        {getInitials(coupon.name.toString())}
                      </Avatar>
                      <div>
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {coupon.name}
                        </Typography>
                        <Grid container spacing={0}>
                          {coupon.start && <Typography
                            variant="body2"
                            color="textPrimary"
                          >
                            {'Válido del '}{coupon.start.toString().substring(0, 10)}
                          </Typography>}
                          {coupon.end && <Typography
                            variant='body2'
                            color='textPrimary'
                          >
                            {' al '}{coupon.end.toString().substring(0, 10)}
                          </Typography>}
                        </Grid>
                      </div>
                    </Box>
                  </TableCell>
                  {coupon.type === 'percentage'
                    ? <TableCell>
                      {coupon.value * 100}{' '}{'%'}
                    </TableCell>
                    : <TableCell>
                      {'$'}{' '}{thousandSeparator(coupon.value || 0, '.')}
                    </TableCell>
                  }
                  <TableCell align="right">
                    <IconButton
                      component={RouterLink}
                      to={`/cupones/${coupon.id}/editar`}
                    >
                      <SvgIcon fontSize="small">
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  coupons: PropTypes.array,
};

Results.defaultProps = {
  coupons: [],
};

export default Results;
