import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import useStyles from './styles';
import { postNewProduct } from '../../../requests/api/products';
import handleApiResponse from '../../../utils/handleApiResponse';
import storeRequest from '../../../requests/api/store';
import {
  GRAMO,
  KILOGRAMO,
  LITRO,
  MILILITRO,
  UNIDAD,
} from '../../../constants/unitMeasure';

function ProductCreateForm({
  className,
  clients,
  ...rest
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [availableStores, setAvailableStores] = useState({ allStores: [], totalStores: 0 });
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const account = useSelector((state) => state.account);

  useEffect(() => {
    const getNumberStores = async () => {
      try {
        const response = await storeRequest.getStoresPerPage(
          0, // page
          1, // limit
          '', // searchedValue
          account.user.role === SUPER_ADMIN ? null : account.user.clientId, // clientId
        );
        setAvailableStores({ allStores: response.allStores, totalStores: response.totalStores });
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getNumberStores();
  }, []);

  if (submitSuccess) {
    return <Redirect to="/productos" />;
  }

  return (
    <Formik
      initialValues={{
        name: '',
        sku: '',
        ean: '',
        category: '',
        clientId: account.user.clientId,
        cost: '',
        price: '',
        initialStock: '',
        storeId: '',
        unitMeasure: UNIDAD,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        category: Yup.string().required('Debe seleccionar una categoría'),
        clientId: Yup.number().typeError('Debe ingresar un número').required('Debe ingresar el cliente'),
        cost: Yup.number().typeError('Debe ingresar un número').required('Debe ingresar el costo'),
        price: Yup.number().typeError('Debe ingresar un número').required('Debe ingresar el precio'),
        unitMeasure: Yup.string().required('Debe seleccionar una unidad de medida'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            name: values.name,
            sku: values.sku,
            ean: values.ean,
            category: values.category,
            clientId: values.clientId,
            cost: values.cost,
            price: values.price,
            initialStock: values.initialStock.trim(),
            storeId: availableStores.totalStores === 1 ? availableStores.allStores[0].id : '',
            unitMeasure: values.unitMeasure,
          };

          const response = await postNewProduct(data);

          handleApiResponse(enqueueSnackbar, response, true);
          setStatus({ success: true });
          setSubmitting(false);
          setSubmitSuccess(true);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/productos">Volver a Productos</Button>);
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.rootCreateForm, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    fullWidth
                    label="SKU"
                    name="sku"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.sku}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.cost && errors.cost)}
                    fullWidth
                    helperText={touched.cost && errors.cost}
                    label="Costo por unidad de medida"
                    name="cost"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.cost}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    fullWidth
                    label="EAN"
                    name="ean"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.ean}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.category && errors.category)}
                    fullWidth
                    helperText={touched.category && errors.category}
                    label="Categoría"
                    name="category"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.category}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.price && errors.price)}
                    fullWidth
                    helperText={touched.price && errors.price}
                    label="Precio por unidad de medida"
                    name="price"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.price}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomAutocomplete
                    fullWidth
                    disablePortal
                    id="combo-box-demo"
                    options={[UNIDAD, GRAMO, KILOGRAMO, MILILITRO, LITRO]}
                    onChange={(e, value) => {
                      if (value === null) return;
                      setFieldValue('unitMeasure', value);
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={Boolean(touched.unitMeasure && errors.unitMeasure)}
                        helperText={touched.unitMeasure && errors.unitMeasure}
                        label="Unidad de Medida"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.unitMeasure}
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Grid>
                {availableStores.totalStores === 1 && <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <CustomTextField
                    error={Boolean(touched.initialStock && errors.initialStock)}
                    fullWidth
                    helperText={touched.initialStock && errors.initialStock}
                    label="Stock Inicial"
                    name="initialStock"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.initialStock}
                    variant="outlined"
                  />
                </Grid>}
                {account.user.role === SUPER_ADMIN
                  && <Grid item md={6} xs={12}>
                    <CustomAutocomplete
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                      disablePortal
                      id="combo-box-demo"
                      options={clients}
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={(e, value) => {
                        if (value === null) return;
                        setFieldValue('clientId', value.id);
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          error={Boolean(touched.clientId && errors.clientId)}
                          helperText={touched.clientId && errors.clientId}
                          label="Cliente"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clientId}
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Grid>
                }
              </Grid>
              <Box
                mt={2}
                display='flex'
                justifyContent='flex-end'
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ProductCreateForm.propTypes = {
  className: PropTypes.string,
};

export default ProductCreateForm;
