import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import handleApiResponse from '../../../utils/handleApiResponse';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import { deletePriceLists } from '../../../requests/api/priceLists';
import { saveFilters } from '../../../actions/filterActions';

function Results({
  priceLists,
  count,
  searchValue,
  setSearchValue,
  page,
  setPage,
  limit,
  setLimit,
  role,
  clients,
  setSelectedClient,
  selectedClient,
  history,
  currentPriceLists,
  setCurrentPriceLists,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPriceLists, setSelectedPriceLists] = useState([]);
  const [filters, setFilters] = useState({
    page,
    limit,
    searchValue,
    selectedClient,
  });

  useEffect(() => { setCurrentPriceLists(priceLists); }, [priceLists]);

  const handleSearchValueChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, searchValue: e.target.value }));
  };

  const handleSelectAllPriceLists = (e) => {
    setSelectedPriceLists(e.target.checked
      ? currentPriceLists.map((priceList) => priceList.id)
      : []);
  };

  const handleSelectOnePriceList = (e, productId) => {
    if (!selectedPriceLists.includes(productId)) {
      setSelectedPriceLists((prevSelected) => [...prevSelected, productId]);
    } else {
      setSelectedPriceLists((prevSelected) => prevSelected.filter((id) => id !== productId));
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    setFilters((prevVal) => ({ ...prevVal, page: newPage }));
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, limit: e.target.value }));
  };

  const enableBulkOperations = selectedPriceLists.length > 0;
  const selectedSomePriceLists = selectedPriceLists.length > 0
    && selectedPriceLists.length < currentPriceLists.length;
  const selectedAllPriceLists = selectedPriceLists.length === currentPriceLists.length;

  const deleteSelectedPriceLists = async () => {
    try {
      const response = await deletePriceLists(selectedPriceLists);
      handleApiResponse(enqueueSnackbar, response, true);
      setCurrentPriceLists(currentPriceLists.filter((e) => (!selectedPriceLists.includes(e.id))));
      setSelectedPriceLists([]);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleGoToEdit = (priceId) => {
    dispatch(saveFilters(filters));
    history.push(`/precios/${priceId}/editar`);
  };

  return (
    <div>
      <Card>
        <Box
          p={2}
          minHeight={56}
          display="flex"
          alignItems="center"
        >
          <Grid container spacing={1}>
            {role === SUPER_ADMIN
              && <Grid item md={3} xs={12}>
                <CustomAutocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disablePortal
                  noOptionsText='Sin clientes para mostrar'
                  id="combo-box-demo"
                  placeholder="Buscar cliente"
                  options={clients}
                  value={selectedClient}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, value) => {
                    if (value === null) return;
                    setSelectedClient({
                      id: value.id,
                      name: value.name,
                    });
                    setFilters((prevVal) => ({
                      ...prevVal,
                      selectedClient: {
                        id: value.id,
                        name: value.name,
                      },
                    }));
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      label="Buscar cliente"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            }
            <Grid item md={3} xs={12}>
              <CustomTextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearchValueChange}
                placeholder="Buscar nombre"
                value={searchValue}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <br />
      <TableContainer component={Card}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedAllPriceLists}
                  indeterminate={selectedSomePriceLists}
                  onChange={handleSelectAllPriceLists}
                />
              </TableCell>
              <TableCell>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    Nombre
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {enableBulkOperations && (
                    <div className={classes.bulkActions}>
                      <Button
                        variant="outlined"
                        className={classes.bulkAction}
                        onClick={() => deleteSelectedPriceLists()}
                      >
                        Eliminar
                      </Button>
                    </div>
                  )}
                </Grid>
              </TableCell>
              <TableCell align="right">
                <TablePagination
                  component="div"
                  count={count}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={count <= 0 ? 0 : page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage="Filas por página"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPriceLists.length
              ? currentPriceLists.map((priceList) => {
                const isPriceListSelected = selectedPriceLists.includes(priceList.id);
                return (
                  <TableRow
                    hover
                    key={priceList.id}
                    selected={isPriceListSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isPriceListSelected}
                        onChange={(e) => handleSelectOnePriceList(e, priceList.id)}
                        value={isPriceListSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <div>
                          <Typography
                            color="inherit"
                            variant="h6"
                          >
                            {priceList.name}
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={Button}
                        onClick={() => handleGoToEdit(priceList.id)}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
              : <TableRow>
                <TableCell colSpan="4" align="center">
                  Sin listas de precios
                </TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  priceLists: PropTypes.array,
};

Results.defaultProps = {
  priceLists: [],
};

export default Results;
