import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import CancelButton from '../../../../components/Buttons/Cancel/CancelButton';

import useStyles from './styles';

function Header({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();

  const handleGoBackToProducts = () => {
    history.push('/administracion/usuarios', { prevPath: '/usuario/editar' });
  };

  return (
    <Grid
      className={clsx(classes.rootHeader, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
    <Grid container spacing= {1}>
      <Grid item>
        <Typography
          variant="h3"
          color="textSecondary"
          >
          Editar
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Usuario
        </Typography>
    </Grid>
    </Grid>
  </Grid>
      <Grid item>
        <CancelButton
          component={Button}
          onClick={() => handleGoBackToProducts()}
        >
          Cancelar
        </CancelButton>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
