/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import {
  ShoppingCart as ShoppingCartIcon,
  DollarSign as DollarSignIcon,
  User as UserIcon,
  Gift as GiftIcon,
  List as ListIcon,
  BarChart2 as ChartIcon,
} from 'react-feather';
import Logo from '../../Layout/Logo';
import NavItem from './NavItem';
import { SUPER_ADMIN, CLIENT_ADMIN, CLIENT_SELLER } from '../../../utils/const/user_types';
import {
  COUPON_MANAGEMENT, RDC_PAYMENT, PRICE_LIST_MANAGEMENT, CLIENT_MANAGEMENT,
} from '../../../utils/const/modules';
import useStyles from './styles';

const navConfig = [
  {
    subheader: 'Administración',
    roles: [SUPER_ADMIN, CLIENT_ADMIN, CLIENT_SELLER],
    moduleNeeded: [COUPON_MANAGEMENT, RDC_PAYMENT, PRICE_LIST_MANAGEMENT],
    items: [
      {
        title: 'Gráficos',
        icon: ChartIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        href: '/graficos',
      },
      {
        title: 'Usuarios',
        icon: UserIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        href: '/administracion/usuarios',
      },
      {
        title: 'Clientes',
        icon: DollarSignIcon,
        roles: [SUPER_ADMIN],
        href: '/pos/clientes',
      },
      {
        title: 'Máquinas',
        icon: DollarSignIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        moduleNeeded: RDC_PAYMENT,
        href: '/administracion/maquinas',
      },
      {
        title: 'Tiendas',
        icon: ShoppingCartIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        href: '/administracion/tiendas',
      },
      {
        title: 'Productos',
        icon: GiftIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        href: '/productos',
      },
      {
        title: 'Lista de Precios',
        icon: ListIcon,
        moduleNeeded: PRICE_LIST_MANAGEMENT,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        href: '/precios',
      },
      {
        title: 'Cupones',
        icon: DollarSignIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        moduleNeeded: COUPON_MANAGEMENT,
        href: '/cupones',
      },
      {
        title: 'Inventario',
        icon: DollarSignIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        href: '/inventario',
      },
      {
        title: 'Pedidos',
        icon: ListIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        href: '/pedidos',
      },
      {
        title: 'Devoluciones',
        icon: DollarSignIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        href: '/devoluciones',
      },
      {
        title: 'Costos Fijos',
        icon: DollarSignIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        href: '/costos-fijos',
      },
      {
        title: 'Módulos',
        icon: ListIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        href: '/modulos',
      },
      {
        title: 'Clientes',
        icon: UserIcon,
        roles: [SUPER_ADMIN, CLIENT_ADMIN],
        moduleNeeded: CLIENT_MANAGEMENT,
        href: '/clientes',
      },
    ],
  },
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc, pathname, item, depth = 0,
}) {
  const user = useSelector((state) => state.account.user);
  const key = item.title + depth;

  if ((item.items && item.roles.includes(user.role))
  && (item.moduleNeeded ? user.module[item.moduleNeeded] : true)) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else if (item.roles.includes(user.role)
  && (item.moduleNeeded ? user.module[item.moduleNeeded] : true)) {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />,
    );
  }
  return acc;
}

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo imgUrl="/img/logos/isotipo_color.svg" className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/">
              <Avatar alt="User" className={classes.avatar}/>
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {account.user && account.user.name}
            </Link>
            <Typography variant="body2" color="textPrimary">
              {account.user && account.user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader disableGutters disableSticky className={classes.button}>
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
