import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import SplashScreen from '../../../components/Loading/SplashScreen';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getReturnById } from '../../../requests/api/returns';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';

function ReturnDetailView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [devolution, setDevolution] = useState(null);
  const account = useSelector((s) => s.account);

  useEffect(() => {
    const getReturn = async () => {
      try {
        const response = await getReturnById(id);
        setDevolution(response.foundReturn);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getReturn();
  }, []);
  if (!devolution) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Pedidos"
    >
      <Container maxWidth={false}>
        <Header />
          <Box mt={3}>
            <Results
              devolution={devolution}
              user={account.user}
            />
          </Box>
      </Container>
    </Page>
  );
}

export default ReturnDetailView;
