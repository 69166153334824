import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import CreateCouponForm from './CreateCouponForm';
import storeRequest from '../../../requests/api/store';
import { getProductsPerPage } from '../../../requests/api/products';
import clientRequest from '../../../requests/api/client';
import customerRequest from '../../../requests/api/customer';
import { COUPON_MANAGEMENT } from '../../../utils/const/modules';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import handleApiResponse from '../../../utils/handleApiResponse';
import TabSelection from './TabSelection';
import StoreAssociation from './StoreAssociation';
import ProductsAssociation from './ProductsAssociation';

function CouponCreateView() {
  const classes = useStyles();
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [clients, setClients] = useState([]);
  const [stores, setStores] = useState([]);
  const [numberStores, setNumberStores] = useState(0);
  const [products, setProducts] = useState([]);
  const [numberProducts, setNumberProducts] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(
    account.user.role === SUPER_ADMIN ? null : account.user.clientId,
  );
  const [clientId] = useState(null);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [limitProduct, setLimitProduct] = useState(5);
  const [pageProduct, setPageProduct] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueProduct, setSearchValueProduct] = useState('');

  const { couponPage } = useSelector((state) => state.coupon);

  if (!account.user.module[COUPON_MANAGEMENT]) return <Redirect to='/administracion/usuarios' />;

  const getStores = async () => {
    try {
      const response = await storeRequest
        .getStoresPerPage(page, limit, searchValue, selectedClientId);
      setStores(response.allStores);
      setNumberStores(response.totalStores);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const getProducts = async () => {
    try {
      const response = await getProductsPerPage(
        pageProduct,
        limitProduct,
        searchValueProduct,
        selectedClientId,
      );
      setProducts(response.products);
      setNumberProducts(response.totalProducts);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await clientRequest
          .getClientsPerPage(page, limit, searchValue, clientId);
        setClients(response.clients);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    const getCustomers = async () => {
      try {
        const response = await customerRequest
          .getCustomersPerPage('', '', '', selectedClientId);
        setCustomers(response.customers);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    if (account.user.role === SUPER_ADMIN) getClients();
    if (selectedClientId) {
      // getStorePerPage y getProductsPerPage filtran por no eliminado desde API
      getStores();
      getProducts();
      getCustomers();
    }
  }, [selectedClientId]);

  useEffect(() => {
    if (selectedClientId) {
      getStores();
    }
  }, [page, limit, searchValue]);

  useEffect(() => {
    if (selectedClientId) {
      getProducts();
    }
  }, [pageProduct, limitProduct, searchValueProduct]);

  return (
    <Page
      className={classes.root}
      title="Agregar Cupones"
    >
      <Container maxWidth={false}>
        <Header />
        <TabSelection />
        {couponPage === 0 && (
          <Box mt={3}>
            <CreateCouponForm
              clients={clients}
              products={products}
              customers={customers}
              account={account}
              stores={stores}
              setStores={setStores}
              setSelectedClientId={setSelectedClientId}
            />
          </Box>
        )}
        {couponPage === 1 && (
          <Box mt={3}>
            <StoreAssociation
              stores={stores}
              totalStores={numberStores}
              setStores={setStores}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              setSearchValue={setSearchValue}
            />
          </Box>
        )}
        {couponPage === 2 && (
          <Box mt={3}>
            <ProductsAssociation
              products={products}
              totalProducts={numberProducts}
              page={pageProduct}
              limit={limitProduct}
              setPage={setPageProduct}
              setLimit={setLimitProduct}
              setSearchValue={setSearchValue}
              setSearchValueProduct={setSearchValueProduct}
            />
          </Box>
        )}

      </Container>
    </Page>
  );
}

export default CouponCreateView;
