import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.background.tableHeader,
      paddingTop: theme.spacing(3),
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  marginDivider: {
    margin: '35px 0px',
  },
  editPriceListWrapper: {

  },
  editPriceListFormWrapper: {

  },
  modifyPriceListNameAndButton: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    margin: '5px 0px',
    alignItems: 'center',
  },
  textFieldPrice: {
    marginLeft: '25px',
    marginTop: '22px',
  },
  updateProduct: {
    backgroundColor: '#C5C200',
    '&:hover': {
      backgroundColor: '#C5C200',
    },
  },
  addProduct: {
    backgroundColor: '#3BB701',
    '&:hover': {
      backgroundColor: '#3BB701',
    },
  },
}));

export default useStyles;
