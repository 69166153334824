/* eslint-env browser */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormData from 'form-data';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
} from 'react-feather';

import useStyles from './styles';
import { postCheckProductsCSV } from '../../../requests/api/products';
import handleApiResponse from '../../../utils/handleApiResponse';
import CancelButton from '../../../components/Buttons/Cancel/CancelButton';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import { postExportCSVStock, putStockCSV } from '../../../requests/api/productStock';

function Header({
  clientId,
  stocks,
  stores,
  productsToUpdateCSV,
  setProductsToUpdateCSV,
  selectedStoreCSV,
  setSelectedStoreCSV,
  className,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorValues, setErrorValues] = useState({ modal: false });
  const [uploadCSVModal, setUploadCSVModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [disableButton, setDisabledButton] = useState(false);

  const DUPLICATES = 'duplicates';
  const BLANKS = 'blanks';

  const handleCloseUploadCSVModal = () => {
    setUploadCSVModal(false);
    setSearchValue('');
    setSelectedFile('');
    setDisabledButton(false);
  };

  const handleUploadFile = async () => {
    setDisabledButton(true);
    try {
      const fileToCheck = new FormData();
      fileToCheck.append('file', selectedFile);
      fileToCheck.append('storeId', selectedStoreCSV.id);
      fileToCheck.append('clientId', clientId);
      fileToCheck.append('columnsToCheck', ['initialStock']);
      fileToCheck.append('headersToCheck', JSON.stringify([
        { value: 'sku', label: 'SKU' },
        { value: 'initialStock', label: 'Stock_Inicial' },
      ]));

      // Check File
      const response = await postCheckProductsCSV(fileToCheck);

      // No se pueden agregar nuevos productos desde la vista de stock
      if (response.filter((item) => item.new).length > 0) {
        const err = new Error();
        err.response = {
          status: 400,
          data: {
            errorMessage: 'No se pueden agregar nuevos productos desde la vista Inventario',
          },
        };
        throw err;
      } else if (response.filter((item) => item.update).every((item) => !item)) {
        handleApiResponse(enqueueSnackbar, { response: { data: { errorMessage: 'No hay datos nuevos para actualizar' } } }, false, 'warning');
        handleCloseUploadCSVModal();
        return;
      }

      setProductsToUpdateCSV(response.filter((item) => item.update));
      handleCloseUploadCSVModal();
    } catch (err) {
      if (err.response.data
        && err.response.data.data
        && err.response.data.data.duplicates) {
        setErrorValues((prevState) => ({
          ...prevState,
          modal: true,
          source: DUPLICATES,
          data: err.response.data.data.duplicates,
          arraySize: err.response.data.data.duplicates.length,
        }));
      } else if (err.response.data
        && err.response.data.data
        && err.response.data.data.whiteCells) {
        setErrorValues((prevState) => ({
          ...prevState,
          modal: true,
          source: BLANKS,
          data: err.response.data.data.whiteCells,
          arraySize: err.response.data.data.duplicates.length,
        }));
      } else if (err.response.data.errorMessage) {
        handleApiResponse(enqueueSnackbar, err, false);
      } else {
        // eslint-disable-next-line no-console
        console.log('err', err);
        handleApiResponse(enqueueSnackbar, { response: { data: { errorMessage: 'Error desconocido al cargar CSV. Contactar proveedor.' } } }, false);
      }
      setDisabledButton();
      setSelectedFile('');
    }
  };

  const handleDownloadCSV = async () => {
    try {
      const response = await postExportCSVStock({ stocks });
      const universalBOM = '\uFEFF';
      const uri = `data:'text/csv';charset=utf-8,${encodeURI(universalBOM + response.file)}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = response.fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleUpdateInformation = async () => {
    try {
      const updateResponse = await putStockCSV({
        dataInCSV: productsToUpdateCSV,
        storeId: selectedStoreCSV.id,
        clientId,
      });
      handleApiResponse(enqueueSnackbar, updateResponse, true);
      setSelectedStoreCSV({});
      setProductsToUpdateCSV(null);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err', err);
      handleApiResponse(enqueueSnackbar, { response: { data: { errorMessage: 'Error desconocido al cargar CSV. Contactar proveedor.' } } }, false);
    }
  };

  return (
    <Grid
      className={clsx(className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textSecondary"
        >
          Inventario
        </Typography>
      </Grid>
      <Grid item>
        {!productsToUpdateCSV && <Button
          startIcon={<UploadIcon />}
          onClick={() => {
            setUploadCSVModal(true);
            setSelectedStoreCSV({});
          }}
        >
          Importar CSV
        </Button>}
        {productsToUpdateCSV && <Button
          startIcon={<UploadIcon />}
          onClick={handleUpdateInformation}
        >
          Subir cambios
        </Button>}
        {clientId && (<Button
          startIcon={<DownloadIcon />}
          component="label"
          onClick={handleDownloadCSV}
        >
          Exportar CSV
        </Button>)}
      </Grid>
      <Modal
        open={errorValues.modal}
        onClose={() => setErrorValues({ ...errorValues, modal: false })}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          margin='0px 20px'
          height='100vh'
        >
          <Card>
            <CardContent>
              {errorValues.modal && <Box>
                <Typography
                  color="textPrimary"
                  variant='h4'
                >
                  {errorValues.source === DUPLICATES && 'Hemos encontrado valores duplicados en el excel en campos que son únicos. Favor revisar los siguientes valores:'}
                  {errorValues.source === BLANKS && 'Hemos encontrado valores en blanco en el excel en campos que necesitan información. Favor revisar los siguientes valores:'}
                </Typography>
                <Box>
                  <List
                    className={classes.listModal}
                  >
                    {errorValues.data && errorValues.data.map((value) => (
                      <ListItem button key={value.line}>
                        <ListItemText primary={`Linea: ${value.line}`} />
                        {
                          errorValues.source === DUPLICATES
                          && <ListItemText primary={`SKU: ${value.info[1]}`} />
                        }
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>}
              <Box
                display='flex'
                justifyContent='end'
                padding='10px 24px'
              >
                <CancelButton
                  onClick={() => setErrorValues({ modal: false })}
                  variant='contained'
                >
                  Cerrar Modal
                </CancelButton>
              </Box>

            </CardContent>
          </Card>

        </Box>
      </Modal>
      <Modal
        open={uploadCSVModal}
        onClose={handleCloseUploadCSVModal}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          margin='0px 20px'
          height='100vh'
        >
          <Card className={classes.uploadProjectModalCard}>
            <CardContent className={classes.uploadProjectModalCardContent}>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
              >
                <Box
                  display='flex'
                  mb={2}
                >
                  <UploadIcon />
                  <Typography>
                    Importar Excel
                  </Typography>
                </Box>
                <Divider />
                <Box
                  mt={2}
                  mb={1}
                >
                  <InputLabel
                    className={classes.inputLabelModal}>
                    Seleccione una tienda
                  </InputLabel>
                  <CustomTextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder='Buscar tienda'
                    color="secondary"
                    variant="outlined"
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                  />
                </Box>
                <Box
                  display='flex'
                  flex={1}
                >
                  <List
                    className={classes.listContainer}
                  >
                    {stores.map((store) => (
                      <ListItem
                        key={store.id}
                        onClick={() => setSelectedStoreCSV(store)}
                        className={store.id === selectedStoreCSV.id
                          ? classes.listItemHighlight
                          : classes.listItem
                        }
                      >
                        <ListItemText primary={`${store.name}`} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
              <Divider />
              <Box
                display='flex'
                justifyContent='end'
                padding='10px 24px'
              >
                <Box
                  width='240px'
                  mr={2}
                >
                  <Button
                    onClick={handleCloseUploadCSVModal}
                    disabled={disableButton}
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.buttonModal}
                  >
                    Cancelar
                  </Button>
                </Box>
                <Box
                  width='240px'
                >
                  {!selectedFile && <Button
                    component='label'
                    fullWidth
                    variant="contained"
                    color='primary'
                    className={classes.buttonModal}
                    disabled={!selectedStoreCSV.id}
                  >
                    Subir archivo
                    <input
                      type="file"
                      accept=".csv"
                      hidden
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                  </Button>}
                  {selectedFile
                    && <Button
                      disabled={disableButton}
                      fullWidth
                      variant="contained"
                      color='primary'
                      onClick={handleUploadFile}
                      className={classes.buttonModal}
                    >
                      Siguiente
                    </Button>}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Grid >
  );
}

Header.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array.isRequired,
  clientName: PropTypes.string,
};

export default Header;
