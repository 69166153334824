import axios from '../../utils/axios';

const getCustomersPerPage = async (page, limit, searchValue, clientId) => {
  const response = await axios.get(`/api/customers?page=${page}&limit=${limit}&searchValue=${searchValue}&clientId=${clientId || 0}`);
  return response.data;
};

export default {
  getCustomersPerPage,
};
