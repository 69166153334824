const RENT = 'Arriendo';
const SALARIES = 'Sueldos';
const EXPENSES = 'Gastos Comunes';
const SERVICES = 'Servicios';
const OTHER = 'Otro';

module.exports = {
  RENT,
  SALARIES,
  EXPENSES,
  SERVICES,
  OTHER,
};
