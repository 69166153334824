/* eslint-disable no-underscore-dangle */
import axios from '../../utils/axios';

const getPriceListsPerPage = async (page, limit, searchValue, clientId) => {
  const response = await axios.get(`/api/price-lists?page=${page}&limit=${limit}&searchValue=${searchValue}&clientId=${clientId || 0}`);
  return response.data;
};

const getPriceListById = async (priceListId) => {
  const response = await axios.get(`/api/price-list/${priceListId}`);
  return response.data;
};

const postNewPriceList = async ({ name }, clientId) => {
  const response = await axios.post('/api/price-list/create', { name, clientId });
  return response.data;
};

const postExportCSV = async (CSVInformation) => {
  const response = await axios.post('/api/price-list/download-csv', CSVInformation);
  return response.data;
};

const postCheckCSV = async (fileToCheck) => {
  const response = await axios.post(
    '/api/price-list/check-SKU',
    fileToCheck,
    {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fileToCheck._boundary}`,
      },
    },
  );
  return response.data;
};

const putProductsPricesFromCSV = async ({ priceListId, newUpdateProducts }) => {
  const response = await axios.put('/api/price-list/update-product-prices-csv', { priceListId, newUpdateProducts });
  return response.data;
};

const putPriceList = async (newPriceListInfo, clientId) => {
  const response = await axios.put(
    '/api/price-list/edit',
    {
      name: newPriceListInfo.name,
      clientId,
      priceListId: newPriceListInfo.id,
      productPriceToAdd: newPriceListInfo.productPriceToAdd,
      productPriceIdsToRemove: newPriceListInfo.productPriceIdsToRemove,
    },
  );
  return response.data;
};

const deletePriceLists = async (priceListsIds) => {
  const response = await axios.put('/api/price-list/delete', { priceListsIds });
  return response.data;
};

export {
  getPriceListsPerPage,
  getPriceListById,
  postNewPriceList,
  postExportCSV,
  postCheckCSV,
  putProductsPricesFromCSV,
  putPriceList,
  deletePriceLists,
};
