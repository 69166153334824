import React from 'react';
import {
  Slash as SlashIcon,
} from 'react-feather';
import {
  Box,
  Card,
  Grid,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

export default function EmptyChart() {
  const classes = useStyles();

  return (
    <Card>
      <Box className={classes.containerBox}>
        <SvgIcon className={classes.iconContainer}>
          <SlashIcon />
        </SvgIcon>
        <Box className={classes.textContainer}>
          <Grid container spacing={2}>
            <Grid item>
              <Box>
                <Typography
                  variant="h1"
                  color="textPrimary"
                >
                  No hay
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography
                variant="h1"
                color="textSecondary"
              >
                datos
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}
