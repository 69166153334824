import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
} from '@material-ui/core';
import ReturnTable from './ReturnTable';
import ReturnProductsTable from './ReturnProductsListView';

function Results({
  devolution,
  user,
}) {
  return (
      <Grid id='results' container spacing={0}>
        <Grid item xs={12}>
          <ReturnTable
          devolution={devolution}
          user={user}
          />
        </Grid>
        <Grid item xs={12}>
        <Box mt={4}>
          <ReturnProductsTable
          devolution={devolution}
          user={user}
          />
        </Box>
        </Grid>
      </Grid>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  devolution: PropTypes.object,
};

Results.defaultProps = {
  devolution: {},
};

export default Results;
