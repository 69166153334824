import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import machineRequest from '../../../requests/api/machines';
import handleApiResponse from '../../../utils/handleApiResponse';
import useStyles from './styles';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import { saveFilters } from '../../../actions/filterActions';

function Results({
  machines,
  count,
  searchValue,
  setSearchValue,
  page,
  setPage,
  limit,
  setLimit,
  setSelectedClient,
  selectedClient,
  role,
  clients,
  history,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [currentMachines, setCurrentMachines] = useState(machines);
  const [filters, setFilters] = useState({
    page,
    limit,
    searchValue,
    selectedClient,
  });

  useEffect(() => { setCurrentMachines(machines); }, [machines]);

  const handleSearchValueChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, searchValue: e.target.value }));
  };

  const handleSelectAllMachines = (e) => {
    setSelectedMachines(e.target.checked
      ? currentMachines.map((machine) => machine.id)
      : []);
  };

  const handleSelectOneMachine = (e, machineId) => {
    if (!selectedMachines.includes(machineId)) {
      setSelectedMachines((prevSelected) => [...prevSelected, machineId]);
    } else {
      setSelectedMachines((prevSelected) => prevSelected.filter((id) => id !== machineId));
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    setFilters((prevVal) => ({ ...prevVal, page: newPage }));
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, limit: e.target.value }));
  };

  const enableBulkOperations = selectedMachines.length > 0;
  const selectedSomeMachines = selectedMachines.length > 0
  && selectedMachines.length < currentMachines.length;
  const selectedAllMachines = selectedMachines.length === currentMachines.length;

  const deleteSelectedMachines = async () => {
    try {
      const response = await machineRequest.deleteMachines(selectedMachines);
      handleApiResponse(enqueueSnackbar, response, true);
      setCurrentMachines(currentMachines.filter((e) => (!selectedMachines.includes(e.id))));
      setSelectedMachines([]);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleGoToEdit = (machineId) => {
    dispatch(saveFilters(filters));
    history.push(`/administracion/maquinas/${machineId}/editar`);
  };

  return (
<div>
  <Card>
  <Box
    p={2}
    minHeight={56}
    display="flex"
    alignItems="center"
  >
    <Grid container spacing={1}>
        {role === SUPER_ADMIN
      && <Grid item md={3} xs={12}>
        <CustomAutocomplete
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disablePortal
        id="combo-box-demo"
        options={clients}
        value={selectedClient}
        noOptionsText='Sin clientes para mostrar'
        getOptionLabel={(option) => `${option.name}`}
        onChange={(e, value) => {
          if (value === null) return;
          setSelectedClient({
            id: value.id,
            name: value.name,
          });
        }}
        renderInput={(params) => (
          <CustomTextField
          {...params}
          label="Buscar cliente"
          variant="outlined"
          />
        )}
        />
      </Grid>
        }
      <Grid item md={3} xs={12}>
        <CustomTextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleSearchValueChange}
          placeholder="Buscar máquina"
          value={searchValue}
          variant="outlined"
        />
      </Grid>
    </Grid>
  </Box>
  </Card>
  <br/>
<Card>
  <Table>
    <TableHead>
    <TableRow>
          {enableBulkOperations && (
            <div className={classes.bulkActions}>
              <Button
               variant="outlined"
               className={classes.bulkAction}
               onClick={() => deleteSelectedMachines()}
               >
                Eliminar
              </Button>
            </div>
          )}
      </TableRow>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedAllMachines}
            indeterminate={selectedSomeMachines}
            onChange={handleSelectAllMachines}
          />
        </TableCell>
        <TableCell>
            ID/Serial
        </TableCell>
        <TableCell>
            Modelo
        </TableCell>
        <TableCell align="right">
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por página"
        />
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {currentMachines.map((machine) => {
        const isMachineSelected = selectedMachines.includes(machine.id);
        return (
          <TableRow
            hover
            key={machine.id}
            selected={isMachineSelected}
          >
            <TableCell padding="checkbox">
              <Checkbox
                checked={isMachineSelected}
                onChange={(e) => handleSelectOneMachine(e, machine.id)}
                value={isMachineSelected}
              />
            </TableCell>
            <TableCell>
              <Box
                display="flex"
                alignItems="center"
              >
                <Avatar
                  className={classes.avatar}
                >
                </Avatar>
                <div>
                  <Typography
                    color="inherit"
                    variant="h6"
                  >
                    ID: {machine.machineId}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                  >
                    {machine.address}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                  >
                   Serial: {machine.serialNumber}
                  </Typography>
                </div>
              </Box>
            </TableCell>
            <TableCell>
              {machine.model}
            </TableCell>
            <TableCell align="right">
              <IconButton
                component={Button}
                onClick={() => handleGoToEdit(machine.id)}
              >
                <SvgIcon fontSize="small">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
</Card>
</div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  machines: PropTypes.array,
};

Results.defaultProps = {
  machines: [],
};

export default Results;
