import axios from '../../utils/axios';

const getCostsPerPage = async (page, limit, searchValue, storeId, clientId, startDate, endDate) => {
  const response = await axios.get(`/api/costs?page=${page}&limit=${limit}&searchValue=${searchValue}&storeId=${storeId}&clientId=${clientId}&startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};

const postNewCost = async (
  cost,
) => {
  const response = await axios.post('/api/cost/create', { cost });
  return response.data;
};

const getCostById = async (costId) => {
  const response = await axios.get(`/api/cost/${costId}`);
  return response.data;
};

const putCostById = async (cost) => {
  const response = await axios.put('/api/cost/edit', { cost });
  return response.data;
};

const deleteCosts = async (costsIds) => {
  const response = await axios.put('/api/cost/delete', { costsIds });
  return response.data;
};

export {
  getCostsPerPage,
  postNewCost,
  putCostById,
  getCostById,
  deleteCosts,
};
