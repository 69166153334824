import {
  EDIT_STOCK,
} from '../actions/stockActions';

const initialState = {
  stock: null,
};

// eslint-disable-next-line default-param-last
const productStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_STOCK: {
      return {
        stock: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default productStockReducer;
