import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import customerRequest from '../../../requests/api/customer';
import clientRequest from '../../../requests/api/client';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import SplashScreen from '../../../components/Loading/SplashScreen';
import thousandSeparator from '../../../utils/thousandSeparator';

function CustomersListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((s) => s.account.user);
  const [selectedClient, setSelectedClient] = useState({
    id: user.role === SUPER_ADMIN ? null : user.clientId,
    name: null,
  });
  const [clients, setClients] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [count, setCount] = useState(null);
  const [customersWithDebt, setCustomersWithDebt] = useState(false);
  const [customersToShow, setCustomersToShow] = useState(null);
  const clientLimit = '';
  const clientPage = '';
  const clientSearchValue = '';

  useEffect(() => {
    const getCustomers = async () => {
      try {
        const response = await customerRequest.getCustomersPerPage(
          page,
          limit,
          searchValue,
          selectedClient.id,
        );
        setCustomers(response.customers);
        setCount(response.totalCustomers);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    const getClients = async () => {
      try {
        const response = await clientRequest
          .getClientsPerPage(clientLimit, clientPage, clientSearchValue);
        setClients(response.clients);
        if (!selectedClient.id) {
          setSelectedClient({
            id: response.clients[0].id,
            name: response.clients[0].name,
          });
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (user.role === SUPER_ADMIN) getClients();
    getCustomers();
  }, [limit, page, searchValue, selectedClient]);

  useEffect(() => {
    if (customers.length) {
      const customersToShowAux = customers.map((customer) => {
        const ordersAmountSum = customer.orders
          ? customer.orders.reduce((acc, curr) => acc + curr.totalAmount, 0) : 0;
        const averageOrdersAmount = Math.round(ordersAmountSum / (customer.orders.length || 1));
        const debts = customer.orders
          ? customer.orders.map((order) => [...order.debts])
            .filter((el) => el.length > 0).flat() : [];
        const debtSum = debts
          .reduce((acc, curr) => acc + curr.initialDebt - curr.amountPayed, 0);
        const averageDebt = debtSum > 0 ? Math.round(debtSum / (debts.length || 1)) : 0;
        return {
          id: customer.id,
          name: customer.name,
          lastname: customer.lastname,
          ordersCount: customer.orders.length,
          averageOrdersAmount: thousandSeparator(averageOrdersAmount, '.'),
          averageDebt: thousandSeparator(averageDebt, '.'),
        };
      });
      if (!customersWithDebt) {
        setCustomersToShow(customersToShowAux);
        setCount(customersToShowAux.length);
        return;
      }
      setCustomersToShow(customersToShowAux.filter((c) => c.averageDebt > 0));
      setCount(customersToShowAux.filter((c) => c.averageDebt > 0).length);
    }
  }, [customersWithDebt, customers]);

  if (!customersToShow) return <SplashScreen />;

  return (
    <Page
      className={classes.root}
      title="Clientes"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results
            customersToShow={customersToShow}
            clients={clients}
            page={page}
            count={count || 0}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            role={user.role}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            customersWithDebt={customersWithDebt}
            setCustomersWithDebt={setCustomersWithDebt}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default CustomersListView;
