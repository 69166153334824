const ERROR = 'error'; // error de ingreso o egreso
const STOCK = 'ingresoStock'; // ingreso de stock
const LOSSES = 'merma'; // productos que se rompen/pierden

// motivos de error (NO de edición de stock)
const ENTRY = 'entry';
const EGRESS = 'egress';

module.exports = {
  ERROR,
  STOCK,
  LOSSES,
  ENTRY,
  EGRESS,
};
