import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
} from 'react-feather';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/Autocomplete/CustomAutocomplete';
import { SUPER_ADMIN } from '../../../utils/const/user_types';
import useStyles from './styles';
import { editStock } from '../../../actions/stockActions';
import { saveFilters } from '../../../actions/filterActions';
import { LABELED_UNITS } from '../../../constants/unitMeasure';

function Results({
  stocks,
  stores,
  count,
  page,
  setPage,
  limit,
  setLimit,
  searchValue,
  setSearchValue,
  role,
  clients,
  selectedClient,
  setSelectedClient,
  selectedStore,
  setSelectedStore,
  history,
  productsToUpdateCSV,
  selectedStoreCSV,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    page,
    limit,
    searchValue,
    selectedStore,
    selectedClient,
  });
  const [pageCSV, setPageCSV] = useState(0);
  const [limitCSV, setLimitCSV] = useState(10);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    setFilters((prevVal) => ({ ...prevVal, page: newPage }));
  };

  const handlePageChangeCSV = (e, newPage) => {
    setPageCSV(newPage);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setFilters((prevVal) => ({ ...prevVal, limit: e.target.value }));
  };

  const handleLimitChangeCSV = (e) => {
    setLimitCSV(e.target.value);
  };

  const handleDispatch = (stock) => {
    dispatch(editStock(stock));
    dispatch(saveFilters(filters));
    history.push('/inventario/editar');
  };

  return (
    <div>
      <Card
        sx={{ marginBottom: 6 }}
      >
        <Box
          p={2}
          minHeight={56}
          display="flex"
          alignItems="center"
        >
          <Grid container spacing={1}>
            {role === SUPER_ADMIN
              && <Grid item md={3} xs={12}>
                <CustomAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  noOptionsText='Sin clientes para mostrar'
                  id="combo-box-demo"
                  placeholder="Buscar cliente"
                  options={clients}
                  value={selectedClient}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, value) => {
                    if (value === null) {
                      setSelectedClient({
                        id: clients[0].id,
                        name: clients[0].name,
                      });
                      setSelectedStore({
                        id: stores[0].id,
                        name: stores[0].name,
                      });
                      return;
                    }
                    setSelectedClient({
                      id: value.id,
                      name: value.name,
                    });
                    setSelectedStore({
                      id: null,
                      name: null,
                    });
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      label="Buscar cliente"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            }
            <Grid item md={3} xs={12}>
              <CustomAutocomplete
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText='Sin tiendas para mostrar'
                id="combo-box-demo"
                placeholder="Buscar tienda"
                options={stores}
                value={selectedStore}
                getOptionLabel={(option) => `${option.name}`}
                onChange={(e, value) => {
                  if (value === null) {
                    setSearchValue('');
                    setSelectedStore({
                      id: stores[0].id,
                      name: stores[0].name,
                    });
                    return;
                  }
                  setSelectedStore({
                    id: value.id,
                    name: value.name,
                  });
                  setFilters((prevVal) => ({
                    ...prevVal,
                    selectedStore: {
                      id: value.id,
                      name: value.name,
                    },
                  }));
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="Buscar tienda"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                fullWidth
                type='text'
                label="Buscar producto"
                name="quantity"
                placeholder="Buscar producto"
                value={searchValue}
                onChange={(e) => {
                  if (e.target.value === null) {
                    setSearchValue('');
                    return;
                  }
                  setSearchValue(e.target.value);
                  setFilters((prevVal) => ({ ...prevVal, searchValue: e.target.value }));
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <br></br>
      <TableContainer component={Card}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {!productsToUpdateCSV && <TableCell>
                Producto
              </TableCell>}
              {productsToUpdateCSV && <TableCell>
                SKU
              </TableCell>}
              <TableCell>
                Stock
              </TableCell>
              <TableCell>
                Unidad de medida
              </TableCell>
              <TableCell>
                Tienda
              </TableCell>
              <TableCell align="right">
                {stocks && !productsToUpdateCSV
                  && <TablePagination
                    component="div"
                    count={count}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={count <= 0 ? 0 : page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage="Filas por página"
                  />}
                {stocks && productsToUpdateCSV
                  && <TablePagination
                    component="div"
                    count={productsToUpdateCSV.length}
                    onPageChange={handlePageChangeCSV}
                    onRowsPerPageChange={handleLimitChangeCSV}
                    page={productsToUpdateCSV.length <= 0 ? 0 : pageCSV}
                    rowsPerPage={limitCSV}
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage="Filas por página"
                  />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stocks && !productsToUpdateCSV
              && stocks.map((stock) => (
                <TableRow
                  hover
                  key={stock.id}
                >
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Avatar
                        className={classes.avatar}
                      >
                      </Avatar>
                      <Typography
                        color="inherit"
                        variant="h6"
                      >
                        {stock.product.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {stock.quantity}
                  </TableCell>
                  <TableCell>
                    {LABELED_UNITS[stock.product.unitMeasure]}
                  </TableCell>
                  <TableCell>
                    {stock.store.name}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleDispatch(stock)}
                      component={Button}
                    >
                      <SvgIcon fontSize="small">
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {stocks && productsToUpdateCSV
              && productsToUpdateCSV.map((stock) => (
                <TableRow
                  hover
                  key={stock.id}
                  className={classes.updateProduct}
                >
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Typography
                        color="inherit"
                        variant="h6"
                      >
                        {stock.sku}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {stock.initialStock}
                  </TableCell>
                  <TableCell>
                    {LABELED_UNITS[stock.unitMeasure]}
                  </TableCell>
                  <TableCell>
                    {selectedStoreCSV.name}
                  </TableCell>
                  <TableCell />
                </TableRow>
              ))}
            {!stocks
              && <TableRow>
                <TableCell colSpan="4" align="center">
                  Sin stock para mostrar
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  stores: PropTypes.array,
};

Results.defaultProps = {
  stores: [],
};

export default Results;
