import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { useSnackbar, withSnackbar } from 'notistack';
import { userVerification } from '../../requests/api/auth';
import CustomTextField from '../../components/Inputs/TextField/CustomTextField';
import useStyles from './styles';
import handleApiResponse from '../../utils/handleApiResponse';

function SetPasswordForm({
  className, onSubmitSuccess, token, ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().max(255).required('Contraseña requerida'),
        confirmPassword: Yup.string().max(255).required('Confirmación de contraseña requerida'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          const data = {
            token,
            password: values.password,
            confirmPassword: values.confirmPassword,
          };
          const res = await userVerification(data);

          handleApiResponse(enqueueSnackbar, res, true);
          setStatus({ success: true });
          setSubmitting(false);
          onSubmitSuccess();
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false);
          setStatus({ success: false });
          setErrors({ submit: error.errorMessage });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(classes.rootSetPasswordForm, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <CustomTextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            autoFocus
            helperText={touched.password && errors.password}
            label="Contraseña"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="standard"
          />
          <CustomTextField
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            label="Confirmar contraseña"
            margin="normal"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.confirmPassword}
            variant="standard"
          />
          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Verificar cuenta
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

SetPasswordForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  token: PropTypes.string,
};

SetPasswordForm.defaultProps = {
  onSubmitSuccess: () => {},
};

export default withSnackbar(SetPasswordForm);
