import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import priceListReducer from './priceListReducer';
import productReducer from './productReducer';
import productStockReducer from './productStockReducer';
import storeReducer from './storeReducers';
import couponReducer from './couponReducer';
import filterReducer from './filtersReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  priceList: priceListReducer,
  product: productReducer,
  form: formReducer,
  stock: productStockReducer,
  store: storeReducer,
  coupon: couponReducer,
  filter: filterReducer,
});

export default rootReducer;
